import { Pipe, PipeTransform } from '@angular/core';
import { TenantCandidature } from '@core/models';

@Pipe({
  name: 'tenantRole'
})
export class TenantRolePipe implements PipeTransform {
  transform(tenant: TenantCandidature): string {
    if (tenant.user.guarantor) {
      return 'components.change_tenant_role.guarantor';
    } else if (tenant.user.student) {
      return 'components.change_tenant_role.student';
    } else if (tenant.user.business) {
      return 'components.change_tenant_role.business';
    } else if (tenant.user.retired) {
      return 'components.change_tenant_role.retired';
    } else if (tenant.user.freelance) {
      return 'components.change_tenant_role.freelance';
    } else {
      return 'components.change_tenant_role.worker';
    }
  }
}
