import { FormControl } from '@angular/forms';

export interface MultiplePriceAssign {
  pipeFunction: MultiplePriceAssignFunction;
  isBuildingPage?: boolean;
}

export enum MultiplePriceAssignFunction {
  SHOW_BUTTON,
  DISABLE_BUTTON,
  TOOLTIP
}

export interface MultiplePriceAssignModalForm {
  newPrice: FormControl<number>;
  motivation: FormControl<string>;
}

export interface MultiplePriceAssignModalResponse {
  newPrice: number;
  motivation: string;
}
