/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import {
  AssetDto,
  BuildingDto,
  Candidature,
  CandidatureDto,
  CandidatureStatusEnum,
  TrialCandidatureRequestDto,
  User
} from '@core/models';
import { TrialFlowService } from '@core/services/trial-flow/trial-flow.service';
import {
  ModalController,
  ToastController,
  ViewWillEnter
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'el-buen-inquilino-add-trial-candidature',
  templateUrl: './add-trial-candidature.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ]
})
export class AddTrialCandidatureComponent implements OnInit, ViewWillEnter {
  @Input() asset: AssetDto;
  selectedIndex = 0;
  stepOne: any;
  saving = false;
  maximunTenantsReached = false;
  continueButtonText: string;
  fromAssetDetail = false;

  @Input() building: BuildingDto;
  @Input() user: User;
  @Input() isBackoffice = false;
  @ViewChild(MatStepper) stepper: MatStepper;

  constructor(
    private modalController: ModalController,
    public trialFlowService: TrialFlowService,
    private translateService: TranslateService,
    private toastController: ToastController
  ) {}

  ngOnInit(): void {
    this.setContinueButtonText();
  }

  ionViewWillEnter(): void {
    if (this.asset) {
      this.nextIndex();
      this.trialFlowService.createCandidatureAsset =
        new TrialCandidatureRequestDto();
      this.trialFlowService.createCandidatureAsset.asset = this.asset;
      this.trialFlowService.createCandidatureAsset.users = [];
      this.fromAssetDetail = true;
    }
  }

  dismiss(saving?: boolean): void {
    this.modalController.dismiss({
      dismissed: true,
      saving
    });
    this.trialFlowService.createCandidatureAsset =
      new TrialCandidatureRequestDto();
  }

  selectChange(index: number, stepsForm: NgForm): void {
    switch (index) {
      case 0:
        this.continueButtonText = this.translateService.instant(
          'components.add_trial_candidature.select_tenants'
        );
        break;
      case 1:
        this.continueButtonText = this.translateService.instant(
          'components.add_trial_candidature.confirm_tenants'
        );
        break;
      case 2:
        if (!this.trialFlowService.createCandidatureAsset.users) {
          this.selectedUser(stepsForm.form.value.stepTwo);
        }
        break;
      default:
        break;
    }
  }

  async presentToast(ok: boolean, message?: string): Promise<void> {
    let messageKey = ok
      ? this.translateService.instant(
          'components.add_trial_candidature.candidature_created'
        )
      : this.translateService.instant(
          'components.add_trial_candidature.candidature_created_fail'
        );
    const colorKey = ok ? 'success' : 'danger';
    if (message) {
      messageKey = message;
    }
    const toast = await this.toastController.create({
      message: this.translateService.instant(messageKey),
      color: colorKey,
      duration: 2000
    });
    toast.present();
  }

  nextIndex(): void {
    this.selectedIndex++;
    this.stepper?.next();
  }

  previousIndex(): void {
    this.selectedIndex--;
    this.stepper?.previous();
  }

  selectedAsset(e): void {
    console.log(e);

    const createCandidatureAsset: TrialCandidatureRequestDto =
      this.trialFlowService.createCandidatureAsset;
    createCandidatureAsset.asset = e;
    createCandidatureAsset.users = [];
    this.trialFlowService.createCandidatureAsset = createCandidatureAsset;
  }

  selectedUser(e): void {
    const createCandidatureAsset: TrialCandidatureRequestDto =
      this.trialFlowService.createCandidatureAsset;
    if (this.isUniqueUser(e)) {
      createCandidatureAsset.users.push(e);
      this.checkMaximunTenantForCandidature();
    } else {
      this.presentToast(
        false,
        this.translateService.instant(
          'components.add_trial_candidature.user_repeated'
        )
      );
    }
    this.trialFlowService.createCandidatureAsset = createCandidatureAsset;
  }

  removeUser(e): void {
    this.trialFlowService.createCandidatureAsset.users.splice(e, 1);
  }

  isUniqueUser(e): boolean {
    if (e) {
      return (
        this.trialFlowService.createCandidatureAsset.users
          .map((it) => it.dni)
          .indexOf(e.dni) === -1
      );
    } else {
      return false;
    }
  }

  checkStep(): boolean {
    if (this.selectedIndex === 0) {
      if (
        this.trialFlowService.createCandidatureAsset &&
        this.trialFlowService.createCandidatureAsset.asset
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        this.selectedIndex === 1 &&
        this.trialFlowService.createCandidatureAsset.users &&
        this.trialFlowService.createCandidatureAsset.users.length === 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkMaximunTenantForCandidature(): void {
    if (
      this.trialFlowService.createCandidatureAsset.users &&
      this.trialFlowService.createCandidatureAsset.users.length >=
        this.trialFlowService.MAXIMUN_TENANT_ON_CANDIDATURE
    ) {
      this.maximunTenantsReached = true;
      this.presentToast(
        false,
        this.translateService.instant(
          'components.add_trial_candidature.max_tenants2'
        )
      );
    } else {
      this.maximunTenantsReached = false;
    }
  }

  submitCandidature(): void {
    this.saving = true;
    if (
      !this.trialFlowService.createCandidatureAsset.users &&
      this.trialFlowService.createCandidatureAsset.users.length === 0
    ) {
      this.presentToast(
        false,
        this.translateService.instant(
          'components.add_trial_candidature.form_error'
        )
      );
      this.saving = false;
    } else {
      let call: Promise<CandidatureDto>;

      if (this.isBackoffice) {
        call = this.trialFlowService.createCandidatureTrialFlow(
          this.user.portfolioId,
          this.user.id
        );
      } else {
        call = this.trialFlowService.createCandidatureTrialFlow(
          this.user.portfolioId
        );
      }

      call
        .then((candidature: CandidatureDto) => {
          if (this.isBackoffice) {
            let status: CandidatureStatusEnum = CandidatureStatusEnum.SELECT;
            Object.keys(CandidatureStatusEnum).forEach((s) => {
              if (
                CandidatureStatusEnum[s] === candidature.candidatureStatusEnum
              ) {
                status = CandidatureStatusEnum[s];
              }
            });

            const newCandidature: Candidature = {
              id: candidature.id,
              asset: candidature.asset,
              candidatureStatusEnum: status,
              expirationDate: candidature.expirationDate,
              homeowner: candidature.homeowner,
              tenantCandidatureList: candidature.tenantCandidatureList,
              thirdParty: false
            };
            this.modalController.dismiss({
              candidature: newCandidature
            });
          } else {
            this.dismiss(true);
            this.saving = false;
            this.presentToast(true);
          }
        })
        .catch(() => {
          this.saving = false;
          this.presentToast(false);
        });
    }
  }

  private setContinueButtonText(): void {
    this.continueButtonText = this.translateService.instant(
      'components.add_trial_candidature.select_tenants'
    );
  }
}
