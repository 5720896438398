import { Candidature, CandidatureStatusEnum } from './candidature.model';
import {
  ApiMemberDto,
  Incidence,
  PolicyHolderData,
  ReportCount
} from './users.model';

export class CreateAssetDto {
  id?: string;
  street: string;
  number: string;
  portal?: string;
  stairs?: string;
  floor?: string;
  door?: string;
  postalCode: string;
  town: string;
  province: string;
  link?: string;
  rentalPrice: number;
  reports?: ReportCount;
  status: string;
  location?: LocationDto;
  homeownerReference?: string;
}

export interface LabelDto {
  id?: number;
  name: string;
  value?: string;
}

export class AssetDto extends CreateAssetDto {
  userId?: string;
  hasPolicyNumber?: string;
  hasTenantPolicyNumber?: string;
  visitedAsset?: boolean;
  lastUpdated?: Date;
  candidatureStatusList?: CandidatureStatusDto[];
  downloadableReports?: number;
  creationDate?: Date;
  rentedDate?: Date;
  disabledDate?: Date;
  cadastralReference?: string;
  declare rentalPrice: number;
  rentalArea?: number;
  declare location?: LocationDto;
  labelId?: number;
  declare homeownerReference?: string;
  candidature?: Candidature;
  thirdParty?: boolean;
  certifierId?: string;
  incidences?: Incidence[];
  // Attributes for buildings
  apiId?: string;
  buildingId?: string;
  portfolioOwnerId?: string;
  portfolioId?: string;
  associatedCandidatures?: AssociatedCandidature[];
  surface?: number;
  adnexalSurface?: number;
  constructionDate?: string;
  reformYear?: string;
  vppModality?: string;
}

export interface AssetCreateCandidatureTenantDto {
  id?: string;
  street: string;
  number: string;
  portal?: string;
  floor?: string;
  door?: string;
  postalCode: string;
  town: string;
  province: string;
  link?: string;
  rentalPrice: number;
  vppModality?: string;
}

export interface CandidatureStatusDto {
  candidatureId: string;
  readyToRequestReport: boolean;
  readyToDownloadReport: boolean;
  missingDocumentation: boolean;
  lastChanges: Date;
}

export class LocationDto {
  longitude: number;
  latitude: number;
}

export interface BuildingDto {
  id?: string;
  portfolioId: string;
  portfolioOwnerId: string;
  street: string;
  number: string;
  postalCode: string;
  town: string;
  province: string;
  location: LocationDto;
  internalCode: string;
  apisId: string[];
  adminDetailsSelected?: ApiMemberDto[];
  creationDate: Date;
  selectedApiId?: string;
  longitude?: string;
  latitude?: string;
  apiAdminId?: string;
  policyHolderData?: PolicyHolderData;
  cadastralReference: string;
  visitedAsset: boolean;
  // Campo solo en front
  statistics?: BuildingStatistic;
  vppModality?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface CreateAssetFromBuildingDto {
  door: string;
  floor: string;
  link: string;
  stairs: string;
  portal: string;
  rentalPrice: number;
  homeownerReference: string;
}

export interface UploadExcelResponse {
  errorRow: number;
  errorMessage: string;
  assets: AssetDto[];
  buildings: BuildingDto[];
}

export interface AssetStatistic {
  id: string;
  buildingId: string;
  location: LocationDto;
  status: string;
  province?: string;
  town?: string;
}

export interface BuildingStatistic {
  open: number;
  rented: number;
  total: number;
}

export interface ChangeLabelDto {
  newLabelId: number;
  assetId: string;
}

export interface ChangeApiDto {
  newApiId: string;
  assetId: string;
  userId: string;
  changeType: string;
}

export interface AssociatedCandidature {
  id: string;
  status: CandidatureStatusEnum;
}

export interface AddAssetClosingModalData {
  edit?: boolean;
  newAsset?: CreateAssetFromBuildingDto;
  selectedApiId?: string;
  apiAdminId?: string;
  saving?: boolean;
}

export interface ReassignApiDto {
  apiId: string;
  adminId: string;
  memberId: string;
  memberEmail: string;
  assets: string[];
}

export interface UpdateMassivePricesDto {
  assets: string[];
  newPrice: number;
  motivation: string;
}

export enum AssetStatus {
  OPEN = 'OPEN',
  RENTED = 'RENTED'
}
