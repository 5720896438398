// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  maintenance: false,
  domain: 'dev.elbueninquilino.es',
  successUrl: 'https://dev.elbueninquilino.es/home-owner/',
  services: {
    users: 'https://dev1-api.elbueninquilino.es/users-api',
    insurances: 'https://dev1-api.elbueninquilino.es/insurances-api',
    supplies: 'https://dev1-api.elbueninquilino.es/supplies-api',
    documents: 'https://dev1-api.elbueninquilino.es/documents-api',
    profiles: 'https://dev1-api.elbueninquilino.es/profiles-api',
    recommendations: 'https://dev1-api.elbueninquilino.es/recommendations-api',
    assets: 'https://dev1-api.elbueninquilino.es/assets-api',
    candidatures: 'https://dev1-api.elbueninquilino.es/candidatures-api',
    analytics: 'https://dev1-api.elbueninquilino.es/analytics-api',
    certifications: 'https://dev1-api.elbueninquilino.es/ebi',
    kamino: 'https://dev1-api.elbueninquilino.es/ebi/kamino',
    zerobounce: 'https://api.zerobounce.net/v2',
    ebiCloudFunctions:
      'https://us-central1-elbueninquilino-b2f53.cloudfunctions.net/ebi',
    notifications: 'https://dev1-api.elbueninquilino.es/notifications-api'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyAsEtlsycA0EhJBxOORMpxvP8c7PQ9t7WY',
    projectId: 'elbueninquilino-b2f53',
    authDomain: 'elbueninquilino-b2f53.firebaseapp.com'
  },
  stripe_key:
    'pk_test_51ICmzuBmSHYUPsLhF9znMKr1BngmJc6hK0lbhPxK6agGE0dMnUfzpb0EszOqMptJN2Ea90Ce3vR02XO1PXRoknmv00xBCaWf4U',
  googleAnalytics: {
    active: false,
    key: 'UA-120900553-5'
  },
  zendesk: {
    active: true,
    id: 'ze-snippet',
    url: 'https://static.zdassets.com/ekr/snippet.js',
    key: '?key=d9ea863c-727a-41ee-b9ff-8f14286931fe'
  },
  osano: {
    active: false,
    file: 'osano.js',
    url: 'https://cmp.osano.com/',
    key: '16CVvFSTvqCPo2qYC/bee6e71c-fcde-4693-91b3-125203118e3d/'
  },
  google_maps: {
    url: 'https://maps.googleapis.com/maps/api/js?libraries=places&language=es&key='
  },
  encryptKey: 'EB1_s3cre7k3y*4*',
  socialSecurityHref:
    'https://portal.seg-social.gob.es/wps/portal/importass/importass/Categorias/Vida+laboral+e+informes/Informes+sobre+tu+situacion+laboral/Informe+de+tu+vida+laboral',
  revisionEmail: 'manugapra+revision@gmail.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
