import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { AnalyticsApiService } from './api-services/analytics-api/analytics-api.service';
import { AssetsApiService } from './api-services/assets-api/assets-api.service';
import { BuildingAssetApiService } from './api-services/building-asset-api/building-asset-api.service';
import { BuildingReportApiService } from './api-services/building-report-api/building-report-api.service';
import { CandidaturesApiService } from './api-services/candidatures-api/candidatures-api.service';
import { CertificationsApiService } from './api-services/certifications-api/certifications-api.service';
import { ContactsApiService } from './api-services/contacts-api/contacts-api.service';
import { CustomerCareApiService } from './api-services/customer-care-api/customer-care-api.service';
import { DocumentsApiService } from './api-services/documenst-api/documents-api.service';
import { HomeownerAnalyticsApiService } from './api-services/homeowner-analytics-api/homeowner-analytics-api.service';
import { InsurancesApiService } from './api-services/insurances-api/insurances-api.service';
import { InsurancesCompanyApiService } from './api-services/insurances-company-api/insurances-company-api.service';
import { NotificationsApiService } from './api-services/notifications-api/notifications-api.service';
import { PreferencesApiService } from './api-services/preferences-api/preferences-api.service';
import { ProfilesApiService } from './api-services/profiles-api/profiles-api.service';
import { RecommendationsApiService } from './api-services/recommendations-api/recommendations-api.service';
import { RequestAnalysisApiService } from './api-services/request-analysis-api/request-analysis-api.service';
import { UsersApiService } from './api-services/users-api/users-api.service';
import { AuthGuard } from './guards/private/auth.guard';
import { BackofficeGuard } from './guards/private/backoffice.guard';
import { FinaerGuard } from './guards/private/finaer.guard';
import { InsuranceCompanyGuard } from './guards/private/insurance-company.guard';
import { MaintenanceGuard } from './guards/public/maintenance.guard';
import { AuthInterceptor } from './interceptors/interceptor';
import { AuthService } from './services/auth/auth.service';
import { FinaerAuthService } from './services/auth/finaer-auth.service';
import { IncofisaAuthService } from './services/auth/incofisa-auth.service';
import { InsuranceCompanyAuthService } from './services/auth/insurance-company-auth.service';
import { BuildingPdfService } from './services/building-pdf/building-pdf.service';
import { CandidatureUtilsService } from './services/candidature-utils/candidature-utils.service';
import { ExportService } from './services/export/export.service';
import { FiltersService } from './services/filters/filters.service';
import { GoogleMapsService } from './services/google-maps/google-maps.service';
import { IncofisaReportPdfService } from './services/incofisa-report-pdf/incofisa-report-pdf.service';
import { InsuranceFeeService } from './services/insurance-fee/insurance-fee.service';
import { LoadingService } from './services/loading/loading.service';
import { MenuService } from './services/menu/menu.service';
import { ModalService } from './services/modal/modal.service';
import { NavigationService } from './services/navigation/navigation.service';
import { SnackBarService } from './services/snack-bar/snack-bar.service';
import { SplitPaneMenuService } from './services/split-pane-menu/split-pane-menu.service';
import { StepsService } from './services/steps/steps.service';
import { StoreService } from './services/store/store.service';
import { TranslationService } from './services/translation/translation.service';
import { TrialFlowService } from './services/trial-flow/trial-flow.service';
import { InsuranceUtilsService } from './services/utils/insurance-utils.service';
import { PhoneMaskService } from './services/utils/phone-mask.service';
import { UserUtilsService } from './services/utils/user-utils.service';
import { UtilsService } from './services/utils/utils.service';
import { WindowService } from './services/window/window.service';
import { WindowMeasuresService } from './services/window-measures/window-measures.service';

const GUARDS = [
  AuthGuard,
  BackofficeGuard,
  FinaerGuard,
  InsuranceCompanyGuard,
  MaintenanceGuard
];
const SERVICES = [
  AuthService,
  BuildingPdfService,
  CandidatureUtilsService,
  ExportService,
  FiltersService,
  FinaerAuthService,
  GoogleMapsService,
  IncofisaAuthService,
  IncofisaReportPdfService,
  InsuranceCompanyAuthService,
  InsuranceFeeService,
  InsuranceUtilsService,
  LoadingService,
  MenuService,
  ModalService,
  NavigationService,
  SnackBarService,
  SplitPaneMenuService,
  StoreService,
  TrialFlowService,
  TranslationService,
  UtilsService,
  WindowMeasuresService,
  WindowService,
  StepsService,
  UserUtilsService,
  PhoneMaskService
];
const INTERCEPTORS = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
const API_SERVICES = [
  AnalyticsApiService,
  AssetsApiService,
  BuildingAssetApiService,
  BuildingReportApiService,
  CandidaturesApiService,
  CertificationsApiService,
  ContactsApiService,
  CustomerCareApiService,
  DocumentsApiService,
  HomeownerAnalyticsApiService,
  InsurancesApiService,
  InsurancesCompanyApiService,
  NotificationsApiService,
  PreferencesApiService,
  ProfilesApiService,
  RecommendationsApiService,
  RequestAnalysisApiService,
  UsersApiService
];

@NgModule({
  imports: [],
  providers: [
    ...GUARDS,
    ...SERVICES,
    ...INTERCEPTORS,
    ...API_SERVICES,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
