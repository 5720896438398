import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  ActivatedRoute,
  Event,
  NavigationStart,
  Params,
  Router
} from '@angular/router';
import { AssetsApiService } from '@core/api-services/assets-api/assets-api.service';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  AnalysisCatalogue,
  AssetDto,
  Candidature,
  CreateAnalysisDto,
  MENU_TABS_HOME_OWNER_SELECTED_CANDIDATURE,
  MenuItem,
  TenantCandidature,
  User
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { IncofisaReportPdfService } from '@core/services/incofisa-report-pdf/incofisa-report-pdf.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { AlertController, ModalController } from '@ionic/angular';
import { first, forkJoin, lastValueFrom, Subject, takeUntil } from 'rxjs';

import { PreviewReportComponent } from '../../components/preview-report/preview-report.component';
import { ButtonsRole } from '../../models/button.model';
import { SelectedCandidatureHistoryState } from '../../models/selected-candidature.model';

@Component({
  selector: 'el-buen-inquilino-selected-candidature-page',
  templateUrl: './selected-candidature-page.component.html'
})
export class SelectedCandidaturePageComponent implements OnInit, OnDestroy {
  asset: AssetDto;
  candidature: Candidature;

  originalPolicyNumber: string;

  client: User;
  totalAnnualReceipt: number;
  initialPrice: number;
  insuranceBrokerPaid = false;
  menuTabs: MenuItem[] = MENU_TABS_HOME_OWNER_SELECTED_CANDIDATURE;
  openTabsActive = 'candidature';
  isBackoffice = false;
  user: User;

  @ViewChild('scoringBusiness', { static: true })
  canvasElement: ElementRef<HTMLCanvasElement>;

  private $destroy = new Subject<void>();

  get partialAddress(): string {
    return `${this.asset?.street}, ${this.asset?.number}`;
  }

  get secondAddress(): string {
    let address = '';

    if (this.asset?.portal) {
      address += `Portal ${this.asset.portal}`;
    }

    if (this.asset?.floor) {
      address += `, Planta ${this.asset.floor}`;
    }

    if (this.asset?.door) {
      address += `, Puerta ${this.asset.door}`;
    }

    return address;
  }

  get fullLocation(): string {
    return `${this.asset?.postalCode} ${this.asset?.town} (${this.asset?.province})`;
  }

  get score(): number {
    if (!this.candidature) {
      return null;
    }
    return this.candidature?.score;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private candidatureService: CandidaturesApiService,
    private assetService: AssetsApiService,
    private modalController: ModalController,
    private loadingService: LoadingService,
    private usersService: UsersApiService,
    private incofisaReportPdfService: IncofisaReportPdfService,
    private alertController: AlertController,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setUser();
    this.setAsset();
    this.setCandidature();
    this.subscribeToRouterNavigation();
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  openComponentFromTab(tab: string): void {
    this.openTabsActive = tab;
  }

  subscribeToRouterNavigation(): void {
    this.router.events
      .pipe(takeUntil(this.$destroy))
      .subscribe((val: Event) => {
        if (val instanceof NavigationStart) {
          delete (history.state as SelectedCandidatureHistoryState).candidature;
          delete (history.state as SelectedCandidatureHistoryState).asset;
          history.pushState(history.state, '');
        }
      });
  }

  return(): void {
    this.router.navigate(['../../..'], {
      relativeTo: this.route
    });
  }

  revertSelectedCandidature(): void {
    if (this.candidature.policyNumber) {
      /* METER EN UN SERVICIO PARA SER LLAMADO DESDE AQUÍ Y CANDIDATURE INFORMATION */
      this.candidatureService.openCancelPolicyRequestModal(
        'DEFAULT',
        this.candidature.policyNumber,
        true,
        this.candidature
      );
    } else {
      this.reOpenCandidature();
    }
  }

  async previewReport(): Promise<void> {
    const candidature = this.candidature;
    await this.loadingService.presentSecondLoader(null, true);

    // Preparamos la llamada para obtener los informes
    const calls: unknown[] = [];

    candidature.tenantCandidatureList.forEach((tc: TenantCandidature) => {
      calls.push(
        this.usersService.getCompletedAnalysis(candidature.id, tc.user.id)
      );
    });

    // Añadimos la llamada para obtener el catálogo del análisis
    calls.push(this.usersService.getCatalogue());

    forkJoin(calls)
      .pipe(first())
      .subscribe({
        next: async (resp: unknown[]) => {
          await this.loadingService.dismissSecondLoader();
          // Recuperamos la información del catálogo y la eliminamos del array
          const catalogue: AnalysisCatalogue = resp.pop() as AnalysisCatalogue;

          const modal = await this.modalController.create({
            component: PreviewReportComponent,
            cssClass: 'preview-report',
            componentProps: {
              reports: resp,
              candidature,
              catalogue
            }
          });

          await modal.present();

          const dismissEvent = await modal.onWillDismiss();

          if (dismissEvent.role === 'download') {
            this.incofisaReportPdfService.getReport(
              candidature,
              resp as CreateAnalysisDto[],
              catalogue,
              this.canvasElement
            );
          }
        },
        error: async () => await this.loadingService.dismissSecondLoader()
      });
  }

  private setUser(): void {
    this.user = this.authService.user;
  }

  private async setAsset(): Promise<void> {
    if (!!(history.state as SelectedCandidatureHistoryState).asset) {
      this.asset = (history.state as SelectedCandidatureHistoryState).asset;
    } else {
      const params: Params = await lastValueFrom(
        this.route.queryParams.pipe(first())
      );

      this.assetService
        .getAsset(params['id'] as string)
        .pipe(first())
        .subscribe((asset: AssetDto) => (this.asset = asset));
    }
  }

  private async setCandidature(): Promise<void> {
    if (!!(history.state as SelectedCandidatureHistoryState).candidature) {
      this.candidature = (
        history.state as SelectedCandidatureHistoryState
      ).candidature;
    } else {
      const params: Params = await lastValueFrom(
        this.route.queryParams.pipe(first())
      );

      this.candidatureService
        .getCandidature(params['candidatureId'] as string)
        .pipe(first())
        .subscribe(
          (candidature: Candidature) => (this.candidature = candidature)
        );
    }
  }

  private async reOpenCandidature(): Promise<void> {
    const alert = await this.alertController.create({
      // eslint-disable-next-line max-len
      message: `¿Estás seguro de que quieres reabrir el inmueble de ${this.partialAddress} ${this.secondAddress}?`,
      buttons: [
        {
          text: 'Cancelar',
          role: ButtonsRole.CANCEL
        },
        {
          text: 'Reabrir',
          role: ButtonsRole.ACCEPT
        }
      ]
    });

    await alert.present();

    const { role } = await alert.onWillDismiss();

    if (role === (ButtonsRole.ACCEPT as string)) {
      this.candidatureService.changeCandidatureStatus(this.candidature);
    }
  }
}
