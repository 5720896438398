export enum InsuranceOptionPopoverEnum {
  SEE_DETAILS = 'SEE_DETAILS',
  CREATE_SINISTER = 'CREATE_SINISTER',
  POLICY_CANCELATION = 'POLICY_CANCELATION',
  UPDATE_SINISTER = 'UPDATE_SINISTER',
  HISTORICAL_DEBT = 'HISTORICAL_DEBT',
  CHRONOLOGY = 'CHRONOLOGY',
  SEE_CANDIDATURE = 'SEE_CANDIDATURE',
  SEE_MODIFICATIONS = 'SEE_MODIFICATIONS',
  SEE_INTERNAL_NOTES = 'SEE_INTERNAL_NOTES'
}

export interface InsuranceOptionPopoverData {
  fromBackoffice: boolean;
  unpaid: boolean;
  policyNumber: string;
  showCancel: boolean;
  isClient: boolean;
  showCreateSinister: boolean;
  isSinisterPage: boolean;
  showSeeDetails: boolean;
  showHistoricalDebt: boolean;
  showChronology: boolean;
  isClosed: boolean;
  seeCandidature: boolean;
}

export interface InsuranceOptionPopoverResp {
  option: InsuranceOptionPopoverEnum;
}
