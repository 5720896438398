/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import {
  PolicyIssue,
  Sinister,
  SinisterThread,
  TypeUserEnum,
  User
} from '@core/models';
import { UtilsService } from '@core/services/utils/utils.service';
import {
  AlertController,
  ModalController,
  ToastController
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { first } from 'rxjs/operators';

@Component({
  selector: 'el-buen-inquilino-cancel-policy-modal',
  templateUrl: './cancel-policy-modal.component.html'
})
export class CancelPolicyModalComponent implements OnInit {
  @Input() user: User;
  @Input() insurance: PolicyIssue;
  @Input() seeDetails = false;
  @Input() isIncofisa = false;
  @Input() unpaid = false;
  isNotifyChecked = false;
  isDisabledDocView = false;

  showSinesterDetail: boolean[] = [];

  form: UntypedFormGroup;
  commentsForm: UntypedFormGroup;

  today: Date = new Date();

  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService,
    private candidatureService: CandidaturesApiService,
    private toastController: ToastController,
    public alertController: AlertController,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const id = `S-${this.insurance.policyNumber}-${
      this.insurance.sinisters.length + 1
    }`;
    this.showSinesterDetail.fill(
      false,
      0,
      this.insurance.sinisters.length || 0
    );
    this.orderSinistersCommentsByDate();
    this.initPrincipalForm(id);
    this.initCommentForm();
  }

  isAddCommentButtonDisabled(): boolean {
    return this.commentsForm.controls?.message?.value?.length < 10;
  }

  dismiss(): void {
    if (this.isIncofisa) {
      this.modalController.dismiss({ insurance: this.insurance });
    } else {
      this.modalController.dismiss();
    }
  }

  save(): void {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    const emailsToNotify: string[] = [this.user.email];

    if (this.isNotifyChecked) {
      emailsToNotify.push(this.form.getRawValue().notifyEmail);
    }

    // TODO:
    // const sinister: Sinister = {
    //   id: this.form.getRawValue().id,
    //   creationDate: new Date(),
    //   startDate: this.form.getRawValue().date,
    //   tenantCommunication: this.form.getRawValue().tenantCommunication,
    //   comments: this.form.getRawValue().comments,
    //   emailsToNotify,
    //   isCancellation: true
    // };

    // this.modalController.dismiss({
    //   sinister
    // });
  }

  toggleNotifyChange(event): void {
    this.isNotifyChecked = event.detail.checked;

    if (this.isNotifyChecked) {
      this.form
        .get('notifyEmail')
        .setValidators([Validators.required, Validators.email]);
    } else {
      this.form.get('notifyEmail').clearValidators();
    }
    this.form.get('notifyEmail').updateValueAndValidity();
  }

  async presentToast(message: string, color: string): Promise<void> {
    //hacer reutilizable
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'top',
      color: color
    });
    toast.present();
  }

  async presentMaximumNumberDocumentsReachedModal(): Promise<void> {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert',
      header: this.translate.instant(
        'pages.documents.create-page.maximum_documents_reached_modal_header'
      ),
      message: this.translate.instant(
        'pages.documents.create-page.maximum_documents_reached_modal_message'
      ),
      buttons: [
        {
          text: this.translate.instant('pages.documents.create-page.ok'),
          cssClass: 'secondary'
        }
      ]
    });
    await alert.present();
  }

  isOwnMessage(thread: SinisterThread): boolean {
    if (thread.userId === this.user.id) {
      return true;
    }
    return false;
  }

  sendNewMessage(sinisterId: string): void {
    this.candidatureService
      .addCommentToPolicySinister(
        this.insurance.id,
        sinisterId,
        this.commentsForm?.controls?.message?.value
      )
      .subscribe((_) => {
        const selectedSinister = this.insurance.sinisters.find(
          (sinister: Sinister) => sinister.id === sinisterId
        );
        selectedSinister.thread.push({
          userId: this.user.id,
          message: this.commentsForm.controls.message.value,
          date: new Date()
        });
        this.commentsForm.controls.message.setValue('');
      });
  }

  replySinister(sinister: Sinister): void {
    this.candidatureService
      .responseAndCloseSinister(this.insurance.id, {
        id: sinister.id,
        response: this.commentsForm?.controls?.message?.value
      })
      .pipe(first())
      .subscribe({
        next: () => {
          const index = this.insurance.sinisters.findIndex(
            (s: Sinister) => s.id === sinister.id
          );
          this.insurance.sinisters[index].response =
            this.commentsForm?.controls?.message?.value;
          this.insurance.sinisters[index].endDate = new Date();
          this.dismiss();
        },
        error: () => {
          this.dismiss();
        }
      });
  }

  isToday(date: Date): boolean {
    date = new Date(date);
    return (
      date.getDay() === this.today.getDay() &&
      date.getMonth() === this.today.getMonth() &&
      date.getFullYear() === this.today.getFullYear()
    );
  }

  getMessageClasses(message: SinisterThread): any {
    const isOwnMessage = this.isOwnMessage(message);
    let isEbiComment = false;
    let isClientComment = false;

    if (this.user.userType === TypeUserEnum.HOMEOWNER) {
      if (message.userId === this.user.id) {
        isClientComment = true;
      } else {
        isEbiComment = true;
      }
    } else {
      if (
        message.userId === this.user.id ||
        (this.insurance.userId !== message.userId &&
          this.user.userType === TypeUserEnum.INCOFISA_ADMINISTRATIVE)
      ) {
        isEbiComment = true;
      } else {
        isClientComment = true;
      }
    }

    return {
      'right-comment': isOwnMessage,
      'left-comment': !isOwnMessage,
      'sinister-ebi-comment': isEbiComment,
      'sinister-client-comment': isClientComment
    };
  }

  onDateChange(event: MatDatepickerInputEvent<Date>): void {
    const formattedDate = moment(event.value).format('DD/MM/YYYY');
    this.form.get('date').setValue(formattedDate);
  }

  private orderSinistersCommentsByDate(): void {
    if (this.insurance.sinisters) {
      this.insurance.sinisters.forEach((sinister: Sinister) => {
        if (sinister.thread) {
          sinister.thread = this.orderThreat(sinister.thread);
        }
      });
    }
  }

  private orderThreat(array: SinisterThread[]): SinisterThread[] {
    return array.sort((a: SinisterThread, b: SinisterThread) => {
      if (a.date > b.date) {
        return -1;
      }
      if (a.date < b.date) {
        return 1;
      }
      return 0;
    });
  }

  private initPrincipalForm(id: string): void {
    this.form = new UntypedFormGroup({
      address: new UntypedFormControl({
        value: this.insurance.insuranceAsset.address,
        disabled: true
      }),
      city: new UntypedFormControl({
        value: this.insurance.insuranceAsset.city,
        disabled: true
      }),
      policyHolder: new UntypedFormControl({
        value: this.insurance.emailPolicyHolder,
        disabled: true
      }),
      id: new UntypedFormControl({ value: id, disabled: true }),
      comments: new UntypedFormControl(null, Validators.required),
      notifyEmail: new UntypedFormControl(null)
    });
  }

  private initCommentForm(): void {
    this.commentsForm = new UntypedFormGroup({
      message: new UntypedFormControl('')
    });
  }
}
