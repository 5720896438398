import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalButtonRole } from '@core/models';
import { UtilsService, prepareFile } from '@core/services/utils/utils.service';
import { ModalController } from '@ionic/angular';

import {
  CancelPolicyRequestModalForm,
  CancelPolicyRequestModalResp
} from '../../models/cancel-policy-request-modal.model';

@Component({
  selector: 'el-buen-inquilino-cancel-policy-request-modal',
  templateUrl: './cancel-policy-request-modal.component.html'
})
export class CancelPolicyRequestModalComponent implements OnInit {
  // Se utiliza el flag para referirse a la reapertura de un activo
  @Input() reopen = false;
  file: File = null;
  form: FormGroup<CancelPolicyRequestModalForm>;

  get fileControl(): FormControl {
    return this.form.controls.file;
  }

  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  dismiss(
    role: CustomModalButtonRole = CustomModalButtonRole.CANCEL,
    file?: File
  ): void {
    const data: CancelPolicyRequestModalResp = {
      file,
      comments: this.form.value.comments
    };
    this.modalController.dismiss(data, role);
  }

  changeListener(event: Event): void {
    this.file = prepareFile((event.target as HTMLInputElement).files[0]);
  }

  accept(): void {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    this.dismiss(CustomModalButtonRole.ACCEPT, this.file);
  }

  deleteFile(): void {
    this.file = null;
  }

  private setForm(): void {
    this.form = new FormGroup<CancelPolicyRequestModalForm>({
      comments: new FormControl<string>(null),
      file: new FormControl<File>(null, Validators.required)
    });
  }
}
