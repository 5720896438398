import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  CustomPopoverButtonRole,
  Sinister,
  SinisterDocumentType,
  SinisterStatusEnum,
  SinisterThread,
  UpdateSinister,
  User
} from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { PhoneMaskService } from '@core/services/utils/phone-mask.service';
import { UtilsService } from '@core/services/utils/utils.service';
import {
  AlertController,
  ModalController,
  ToastController
} from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import * as appValidators from '@shared/utils/app-validators.utils';
import { CountryCode } from 'libphonenumber-js/types.d';
import { first, lastValueFrom } from 'rxjs';

import {
  UpdateSinisterModalForm,
  UpdateSinisterModalFormValue
} from '../../models/update-sinister-modal.model';
import { copyObject } from '../../utils/global.utils';
import { getSinisterDocLabel, getUsers } from '../../utils/sinister.utils';
import { presentToast } from '../../utils/toast.utils';
import { HistoricalDebtModalComponent } from '../historical-debt-modal/historical-debt-modal.component';
import { SinisterChronologyModalComponent } from '../sinister-chronology-modal/sinister-chronology-modal.component';

@Component({
  selector: 'el-buen-inquilino-update-sinister-modal',
  templateUrl: './update-sinister-modal.component.html'
})
export class UpdateSinisterModalComponent implements OnInit {
  @Input() isOwnerPage = false;
  @Input() sinister: Sinister;
  @Input() user: User;
  @Input() seeDetails = false;

  form: FormGroup<UpdateSinisterModalForm>;
  sinisterStatusEnum = SinisterStatusEnum;
  users: User[] = [];
  showSinisterConversation = false;
  today = new Date();
  country: CountryCode = 'ES';
  validationErrorMessages = appValidators.validationErrorMessages;

  get lastUpdatedTotalDebt(): FormControl<Date> {
    return this.form.controls.lastUpdatedTotalDebt;
  }

  get finalDebt(): string {
    return this.form.value.inputDebt;
  }

  get showLawyerTemp(): boolean {
    const validStatus = [
      SinisterStatusEnum.INSURANCE_COMPANY_BUROFAX_SENT,
      SinisterStatusEnum.INSURANCE_COMPANY_COMPLAINT_FILLED,
      SinisterStatusEnum.PENDING_TRIAL,
      SinisterStatusEnum.SCHEDULED_EVICTION
    ];

    return validStatus.includes(this.form.controls.status.value);
  }

  get lawyerData(): string {
    if (this.assignedLawyerControl.value === null) {
      return 'Sin asignar';
    }
    // eslint-disable-next-line max-len
    return `${this.assignedLawyerControl.value} - ${this.lawyerEmailControl.value} - ${this.lawyerPhoneControl.value}`;
  }

  get inputDebtInfoText(): string {
    return 'En este campo debes introducir la deuda total acumulada a día de hoy.';
  }

  get formValue(): UpdateSinisterModalFormValue {
    return this.form.getRawValue();
  }

  get statusControl(): FormControl {
    return this.form.controls.status;
  }
  get insuranceCodeControl(): FormControl {
    return this.form.controls.insuranceCode;
  }
  get totalDebtControl(): FormControl {
    return this.form.controls.totalDebt;
  }
  get inputDebtControl(): FormControl {
    return this.form.controls.inputDebt;
  }
  get lastUpdatedTotalDebtControl(): FormControl {
    return this.form.controls.lastUpdatedTotalDebt;
  }
  get assignedLawyerControl(): FormControl {
    return this.form.controls.assignedLawyer;
  }
  get lawyerPhoneControl(): FormControl<string> {
    return this.form.controls.lawyerPhone;
  }
  get lawyerEmailControl(): FormControl<string> {
    return this.form.controls.lawyerEmail;
  }

  constructor(
    private modalController: ModalController,
    private userService: UsersApiService,
    private loadingService: LoadingService,
    private alertController: AlertController,
    private utilsService: UtilsService,
    private candidatureService: CandidaturesApiService,
    private toastController: ToastController,
    private changeDetectorRef: ChangeDetectorRef,
    private phoneMaskService: PhoneMaskService
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.checkStatus();
    this.getUsers();
  }

  changeCountry(event: CountryCode): void {
    this.lawyerPhoneControl.setValue(null);
    this.lawyerPhoneControl.clearValidators();
    this.country = event;
    this.lawyerPhoneControl.setValidators(this.getPhoneControlValidators());
    this.lawyerPhoneControl.updateValueAndValidity();
  }

  dismiss(saving: boolean = false): void {
    this.modalController.dismiss({ saving });
  }

  async updateSinister(): Promise<void> {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    if (
      this.inputDebtControl.dirty &&
      this.inputDebtControl.touched &&
      !!this.inputDebtControl.value
    ) {
      const alertResp = await this.showUpdateAlert();

      if (alertResp === CustomPopoverButtonRole.CANCEL) {
        return;
      }
    }

    const dataToUpdate: UpdateSinister = {
      ...this.sinister,
      status: this.formValue.status,
      insuranceCode: this.formValue.insuranceCode,
      totalDebt: !!this.formValue.inputDebt ? Number(this.finalDebt) : null,
      inputDebt: !!this.formValue.inputDebt
        ? Number(this.formValue.inputDebt)
        : null,
      lastUpdatedTotalDebt: this.formValue.lastUpdatedTotalDebt,
      assignedLawyer: this.formValue.assignedLawyer,
      lawyerPhone: this.formValue.lawyerPhone,
      lawyerEmail: this.formValue.lawyerEmail
    };

    await this.loadingService.presentSecondLoader(null);
    this.candidatureService
      .updateSinister(dataToUpdate)
      .pipe(first())
      .subscribe({
        next: (resp: Sinister) => this.onSuccessUpdateSinister(resp),
        error: async () => await this.loadingService.dismissSecondLoader()
      });
  }

  async onSuccessUpdateSinister(resp: Sinister): Promise<void> {
    await this.loadingService.dismissSecondLoader();
    this.sinister = resp;
    presentToast(this.toastController, 'Siniestro actualizado correctamente');
    this.dismiss(true);
  }

  async changeSeeDebtHistory(): Promise<void> {
    const modal = await this.modalController.create({
      component: HistoricalDebtModalComponent,
      componentProps: {
        sinister: this.sinister
      } as Partial<HistoricalDebtModalComponent>
    });
    await modal.present();
  }

  async changeSeeChronology(): Promise<void> {
    const modal = await this.modalController.create({
      component: SinisterChronologyModalComponent,
      componentProps: {
        sinister: this.sinister
      } as Partial<SinisterChronologyModalComponent>
    });
    await modal.present();
  }

  statusSelectChange(): void {
    if (this.showLawyerTemp) {
      this.addLawyerControls();
    }
    this.sinister.status = this.statusControl.value as SinisterStatusEnum;
    this.sinister = copyObject(this.sinister) as Sinister;
  }

  async closeSinister(): Promise<void> {
    const alertResp = await this.closeSinisterAlert();

    if (alertResp === CustomPopoverButtonRole.CANCEL) {
      return;
    }

    await this.loadingService.presentSecondLoader(null);
    this.candidatureService
      .closeSinister(this.sinister)
      .pipe(first())
      .subscribe(() => this.onSuccessCloseSinister());
  }

  async onSuccessCloseSinister(): Promise<void> {
    await this.loadingService.dismissSecondLoader();
    presentToast(
      this.toastController,
      'Siniestro finalizado correctamente.',
      'success'
    );
    this.dismiss(true);
  }

  registerNewDoc(event: SinisterDocumentType): void {
    this.registerDocumentComment(`Subida ${getSinisterDocLabel(event)}.`);
  }

  registerDeleteDoc(event: SinisterDocumentType): void {
    this.registerDocumentComment(`Eliminación ${getSinisterDocLabel(event)}.`);
  }

  registerDocumentComment(message: string): void {
    const data: SinisterThread = {
      date: new Date(),
      message,
      userId: this.user.id
    };
    this.candidatureService
      .addCommentToSinister(this.sinister.id, data)
      .pipe(first())
      .subscribe(() => {
        this.sinister.thread.unshift(data);
        this.sinister = copyObject(this.sinister) as Sinister;
      });
  }

  private async closeSinisterAlert(): Promise<CustomPopoverButtonRole> {
    const alert = await this.alertController.create({
      message: '¿Estás seguro de que quieres finalizar el siniestro?',
      buttons: [
        {
          text: 'Cancelar',
          role: CustomPopoverButtonRole.CANCEL
        },
        {
          text: 'Confirmar',
          role: CustomPopoverButtonRole.ACCEPT
        }
      ]
    });

    await alert.present();
    const resp: OverlayEventDetail = await alert.onDidDismiss();
    return resp.role as CustomPopoverButtonRole;
  }

  private async getUsers(): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    this.users = await getUsers(this.userService, this.user, this.sinister);
    this.showSinisterConversation = true;
    await this.loadingService.dismissSecondLoader();
  }

  private async showUpdateAlert(): Promise<CustomPopoverButtonRole> {
    const alert = await this.alertController.create({
      message: `La deuda total acumulada pasará a ser ${this.finalDebt} €`,
      buttons: [
        {
          text: 'Cancelar',
          role: CustomPopoverButtonRole.CANCEL
        },
        {
          text: 'Confirmar',
          role: CustomPopoverButtonRole.ACCEPT
        }
      ]
    });

    await alert.present();
    const resp: OverlayEventDetail = await alert.onDidDismiss();
    return resp.role as CustomPopoverButtonRole;
  }

  private setForm(): void {
    this.form = new FormGroup<UpdateSinisterModalForm>({
      status: this.setStatusControl(),
      insuranceCode: this.setInsuranceCodeControl(),
      totalDebt: this.setTotalDebtControl(),
      lastUpdatedTotalDebt: this.setUpdateDateControl()
    });

    if (!this.seeDetails) {
      this.addInputDebtControl();
    }

    if (this.showLawyerTemp) {
      this.addLawyerControls();
    }
  }

  private addInputDebtControl(): void {
    this.form.addControl('inputDebt', this.setInputDebtControl());
  }

  private addLawyerControls(): void {
    this.form.addControl('assignedLawyer', this.setAssignedLawyerControl());
    this.form.addControl('lawyerPhone', this.setLawyerPhoneControl());
    this.form.addControl('lawyerEmail', this.setLawyerEmailControl());
  }

  private setStatusControl(): FormControl<SinisterStatusEnum> {
    return new FormControl<SinisterStatusEnum>({
      value: this.sinister.status,
      disabled: this.isOwnerPage
    });
  }
  private setInsuranceCodeControl(): FormControl<string> {
    return new FormControl<string>({
      value: this.getInsuranceCodeControlValue(),
      disabled: this.isOwnerPage
    });
  }
  private getInsuranceCodeControlValue(): string {
    let value = this.sinister.insuranceCode;
    if (!value && this.isOwnerPage) {
      value = 'Aún sin asignar';
    }
    return value;
  }
  private setTotalDebtControl(): FormControl<number> {
    return new FormControl<number>({
      value: this.sinister.totalDebt ?? 0,
      disabled: true
    });
  }
  private setInputDebtControl(): FormControl<string> {
    return new FormControl<string>(null);
  }
  private setUpdateDateControl(): FormControl<Date> {
    let value = new Date();
    if (this.seeDetails) {
      value = new Date(this.sinister.lastUpdatedTotalDebt);
    }
    return new FormControl<Date>({
      value,
      disabled: this.seeDetails
    });
  }
  private setAssignedLawyerControl(): FormControl<string> {
    const value = this.sinister?.assignedLawyer || null;
    return new FormControl<string>(
      {
        value,
        disabled: this.seeDetails
      },
      Validators.required
    );
  }
  private setLawyerPhoneControl(): FormControl<string> {
    let value: string = null;
    if (!!this.sinister?.lawyerPhone) {
      value = this.phoneMaskService.formatPhone(
        this.sinister?.lawyerPhone,
        this.country
      );
    }
    return new FormControl<string>(
      {
        value,
        disabled: this.seeDetails
      },
      this.getPhoneControlValidators()
    );
  }
  private getPhoneControlValidators(): ValidatorFn[] {
    return [
      Validators.required,
      appValidators.phonenumberValidator(this.phoneMaskService, this.country)
    ];
  }
  private setLawyerEmailControl(): FormControl<string> {
    const value = this.sinister?.lawyerEmail || null;
    return new FormControl<string>(
      {
        value,
        disabled: this.seeDetails
      },
      Validators.required
    );
  }

  private async checkStatus(): Promise<void> {
    if (!this.sinister.status) {
      let sinister = copyObject(this.sinister) as Sinister;
      sinister.status = SinisterStatusEnum.NOTIFIED_TO_EBI;
      const dataToUpdate: UpdateSinister = {
        ...sinister,
        inputDebt: 0
      };

      const resp = await lastValueFrom(
        this.candidatureService.updateSinister(dataToUpdate).pipe(first())
      );

      if (this.sinister !== resp) {
        sinister = resp;
        this.sinister = copyObject(sinister) as Sinister;
        this.changeDetectorRef.detectChanges();
      }

      this.setForm();
      this.changeDetectorRef.detectChanges();
    }
  }
}
