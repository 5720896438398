import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomPopoverButtonRole } from '@core/models';
import { UtilsService } from '@core/services/utils/utils.service';
import { PopoverController } from '@ionic/angular';

import {
  MultiplePriceAssignModalForm,
  MultiplePriceAssignModalResponse
} from '../../models/multiple-price-assign.model';

@Component({
  selector: 'el-buen-inquilino-multiple-price-assign',
  templateUrl: './multiple-price-assign.component.html'
})
export class MultiplePriceAssignComponent implements OnInit {
  form: FormGroup<MultiplePriceAssignModalForm>;
  customPopoverButtonRole = CustomPopoverButtonRole;

  get newPriceControl(): FormControl<number> {
    return this.form.get('newPrice') as FormControl<number>;
  }

  get motivationControl(): FormControl<string> {
    return this.form.get('motivation') as FormControl<string>;
  }

  constructor(
    private popoverController: PopoverController,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  dismiss(
    role: CustomPopoverButtonRole,
    data?: MultiplePriceAssignModalResponse
  ): void {
    this.popoverController.dismiss(data, role);
  }

  assign(): void {
    if (this.form.invalid) {
      this.utilsService.showFormErrors(this.form);
      return;
    }

    this.dismiss(
      CustomPopoverButtonRole.ACCEPT,
      this.form.getRawValue() as MultiplePriceAssignModalResponse
    );
  }

  private setForm(): void {
    this.form = new FormGroup<MultiplePriceAssignModalForm>({
      newPrice: new FormControl<number>(null, Validators.required),
      motivation: new FormControl<string>(null, Validators.required)
    });
  }
}
