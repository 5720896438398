import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SelectedCandidatureModalComponent } from '@shared/components/selected-candidature-modal/selected-candidature-modal.component';
import { isCandidatureClosed } from '@shared/utils/sinister.utils';

import {
  Candidature,
  CandidatureStatusEnum,
  CandidatureStatusModalEnum,
  CheckMinimumDoc,
  Incidence,
  TenantCandidature
} from '../../models';
import { UtilsService } from '../utils/utils.service';

@Injectable()
export class CandidatureUtilsService {
  constructor(
    private utilsService: UtilsService,
    private translate: TranslateService,
    private modalController: ModalController
  ) {}

  getCandidatureColorStatus(candidature: Candidature): string {
    let status = this.getCandidatureStatus(candidature);
    if (status === 'pre-selected' || status === 'revision-owner') {
      status = 'revision';
    }
    if (status === 'pending') {
      status = 'unfilled';
    }
    return `status-${status}`;
  }

  getCandidatureTextStatus(candidature: Candidature): string {
    const status = this.getCandidatureStatus(candidature);
    let msg: string;
    switch (status) {
      case 'pending':
        // eslint-disable-next-line max-len
        msg = `enums.candidatureStatusTenant.NO_REGISTERED`;
        break;
      case 'unfilled':
        // eslint-disable-next-line max-len
        msg = `enums.candidatureStatusTenant.${CandidatureStatusEnum.WITHOUT_CHECKING}`;
        break;
      case 'incidence':
        msg = `enums.candidatureStatusTenant.INCIDENCE`;
        break;
      case 'rejected-tenant':
        msg = `enums.candidatureStatusTenant.REJECTED_TENANT`;
        break;
      case 'pre-selected':
        msg = `enums.candidatureStatusTenant.${CandidatureStatusEnum.PENDING}`;
        break;
      case 'expired':
        msg = `enums.candidatureStatusTenant.${CandidatureStatusEnum.EXPIRED}`;
        break;
      case 'revision-ebi':
        msg = 'enums.candidatureStatusTenant.REVISION-EBI';
        break;
      case 'revision-owner':
        msg = 'enums.candidatureStatusTenant.REVISION-OWNER';
        break;
      default:
        // eslint-disable-next-line max-len
        msg = `enums.candidatureStatusTenant.${candidature.candidatureStatusEnum}`;
    }

    return this.translate.instant(msg) as string;
  }

  isRejectedByTenant(candidature: Candidature): boolean {
    return candidature.tenantCandidatureList.some(
      (tenant: TenantCandidature) =>
        tenant.userCandidatureStatusDtoEnum === 'REJECT'
    );
  }

  getTenantStatus(tenant: TenantCandidature): string {
    let cause: string;

    // Comprobamos si no ha completado el registro
    if (!(tenant && tenant.user && tenant.user.id)) {
      cause = 'unregistered';
    } else {
      // Obtenemos el rol del tenant
      const tenantRole = this.getTenantRole(tenant);

      // Comprobamos si tiene la documentacióm completa
      const doc = this.checkTenantsDocumentation(tenant, tenantRole);
      if (!doc) {
        cause = 'doc';
      } else if (
        tenant.incidences?.length > 0 &&
        this.thereIsIncidence(tenant.incidences)
      ) {
        cause = 'incidence';
      }
    }

    return cause;
  }

  checkLiveCandidatureStatus(candidature: Candidature): boolean {
    switch (candidature?.candidatureStatusEnum) {
      case CandidatureStatusEnum.WITHOUT_CHECKING:
        return true;

      case CandidatureStatusEnum.PENDING:
        return true;

      case CandidatureStatusEnum.PRE_SELECT:
        return true;

      default:
        return false;
    }
  }

  getScoreColor(progress: number): string {
    if (progress < 35) {
      return 'danger';
    } else if (progress >= 80) {
      return 'success';
    } else {
      return 'warning';
    }
  }

  thereIsIncidence(incidences: Incidence[]): boolean {
    return incidences.some((i: Incidence) => !i.endDate);
  }

  getCandidatureStatus(candidature: Candidature): string {
    if (candidature.candidatureStatusEnum === CandidatureStatusEnum.REJECT) {
      return 'rejected';
    }
    if (this.isRejectedByTenant(candidature)) {
      return 'rejected-tenant';
    }

    // Si alguno de los tenants no está registrado
    if (this.checkUnregisteredTenants(candidature)) {
      return 'pending';
    }

    let noDocuments = false;
    for (
      let index = 0;
      index < candidature.tenantCandidatureList.length;
      index++
    ) {
      const tenant = candidature.tenantCandidatureList[index];
      const role = this.getTenantRole(tenant);
      const isCompleted = this.checkTenantsDocumentation(tenant, role);
      if (!isCompleted) {
        noDocuments = true;
        break;
      }
    }

    // Estado Caducado
    if (new Date(candidature.expirationDate) < new Date()) {
      candidature.candidatureStatusEnum = CandidatureStatusEnum.EXPIRED;
      return 'expired';
    }

    // Estado incompleto
    if (noDocuments && this.checkLiveCandidatureStatus(candidature)) {
      return 'unfilled';
    }

    let incidence = false;
    for (
      let index = 0;
      index < candidature.tenantCandidatureList.length;
      index++
    ) {
      const incidenceList = candidature.tenantCandidatureList[index].incidences;
      if (incidenceList?.length > 0) {
        const isIncidence = this.thereIsIncidence(incidenceList);
        if (isIncidence) {
          incidence = true;
          break;
        }
      }
    }

    // Estado incidencia
    if (incidence) {
      return 'incidence';
    } else if (
      candidature.candidatureStatusEnum === CandidatureStatusEnum.PENDING &&
      !!candidature.tenantCandidatureList[0].analysisId &&
      !candidature.score
    ) {
      return 'revision-ebi';
    } else if (
      candidature.candidatureStatusEnum === CandidatureStatusEnum.PENDING &&
      candidature.selectDate !== null
    ) {
      return 'revision';
    } else if (
      candidature.candidatureStatusEnum === CandidatureStatusEnum.PENDING &&
      candidature.tenantCandidatureList[0].analysisId !== null &&
      candidature.score
    ) {
      return 'revision-owner';
    } else {
      switch (candidature.candidatureStatusEnum) {
        case CandidatureStatusEnum.PENDING:
          return 'revision';
        case CandidatureStatusEnum.PRE_SELECT:
          return 'pre-selected';
        case CandidatureStatusEnum.SELECT:
          return 'selected';
        case CandidatureStatusEnum.WITHOUT_CHECKING:
          return this.isRejectedByTenant(candidature)
            ? 'rejected-tenant'
            : 'unfilled';
        case CandidatureStatusEnum.EXPIRED:
          return 'expired';
      }
    }
  }
  checkUnregisteredTenants(candidature: Candidature): boolean {
    return candidature.tenantCandidatureList.some(
      (tenant: TenantCandidature) => !tenant.user.id
    );
  }

  /**
   * Obtiene el rol del tenant
   * @param tenant
   * @returns Rol del tenant
   */
  getTenantRole(tenant: TenantCandidature): string {
    let role: string;

    if (tenant.user.guarantor) {
      role = 'guarantor';
    } else if (tenant.user.student) {
      role = 'student';
    } else if (tenant.user.business) {
      role = 'business';
    } else if (tenant.user.retired) {
      role = 'retired';
    } else if (tenant.user.freelance) {
      role = 'freelance';
    } else {
      role = 'worker';
    }

    return role;
  }

  getCandidatureStatusForCandidatureStatusModal(
    candidature: Candidature
  ): string {
    let status = this.getCandidatureStatus(candidature);
    if (status) {
      if (status === 'unfilled') {
        status = 'documents';
      }
      if (status === 'pre-selected' || status === 'revision-owner') {
        status = 'revision';
      }
    }
    return status;
  }

  showStatusHelpIcon(candidature: Candidature): boolean {
    const status =
      this.getCandidatureStatusForCandidatureStatusModal(candidature);

    return Object.keys(CandidatureStatusModalEnum).some(
      (key: string) => CandidatureStatusModalEnum[key] === status
    );
  }

  async presentSelectedCandidatureModal(
    candidature: Candidature
  ): Promise<void> {
    const closedOperation: boolean = isCandidatureClosed(candidature);
    const modal = await this.modalController.create({
      component: SelectedCandidatureModalComponent,
      cssClass: 'auto-height modal-extend',
      componentProps: {
        candidature,
        closedOperation
      },
      id: 'selected-candidature'
    });
    await modal.present();
  }

  /**
   * Comprueba si el tenant tiene toda la documentación mínima
   * necesaria por su rol
   * @param tenant
   * @param role
   * @returns boolean
   */
  private checkTenantsDocumentation(
    tenant: TenantCandidature,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    role: string
  ): boolean {
    const minDoc: CheckMinimumDoc = {
      student: tenant?.user?.student || false,
      freelance: tenant?.user?.freelance || false,
      retired: tenant?.user?.retired || false,
      business: tenant?.user?.business || false,
      hasDni: tenant?.hasDni || false,
      hasRent: tenant?.hasRent || false,
      hasModel130: tenant?.hasModel130 || false,
      hasIvaQuarterModel: tenant?.hasIvaQuarterModel || false,
      hasPensionerRecognition: tenant?.hasPensionerRecognition || false,
      hasIs200Model: tenant?.hasIs200Model || false,
      hasPayslips: tenant?.hasPayslip || false,
      hasSSCertificate: tenant?.hasSSCertificate || false,
      hasAEATCertificate: tenant?.hasAEATCertificate || false,
      hasPYGQuarter: tenant?.hasPYGQuarter || false
    };
    return this.utilsService.checkMinimumDocForTenantRol(minDoc);
  }
}
