export class PageDto<T> {
  elements: T[];
}

export class MyCustomEvent {
  value?: string | number | boolean;
  checked?: boolean;
  data?: string;
}

export interface FirebaseDate {
  _seconds: number;
  _nanoseconds: number;
}

export enum CustomPopoverButtonRole {
  CANCEL = 'CANCEL',
  ACCEPT = 'ACCEPT'
}

export enum CustomModalButtonRole {
  ACCEPT = 'ACCEPT',
  CANCEL = 'CANCEL',
  CREATE = 'CREATE',
  EDIT = 'EDIT'
}

export interface DateRangeFilter {
  dateFrom: Date;
  dateTo: Date;
}

export interface ErrorResponse {
  code: string;
  description: string;
  detail?: string;
}

export interface GeneralModalResponse {
  saving?: boolean;
  dismissed?: boolean;
}
