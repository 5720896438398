import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

import { Incidence } from '../../models';

@Injectable()
export class IncidencesApiService {
  private url: string;

  constructor(private http: HttpClient) {
    this.url = environment.services.spaces;
  }

  getIncidencesByCandidature(candidatureId: string): Observable<Incidence[]> {
    const endpoint = `${this.url}/incidences/${candidatureId}/by-candidature`;

    return this.http.get<Incidence[]>(endpoint);
  }
}
