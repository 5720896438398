/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, forkJoin, of } from 'rxjs';
import {
  catchError,
  concatMap,
  defaultIfEmpty,
  first,
  map
} from 'rxjs/operators';

import {
  AragInsuranceDto,
  AssetDto,
  BrokerDto,
  CalculatedPremiumAragDto,
  Candidature,
  CandidatureDto,
  CandidatureStatusEnum,
  ChangePolicyPaymentStatusDto,
  ChangeTenantEmailObj,
  ChangeUserRoleDto,
  ComercialNote,
  DocumentDTO,
  MoveCandidatureRequest,
  PageDto,
  PolicyIssue,
  PricingFiatcDto,
  ReSendEmailDto,
  ScoreResponseDto,
  ShareProfileDto,
  Sinister,
  SinisterDocumentType,
  SinisterThread,
  TenantCandidature,
  TrialCandidatureRequestDto,
  TypeDocumentEnum,
  UpdateSinister,
  UpdateUserStatusResponseDto,
  User,
  UserAnalysisStatusEnum,
  UserDocuments,
  UserRequestCreateCandidatureDto,
  UserRequestCreateMultipleCandidatureDto,
  ValidateUserDto
} from '../../models';
import { UtilsService } from '../../services/utils/utils.service';
import { DocumentsApiService } from '../documenst-api/documents-api.service';
import { ProfilesApiService } from '../profiles-api/profiles-api.service';
import { UsersApiService } from '../users-api/users-api.service';

@Injectable()
export class CandidaturesApiService {
  url: string;
  shouldBeRefreshCandidature = false;
  selectedCandidature: Candidature;
  userFillEventEmitter: EventEmitter<TenantCandidature> =
    new EventEmitter<TenantCandidature>();

  constructor(
    private http: HttpClient,
    private profileService: ProfilesApiService,
    private docService: DocumentsApiService,
    private usersService: UsersApiService,
    private utils: UtilsService
  ) {
    this.url = environment.services.candidatures;
  }

  homeOwnerRegisterCandidatureForAllSelected(
    assetId: string,
    user: UserRequestCreateCandidatureDto
  ): Observable<unknown> {
    return this.http.post<unknown>(
      this.url + '/assets/' + assetId + '/candidatures',
      user
    );
  }

  homeOwnerRegisterCandidatureMultipleForAllSelected(
    assetId: string,
    userList: UserRequestCreateMultipleCandidatureDto
  ): Observable<unknown> {
    return this.http.post<unknown>(
      this.url + '/assets/' + assetId + '/multiple-candidature',
      userList
    );
  }

  share(shareProfile: ShareProfileDto): Observable<ShareProfileDto> {
    return this.http.post<ShareProfileDto>(
      this.url + '/share-profile',
      shareProfile
    );
  }

  getCandidatures(
    // eslint-disable-next-line @typescript-eslint/typedef
    numElements = '50',
    afterElementId?: string,
    assetId?: string,
    status?: CandidatureStatusEnum,
    email?: string
  ): Observable<Candidature[]> {
    let httpParams = new HttpParams().append('numElements', numElements);
    if (afterElementId) {
      httpParams = httpParams.append('afterElementId', afterElementId);
    }
    if (assetId) {
      httpParams = httpParams.append('assetId', assetId);
    }
    if (status) {
      httpParams = httpParams.append('status', status);
    }
    if (email) {
      httpParams = httpParams.append('email', email);
    }
    return this.http
      .get<PageDto<Candidature>>(this.url + '/candidatures', {
        params: httpParams
      })
      .pipe(map((res: PageDto<Candidature>) => res.elements));
  }

  getClosedCandidaturesForBuildings(
    assetId?: string,
    apiId?: string,
    portfolioId?: string
  ): Observable<Candidature[]> {
    const httpParams = new HttpParams()
      .append('numElements', 50)
      .append('apiId', apiId)
      .append('portfolioId', portfolioId)
      .append('assetId', assetId)
      .append('status', CandidatureStatusEnum.SELECT);
    return this.http
      .get<PageDto<Candidature>>(this.url + '/candidatures/for-buildings', {
        params: httpParams
      })
      .pipe(map((res: PageDto<Candidature>) => res.elements));
  }

  getCandidaturesForBuildings(
    assetId?: string,
    apiId?: string,
    portfolioId?: string,
    status?: CandidatureStatusEnum
  ): Observable<Candidature[]> {
    let httpParams = new HttpParams()
      .append('numElements', 50)
      .append('apiId', apiId)
      .append('portfolioId', portfolioId)
      .append('assetId', assetId);
    if (status) {
      httpParams = httpParams.append('status', status);
    }

    return this.http
      .get<PageDto<Candidature>>(this.url + '/candidatures/for-buildings', {
        params: httpParams
      })
      .pipe(
        concatMap((candidatures) => {
          return forkJoin(
            (candidatures.elements || []).map((candidature) => {
              return forkJoin(
                (candidature.tenantCandidatureList || []).map((tenant) =>
                  this.getExtendedTenant(tenant, candidature)
                )
              ).pipe(
                defaultIfEmpty(null),
                map((extendedTenantCandidatureList) => ({
                  ...candidature,
                  tenantCandidatureList: extendedTenantCandidatureList || []
                }))
              );
            })
          ).pipe(defaultIfEmpty([]));
        }),
        map((extendedCandidatures) => {
          return extendedCandidatures.filter(
            (e: Candidature) =>
              !(
                e.tenantCandidatureList.length === 0 &&
                e.candidatureStatusEnum === CandidatureStatusEnum.REJECT
              )
          );
        })
      );
  }

  getTenantsData(idUsers: string[]) {
    idUsers.forEach((idUser) => {
      forkJoin({
        documents: this.checkDocuments(idUser).pipe(catchError(() => of(null))),
        requestDocumentation: this.usersService
          .getRequestDocumentation(idUser)
          .pipe(catchError(() => of(null))),
        user: this.usersService
          .getUserObservable(idUser)
          .pipe(catchError(() => of(null))),
        professionalData: this.usersService
          .getProfessionalData(idUser)
          .pipe(catchError(() => of(null)))
      })
        .pipe(first())
        .subscribe({
          next: (responses) => {
            const userData: TenantCandidature = {
              hasDni: responses.documents?.hasDni,
              hasPayslip: responses.documents?.hasPayslip,
              lastPayslipAmount: responses.documents?.lastPayslipAmount,
              dni: responses.documents?.dni,
              payslips: responses.documents?.payslips,
              rent: responses.documents?.rent,
              is200Model: responses.documents?.is200Model,
              ivaQuarterModel: responses.documents?.ivaQuarterModel,
              hasRent: responses.documents?.hasRent,
              otherDocument: responses.documents?.otherDocument,
              hasOtherDocument: responses.documents?.hasOtherDocument,
              pensionerRecognition: responses.documents?.pensionerRecognition,
              hasPensionerRecognition:
                responses.documents?.hasPensionerRecognition,
              pensionerTotalAmount: responses.documents?.pensionerTotalAmount,
              hasIs200Model: responses.documents?.hasIs200Model,
              hasIvaQuarterModel: responses.documents?.hasIvaQuarterModel,
              workLife: responses.documents?.workLife,
              hasWorkLife: responses.documents?.hasWorkLife,
              model130: responses.documents?.model130,
              hasModel130: responses.documents?.hasModel130,
              ssCertificate: responses.documents?.ssCertificate,
              hasSSCertificate: responses.documents?.hasSSCertificate,
              aeatCertificate: responses.documents?.aeatCertificate,
              hasAEATCertificate: responses.documents?.hasAEATCertificate,
              pygQuarter: responses.documents?.pygQuarter,
              hasPYGQuarter: responses.documents?.hasPYGQuarter,
              requestDocumentation: responses.requestDocumentation
            };
            if (responses.user) {
              userData.user = responses.user;
              userData.user.freelance = responses.professionalData?.freelance;
            }
            this.userFillEventEmitter.emit(userData);
          }
        });
    });
  }

  getCandidaturesByEmail(
    email: string,
    status?: any
  ): Observable<Candidature[]> {
    const emailReplaced = email.replace('+', '%2B');
    return this.getCandidatures('50', '0', null, status, emailReplaced);
  }

  getCandidaturesById(id: string): Observable<Candidature> {
    return this.http.get<Candidature>(`${this.url}/candidatures/${id}`);
  }

  getCandidaturesByTenantEmail(email: string): Observable<Candidature[]> {
    return this.http.get<Candidature[]>(
      `${this.url}/candidatures/by-tenant-email/${email}`
    );
  }

  getCandidaturesByTenantId(tenantId: string): Observable<unknown> {
    return this.http.get(this.url + '/candidatures/tenant/' + tenantId);
  }

  getCandidaturesByAsset(assetd: string): Observable<Candidature[]> {
    return this.getCandidatures('50', '0', assetd).pipe(
      concatMap((candidatures) => {
        return forkJoin(
          (candidatures || []).map((candidature) => {
            return forkJoin(
              (candidature.tenantCandidatureList || []).map((tenant) =>
                this.getExtendedTenant(tenant, candidature)
              )
            ).pipe(
              defaultIfEmpty(null),
              map((extendedTenantCandidatureList) => ({
                ...candidature,
                tenantCandidatureList: extendedTenantCandidatureList || []
              }))
            );
          })
        ).pipe(defaultIfEmpty([]));
      }),
      map((extendedCandidatures) => {
        return extendedCandidatures.filter(
          (e: Candidature) =>
            !(
              e.tenantCandidatureList.length === 0 &&
              e.candidatureStatusEnum === CandidatureStatusEnum.REJECT
            )
        );
      })
    );
  }

  checkDocuments(idUser: string): Observable<UserDocuments> {
    const docs: UserDocuments = new UserDocuments();
    return this.docService.getDocuments('20', idUser).pipe(
      map((documents) => {
        const payslips = documents?.filter(
          (document) => document.typeDocument === TypeDocumentEnum.PAYSLIP
        );
        const dni = documents?.filter(
          (document) => document.typeDocument === TypeDocumentEnum.DNI
        );
        const rent = documents?.filter(
          (document) =>
            document.typeDocument === TypeDocumentEnum.INCOME_TAX_DECLARATION
        );
        const pensionerRecognition = documents?.filter(
          (document) =>
            document.typeDocument === TypeDocumentEnum.PENSIONER_RECOGNITION
        );
        const is200Model = documents?.filter(
          (document) => document.typeDocument === TypeDocumentEnum.IS200_MODEL
        );
        const ivaQuarterModel = documents?.filter(
          (document) =>
            document.typeDocument === TypeDocumentEnum.IVA_QUARTER_MODEL
        );
        const workLife = documents?.filter(
          (document) => document.typeDocument === TypeDocumentEnum.WORK_LIFE
        );

        const model130 = documents?.filter(
          (document) => document.typeDocument === TypeDocumentEnum.MODEL_130
        );

        const ssCertificate = documents?.filter(
          (document) =>
            document.typeDocument === TypeDocumentEnum.SS_CERTIFICATE
        );
        const aeatCertificate = documents?.filter(
          (document) =>
            document.typeDocument === TypeDocumentEnum.AEAT_CERTIFICATE
        );
        const pygQuarter = documents?.filter(
          (document) => document.typeDocument === TypeDocumentEnum.PYG_QUARTER
        );

        const otherDocument = documents?.filter(
          (document) =>
            document.typeDocument === TypeDocumentEnum.OTHER_DOCUMENT
        );
        docs.hasRent = rent?.length > 0;
        docs.rent = rent;
        docs.dni = dni;
        docs.payslips = payslips;
        docs.hasDni = dni?.length > 0;
        //docs.hasPayslip = payslips.length > 1;
        docs.otherDocument = otherDocument;
        docs.hasOtherDocument = otherDocument?.length > 0;
        if (payslips?.length > 0) {
          const latestsPayslip = payslips.reduce((a, b) =>
            a.date > b.date ? a : b
          );
          docs.lastPayslipAmount = latestsPayslip.netAmount;
        }
        docs.pensionerRecognition = pensionerRecognition;
        docs.hasPensionerRecognition = pensionerRecognition?.length > 0;
        if (pensionerRecognition?.length > 0) {
          docs.pensionerTotalAmount = pensionerRecognition[0].totalAmount;
        }
        docs.is200Model = is200Model;
        docs.ivaQuarterModel = ivaQuarterModel;
        docs.workLife = workLife;
        docs.model130 = model130;
        docs.ssCertificate = ssCertificate;
        docs.aeatCertificate = aeatCertificate;
        docs.pygQuarter = pygQuarter;
        const minimaldocs = this.utils.checkMinimalDocumentation(documents);
        docs.hasDni = minimaldocs.hasDni;
        docs.hasPayslip = minimaldocs.hasPayslips;
        docs.hasRent = minimaldocs.hasRent;
        docs.hasOtherDocument = minimaldocs.hasOtherDocuments;
        docs.hasPensionerRecognition = minimaldocs.hasPensionerRecognition;
        docs.hasWorkLife = minimaldocs.hasWorkLife;
        docs.hasIs200Model = minimaldocs.hasIs200Model;
        docs.hasIvaQuarterModel = minimaldocs.hasIvaQuarterModel;
        docs.hasOneQuarterModel = minimaldocs.hasOneQuarterModel;
        docs.hasTwoQuarterModel = minimaldocs.hasTwoQuarterModel;
        docs.hasModel130 = minimaldocs.hasModel130;
        docs.hasSSCertificate = minimaldocs.hasSSCertificate;
        docs.hasAEATCertificate = minimaldocs.hasAEATCertificate;
        docs.hasPYGQuarter = minimaldocs.hasPYGQuarter;
        docs.hasOneMonthPayslips = minimaldocs.hasOneMonthPayslips;
        docs.hasTwoMonthPayslips = minimaldocs.hasTwoMonthPayslips;
        return docs;
      })
    );
  }
  getCandidaturesByUser(id: string): Observable<Candidature[]> {
    return this.getCandidatures(
      '50',
      '0',
      null,
      CandidatureStatusEnum.WITHOUT_CHECKING
    ).pipe(
      map((candidatures) => {
        const result: Candidature[] = [];
        if (candidatures && candidatures.length > 0) {
          for (const candidature of candidatures) {
            for (const tenant of candidature.tenantCandidatureList) {
              if (
                tenant.user.id === id &&
                tenant.userCandidatureStatusDtoEnum ===
                  CandidatureStatusEnum.WITHOUT_CHECKING
              ) {
                result.push(candidature);
              }
            }
          }
          return result;
        } else {
          return candidatures;
        }
      })
    );
  }

  getCandidature(candidatureId: string): Observable<Candidature> {
    return this.http.get<Candidature>(
      this.url + '/candidatures/' + candidatureId
    );
  }

  updateStatus(candidature: Candidature, status?: string): Observable<unknown> {
    if (status) {
      return this.http.patch(this.url + '/candidatures/' + candidature.id, {
        status
      });
    } else {
      return this.http.patch(this.url + '/candidatures/' + candidature.id, {
        status: candidature.candidatureStatusEnum
      });
    }
  }

  updateCandidatureStatus(
    candidature: Candidature,
    status: string,
    notify: boolean
  ): Observable<unknown> {
    return this.http.patch(this.url + '/candidatures/' + candidature.id, {
      status,
      notify
    });
  }

  updateUserStatus(
    candidatureId: string,
    userId: string,
    status: string
  ): Observable<UpdateUserStatusResponseDto> {
    return this.http.patch<UpdateUserStatusResponseDto>(
      this.url + '/candidatures/' + candidatureId + '/user-status/' + userId,
      { status }
    );
  }

  updateAsset(candidatureId: string, assetId: string) {
    return this.http.patch(this.url + '/candidatures/' + candidatureId, {
      assetId
    });
  }

  createInsurancePolicyArag(
    candidatureId: string,
    aragInsuranceDto: AragInsuranceDto
  ): Observable<unknown> {
    return this.http.post(
      this.url + '/candidatures/contractInsurancePolicy/' + candidatureId,
      aragInsuranceDto,
      {
        responseType: 'blob'
      }
    );
  }

  createTenantInsurance(
    candidatureId: string,
    aragInsuranceDto: AragInsuranceDto
  ): Observable<unknown> {
    return this.http.post(
      this.url + '/candidatures/createTenantInsurance/' + candidatureId,
      aragInsuranceDto
    );
  }

  generateTenantPolicyPDF(policyNumber: string): Observable<void> {
    return this.http.get<void>(
      this.url + '/candidatures/generate-pdf/' + policyNumber
    );
  }

  getCalculatedPremiumArag(
    candidatureId: string,
    userId?: string
  ): Observable<CalculatedPremiumAragDto> {
    let httpParams = new HttpParams();
    if (userId) {
      httpParams = httpParams.append('userId', userId);
    }
    return this.http.get<CalculatedPremiumAragDto>(
      `${this.url}/candidatures/${candidatureId}/calculateInsurancePremium`,
      { params: httpParams }
    );
  }

  getPricingFiatC(candidatureId: string): Observable<PricingFiatcDto> {
    return this.http.get<PricingFiatcDto>(
      `${this.url}/candidatures/${candidatureId}/pricing-fiatc`
    );
  }

  recalculateInsurancePremium(
    candidatureId: string,
    score: number,
    rentalPrice: number,
    vandalismCoverage: string,
    insuranceRetention: string,
    cleaningCoverage: string
  ): Observable<CalculatedPremiumAragDto> {
    const request = {
      score,
      rentalPrice,
      vandalismCoverage,
      insuranceRetention,
      cleaningCoverage
    };
    return this.http.post<CalculatedPremiumAragDto>(
      this.url +
        '/candidatures/' +
        candidatureId +
        '/recalculateInsurancePremium',
      request
    );
  }

  getPolicyReport(userId: string, policyId: string): Observable<unknown> {
    return this.http.get(
      this.url + '/candidatures/policies/' + userId + '/' + policyId,
      {
        responseType: 'blob'
      }
    );
  }

  requestMutipleAnalysis(
    candidature: Candidature,
    reLaunched?: boolean,
    rentalPrice?: number
  ): Observable<unknown> {
    return this.usersService.requestAnalysis(
      candidature.id,
      reLaunched,
      rentalPrice,
      null
    );
  }

  requestAnalysis(
    candidatureId: string,
    homeownerId?: string
  ): Observable<unknown> {
    return this.usersService.requestAnalysis(
      candidatureId,
      false,
      null,
      homeownerId
    );
  }

  updateCandidaturesCotenants(
    invitingTenantId: string,
    invitedTenantId: string
  ): Observable<unknown> {
    return this.http.patch(this.url + '/candidatures/', {
      invitingTenantId,
      invitedTenantId
    });
  }

  deletePreviousCandidatures(invitedTenant: string): Observable<unknown> {
    return this.http.delete(
      this.url + '/candidatures/' + invitedTenant + '/co-tenants'
    );
  }

  reSendEmailToTenant(reSendEmailDto: ReSendEmailDto): Observable<unknown> {
    return this.http.patch(
      this.url + '/candidatures/resend-invitation-tenant',
      reSendEmailDto
    );
  }

  changeTenantEmailForNewTenantsAndSendEmail(
    changeTenantEmailObj: ChangeTenantEmailObj
  ) {
    return this.http.patch(
      this.url + '/candidatures/edit-and-resend-tenant-mail',
      changeTenantEmailObj
    );
  }

  /************  Auxiliar functions ****************/

  updateColor(progress) {
    if (progress < 35) {
      return 'danger';
    } else if (progress >= 80) {
      return 'success';
    } else {
      return 'warning';
    }
  }

  getBuildingPolicyNumberAndThirdParty(
    assetId: string,
    apiId?: string,
    portfolioId?: string
  ): Observable<unknown> {
    return this.getClosedCandidaturesForBuildings(
      assetId,
      apiId,
      portfolioId
    ).pipe(
      map((data: Candidature[]) => {
        return {
          id: assetId,
          policyNumber: data?.some(
            (candidature) => candidature.policyNumber !== null
          ),
          isThirdParty: data?.every(
            (candidature) => candidature.thirdParty === true
          ),
          tenantPolicyNumber: data?.some(
            (candidature) =>
              candidature.tenantPolicyNumber !== null &&
              candidature.tenantPolicyNumber !== 'DRAFT'
          )
        };
      })
    );
  }

  getAssetPolicyNumberAndThirdParty(assetId: string): Observable<unknown> {
    return this.getCandidatures(
      '50',
      '0',
      assetId,
      CandidatureStatusEnum.SELECT
    ).pipe(
      map((data: Candidature[]) => {
        return {
          id: assetId,
          policyNumber: data?.some(
            (candidature) => candidature.policyNumber !== null
          ),
          isThirdParty: data?.every(
            (candidature) => candidature.thirdParty === true
          ),
          tenantPolicyNumber: data?.some(
            (candidature) =>
              candidature.tenantPolicyNumber !== null &&
              candidature.tenantPolicyNumber !== 'DRAFT'
          )
        };
      })
    );
  }

  changePolicyBlockedStatus(
    candidatureId: string,
    status: boolean
  ): Observable<unknown> {
    return this.http.patch(
      this.url + `/candidatures/${candidatureId}/block-policy`,
      {
        blockPolicy: status
      }
    );
  }

  verifyTenantTrialFlow(
    userData: ValidateUserDto
  ): Observable<ScoreResponseDto> {
    return this.http.post<ScoreResponseDto>(
      this.url + '/trial/verify-tenant',
      userData
    );
  }

  createCandidatureTrialFlow(
    candidature: TrialCandidatureRequestDto,
    clientId?: string
  ): Observable<CandidatureDto> {
    return this.http.post<CandidatureDto>(
      this.url + '/trial/' + candidature.asset.id + '/candidatures',
      {
        warrantyId: 'trialVersion',
        users: candidature.users,
        portfolioId: candidature.portfolioId,
        clientId
      }
    );
  }

  changeUserRole(changeUserRoleDto: ChangeUserRoleDto): Observable<unknown> {
    const url =
      this.url +
      '/trial/' +
      changeUserRoleDto.candidatureId +
      '/tenants/' +
      changeUserRoleDto.tenantId +
      '/change-roles';
    const obj: ChangeUserRoleDto = { ...changeUserRoleDto };
    return this.http.put(url, obj);
  }

  thirdPartyCandidatures(date?: string) {
    const params = {};
    if (date !== null) {
      params['selectedDate'] = date;
    }
    return this.http.get<Candidature[]>(this.url + '/candidatures/third-party');
  }

  archiveCandidatures(archived: boolean, candidaturesIds: string[]) {
    return this.http.patch(`${this.url}/candidatures/archive`, {
      archived,
      candidaturesIds
    });
  }

  deleteTenant(candidatureId, tenantEmail): Observable<unknown> {
    return this.http.delete(
      this.url +
        '/candidatures/' +
        `${candidatureId}` +
        '/tenant/' +
        `${tenantEmail}`
    );
  }

  getContractedPoliciesResume(
    portfolioId?: string,
    apiId?: string,
    date?: string,
    homeownerId?: string
  ): Observable<PolicyIssue[]> {
    const params = {};

    if (portfolioId) {
      params['portfolioId'] = portfolioId;
    }

    if (apiId) {
      params['apiId'] = apiId;
    }

    if (date !== null) {
      params['selectedDate'] = date;
    }

    if (homeownerId) {
      params['requestedHomeownerId'] = homeownerId;
    }

    return this.http.get<PolicyIssue[]>(
      `${this.url}/candidatures/contracted-policies-resume`,
      { params }
    );
  }

  getSinisterPolicies(
    portfolioId?: string,
    apiId?: string,
    homeownerId?: string
  ): Observable<Sinister[]> {
    const params = {};

    if (portfolioId) {
      params['portfolioId'] = portfolioId;
    }

    if (apiId) {
      params['apiId'] = apiId;
    }

    if (homeownerId) {
      params['requestedHomeownerId'] = homeownerId;
    }

    return this.http.get<Sinister[]>(`${this.url}/sinisters`, { params });
  }

  getContractedPolicyByPolicyNumber(policyNumber: string) {
    return this.http
      .get<PolicyIssue>(
        `${this.url}/candidatures/contracted-policies/${policyNumber}`
      )
      .pipe(map((resp) => [resp]));
  }

  getContractedPolicyByCandidature(
    candidatureId: string
  ): Observable<PolicyIssue> {
    return this.http.get<PolicyIssue>(
      `${this.url}/candidatures/contracted-policies/by-candidature/${candidatureId}`
    );
  }

  getContractedPoliciesByTenantId() {
    return this.http.get<PolicyIssue[]>(
      `${this.url}/candidatures/contracted-policies/by-tenant`
    );
  }

  updatePolicyPaymentStatus(
    policyNumber: string,
    changePolicyPaymentStatusDto: ChangePolicyPaymentStatusDto
  ): Observable<unknown> {
    return this.http.patch(
      `${this.url}/candidatures/contracted-policies/${policyNumber}/updatePaymentStatus`,
      changePolicyPaymentStatusDto
    );
  }

  updateDraftFlagCandidature(candidatureId: string): Observable<unknown> {
    return this.http.patch(
      `${this.url}/candidatures/${candidatureId}/update-tenant-policy-number`,
      {}
    );
  }

  cancelPolicy(policyNumber: string): Observable<unknown> {
    return this.http.patch(
      `${this.url}/candidatures/contracted-policies/${policyNumber}/cancel`,
      {}
    );
  }

  cancelPolicyRequest(
    policyNumber: string,
    file?: File,
    comments?: string
  ): Observable<void> {
    const formData = new FormData();
    if (!!file) {
      formData.append('file', file);
      formData.append('name', file.name);
    }
    if (!!comments) {
      formData.append('comments', comments);
    }
    return this.http.post<void>(
      `${this.url}/candidatures/contracted-policies/${policyNumber}/cancel-request`,
      formData
    );
  }

  createSinister(policyId: string, sinister: Sinister) {
    return this.http.post(
      `${this.url}/candidatures/contracted-policies/${policyId}/sinister`,
      sinister
    );
  }

  responseSinister(
    policyId: string,
    sinisterResp: { id: string; response: string }
  ) {
    return this.http.post(
      `${this.url}/candidatures/contracted-policies/${policyId}/response-sinister`,
      sinisterResp
    );
  }
  responseAndCloseSinister(
    policyId: string,
    sinisterResp: { id: string; response: string }
  ) {
    return this.http.post(
      `${this.url}/candidatures/contracted-policies/${policyId}/close-sinister`,
      sinisterResp
    );
  }

  closeSinister(sinister: Sinister): Observable<void> {
    return this.http.post<void>(
      `${this.url}/sinisters/${sinister.id}/close`,
      sinister
    );
  }

  addCommentToPolicySinister(
    policyId: string,
    sinisterId: string,
    message: string
  ) {
    return this.http.patch(
      `${this.url}/candidatures/contracted-policies/${policyId}/sinister/${sinisterId}`,
      {
        message
      }
    );
  }
  addCommentToSinister(sinisterId: string, data: SinisterThread) {
    return this.http.patch(`${this.url}/sinisters/${sinisterId}/comment`, data);
  }
  addCommentToCancellation(policyId: string, data: SinisterThread) {
    return this.http.patch(
      `${this.url}/sinisters/cancellations/${policyId}/comment`,
      data
    );
  }

  editCandidature(assetId: string, candidatureId: string, tenantList: User[]) {
    return this.http.patch(
      this.url + '/assets/' + assetId + '/candidatures/' + candidatureId,
      {
        users: tenantList
      }
    );
  }

  relocateCandidature(
    relocatedCandidature: MoveCandidatureRequest
  ): Observable<unknown> {
    return this.http.post<unknown>(
      this.url + '/assets/relocate-candidature',
      relocatedCandidature
    );
  }

  reinforceCandidature(
    assetId: string,
    candidatureId: string,
    tenantList: User[]
  ): Observable<CandidatureDto> {
    return this.http.post<CandidatureDto>(
      this.url + `/assets/${assetId}/candidatures/${candidatureId}/reinforce`,
      {
        users: tenantList
      }
    );
  }

  downloadTenantPolicy(policyNumber: string): Observable<Blob> {
    return this.http.get(
      this.url + '/candidatures/downloadTenantPolicy/' + policyNumber,
      {
        responseType: 'blob'
      }
    );
  }

  getBrokerCodeByUserId(userId?: string): Observable<BrokerDto> {
    let httpParams = new HttpParams();
    if (userId) {
      httpParams = httpParams.append('userId', userId);
    }
    return this.http.get<BrokerDto>(
      `${this.url}/candidatures/insurances/broker-code`,
      { params: httpParams }
    );
  }

  updateScore(candidatureId: string, score: number) {
    return this.http.patch<null>(
      `${this.url}/candidatures/${candidatureId}/updateScore`,
      {
        score
      }
    );
  }

  getContractedPoliciesByPolicyNumber(policyNumber: string) {
    return this.http
      .get<PolicyIssue>(
        `${this.url}/candidatures/contracted-policies/${policyNumber}`
      )
      .pipe(map((resp) => [resp]));
  }

  getSinisterExcel(
    portfolioId?: string,
    apiId?: string,
    homeownerId?: string
  ): Observable<Blob> {
    const params = {};

    if (portfolioId) {
      params['portfolioId'] = portfolioId;
    }

    if (apiId) {
      params['apiId'] = apiId;
    }

    if (homeownerId) {
      params['requestedHomeownerId'] = homeownerId;
    }

    return this.http.get(`${this.url}/sinisters/excel`, {
      responseType: 'blob',
      params
    });
  }

  getAssetsByTenantEmail(
    email: string,
    buildingId: string
  ): Observable<AssetDto[]> {
    const params = new HttpParams().append('buildingId', buildingId);

    const tenantEmail = email.replace('+', '%2B');
    const endpoint = `${this.url}/assets/tenants/${tenantEmail}`;
    return this.http.get<AssetDto[]>(endpoint, { params });
  }

  getAssetsByTenantDni(
    dni: string,
    buildingId: string
  ): Observable<AssetDto[]> {
    const params = new HttpParams().append('buildingId', buildingId);

    const endpoint = `${this.url}/assets/tenant-dni/${dni}`;
    return this.http.get<AssetDto[]>(endpoint, { params });
  }

  createCommercialNotes(
    candidatureId: string,
    body: ComercialNote
  ): Observable<null> {
    return this.http.patch<null>(
      `${this.url}/candidatures/${candidatureId}/comercial-notes`,
      body
    );
  }

  getSinisterDocument(
    policyNumber: string,
    docType: SinisterDocumentType
  ): Observable<DocumentDTO> {
    const endpoint = `${this.url}/sinisters/${policyNumber}/document/${docType}`;

    return this.http.get<DocumentDTO>(endpoint);
  }

  updateSinister(data: UpdateSinister): Observable<Sinister> {
    const endpoint = `${this.url}/sinisters/${data.id}`;
    return this.http.patch<Sinister>(endpoint, data);
  }

  addSinisterDocument(
    policyNumber: string,
    file: File,
    type: SinisterDocumentType
  ): Observable<void> {
    const endpoint = `${this.url}/sinisters/${policyNumber}/add-document`;

    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);
    formData.append('type', type.toString());

    return this.http.post<void>(endpoint, formData);
  }

  deleteSinisterDocument(
    policyNumber: string,
    type: SinisterDocumentType
  ): Observable<void> {
    const endpoint = `${this.url}/sinisters/${policyNumber}/document/${type}`;

    return this.http.delete<void>(endpoint);
  }

  getSinisterCancelation(
    portfolioId: string,
    apiId: string,
    requestedHomeownerId: string
  ): Observable<Sinister[]> {
    const endpoint = `${this.url}/sinisters/cancellations`;

    let params = new HttpParams();

    if (!!portfolioId) {
      params = params.append('portfolioId', portfolioId);
    }

    if (!!apiId) {
      params = params.append('apiId', apiId);
    }

    if (!!requestedHomeownerId) {
      params = params.append('requestedHomeownerId', requestedHomeownerId);
    }

    return this.http.get<Sinister[]>(endpoint, { params });
  }

  checkSinistersByDni(dni: string) {
    const endpoint = `${this.url}/sinisters/${dni}/by-tenant-dni`;
    return this.http.get<Sinister[]>(endpoint);
  }

  addCommentToPolicyIssue(
    id: string,
    data: SinisterThread
  ): Observable<PolicyIssue> {
    const endpoint = `${this.url}/candidatures/${id}/add-comment`;
    return this.http.post<PolicyIssue>(endpoint, data);
  }

  addCommentToCancelledPolicy(
    id: string,
    data: SinisterThread
  ): Observable<PolicyIssue> {
    const endpoint = `${this.url}/sinisters/cancellations/${id}/add-comment`;
    return this.http.post<PolicyIssue>(endpoint, data);
  }

  private getExtendedTenant(
    tenant: TenantCandidature,
    candidature: Candidature
  ) {
    return forkJoin({
      section: this.profileService
        .getSectionsByUserId(tenant.user.id)
        .pipe(catchError(() => of(null))),
      analysis: this.getRelevantAnalysisDataForTenant(
        tenant.user.id,
        candidature.id,
        candidature.latestUpdate
      ),
      documents: this.checkDocuments(tenant.user.id).pipe(
        catchError(() => of(null))
      ),
      requestDocumentation: this.usersService
        .getRequestDocumentation(tenant.user.id)
        .pipe(catchError(() => of(null))),
      user: of(
        tenant.user?.professionalData?.freelance
          ? {
              ...tenant.user,
              freelance: tenant.user?.professionalData?.freelance
            }
          : tenant.user
      ),
      professionalData: of({})
    }).pipe(
      map((responses) => {
        return {
          ...tenant,

          status: responses.analysis.status,
          incidences: responses.analysis.incidences,
          requestAnalysisDate: responses.analysis.requestAnalysisDate,
          requestAnalysisId: responses.analysis.requestAnalysisId,
          pendingToDownload: responses.analysis.pendingToDownload,

          percentage: responses.section?.percentageProfile,

          hasDni: responses.documents?.hasDni,
          hasPayslip: responses.documents?.hasPayslip,
          lastPayslipAmount: responses.documents?.lastPayslipAmount,
          dni: responses.documents?.dni,
          payslips: responses.documents?.payslips,
          rent: responses.documents?.rent,
          is200Model: responses.documents?.is200Model,
          ivaQuarterModel: responses.documents?.ivaQuarterModel,
          hasRent: responses.documents?.hasRent,
          otherDocument: responses.documents?.otherDocument,
          hasOtherDocument: responses.documents?.hasOtherDocument,
          pensionerRecognition: responses.documents?.pensionerRecognition,
          hasPensionerRecognition: responses.documents?.hasPensionerRecognition,
          pensionerTotalAmount: responses.documents?.pensionerTotalAmount,
          hasIs200Model: responses.documents?.hasIs200Model,
          hasIvaQuarterModel: responses.documents?.hasIvaQuarterModel,
          hasModel130: responses.documents?.hasModel130,
          model130: responses.documents?.model130,
          hasSSCertificate: responses.documents?.hasSSCertificate,
          ssCertificate: responses.documents?.ssCertificate,
          hasAEATCertificate: responses.documents?.hasAEATCertificate,
          aeatCertificate: responses.documents?.aeatCertificate,
          hasPYGQuarter: responses.documents?.hasPYGQuarter,
          pygQuarter: responses.documents?.pygQuarter,
          workLife: responses.documents?.workLife,
          hasWorkLife: responses.documents?.workLife,

          requestDocumentation: responses.requestDocumentation,

          user: responses.user
        };
      })
    );
  }

  private getRelevantAnalysisDataForTenant(
    tenantId: string,
    candidatureId: string,
    latestCandidatureUpdate: Date
  ) {
    return this.usersService
      .getRequestAnalysisByUser(tenantId, candidatureId)
      .pipe(
        map((analysis) => {
          const incidences = analysis.incidences || [];
          const pendingToDownload = analysis.pendingToDownload;
          if (
            this.checkAnalysisDate(
              latestCandidatureUpdate,
              analysis.creationDate
            )
          ) {
            return {
              status: UserAnalysisStatusEnum.WITHOUT_ANALYSIS,
              incidences,
              pendingToDownload,
              requestAnalysisDate: null,
              requestAnalysisId: null
            };
          } else if (
            this.checkAnalysisDate(latestCandidatureUpdate, analysis.endDate)
          ) {
            return {
              status: UserAnalysisStatusEnum.WITHOUT_ANALYSIS,
              incidences,
              pendingToDownload,
              requestAnalysisDate: null,
              requestAnalysisId: null
            };
          } else if (analysis && analysis.endDate) {
            return {
              status: UserAnalysisStatusEnum.REPORTED_ANALYSIS,
              requestAnalysisDate: analysis.endDate,
              incidences,
              pendingToDownload,
              requestAnalysisId: analysis.id
            };
          } else {
            return {
              status: UserAnalysisStatusEnum.IN_PROCESS_ANALYSIS,
              requestAnalysisDate: analysis.creationDate,
              incidences,
              pendingToDownload,
              requestAnalysisId: analysis.id
            };
          }
        }),
        catchError((_error) =>
          of({
            status: UserAnalysisStatusEnum.WITHOUT_ANALYSIS,
            incidences: [],
            pendingToDownload: null,
            requestAnalysisDate: null,
            requestAnalysisId: null
          })
        )
      );
  }

  private checkAnalysisDate(latestUpdate: Date, analysisDates: Date) {
    if (analysisDates) {
      return latestUpdate >= analysisDates;
    }
    return false;
  }

  /* getTenantCandidatures(): Observable<Candidature[]> {
     return this.getCandidatures().pipe(
       concatMap((candidatures) => {
         return forkJoin(
           (candidatures || []).map((candidature) => {
             return forkJoin(
               (candidature.tenantCandidatureList || []).map((tenant) =>
                 this.getExtendedTenantForTenantRequest(tenant, candidature)
               )
             ).pipe(
               defaultIfEmpty(null),
               map((extendedTenantCandidatureList) => ({
                 ...candidature,
                 tenantCandidatureList: extendedTenantCandidatureList || [],
               }))
             );
           })
         ).pipe(defaultIfEmpty([]));
       }),
       map((extendedCandidatures) => {
         return extendedCandidatures.filter(
           (e: Candidature) =>
             !(
               e.tenantCandidatureList.length === 0 &&
               e.candidatureStatusEnum === 'REJECT'
             )
         );
       })
     );
   }
   
 
   // TODO: probar con usuarios de kamino
   private getRelevantAnalysisDataForTenantRequest(
     tenantId: string,
     candidatureId: string
   ) {
     return this.usersService.getAnalysisByUser(tenantId, candidatureId).pipe(
       map((analysis) => {
         return {
           analysisId: analysis.id || null,
           incidences: analysis.incidences || [],
         };
       }),
       catchError((_error) =>
         of({
           analysisId: null,
           incidences: [],
         })
       )
     );
   }
   
 
   private getExtendedTenantForTenantRequest(
     tenant: TenantCandidature,
     candidature: Candidature
   ) {
     return forkJoin({
       analysis: this.getRelevantAnalysisDataForTenantRequest(
         tenant.user.id,
         candidature.id
       ),
       documents: this.checkDocuments(tenant.user.id).pipe(
         catchError(() => of(null))
       ),
       requestDocumentation: this.usersService
         .getRequestDocumentation(tenant.user.id)
         .pipe(catchError(() => of(null))),
       user: this.usersService
         .getUserObservable(tenant.user.id)
         .pipe(catchError(() => of(tenant.user))),
       professionalData: this.usersService
         .getProfessionalData(tenant.user.id)
         .pipe(catchError(() => of(null))),
     }).pipe(
       map((responses) => {
         return {
           ...tenant,
           analysisId: responses.analysis.analysisId,
           incidences: responses.analysis.incidences,
           hasDni: responses.documents?.hasDni,
           hasPayslip: responses.documents?.hasPayslip,
           lastPayslipAmount: responses.documents?.lastPayslipAmount,
           dni: responses.documents?.dni,
           payslips: responses.documents?.payslips,
           rent: responses.documents?.rent,
           is200Model: responses.documents?.is200Model,
           ivaQuarterModel: responses.documents?.ivaQuarterModel,
           hasRent: responses.documents?.hasRent,
           otherDocument: responses.documents?.otherDocument,
           hasOtherDocument: responses.documents?.hasOtherDocument,
           pensionerRecognition: responses.documents?.pensionerRecognition,
           hasPensionerRecognition: responses.documents?.hasPensionerRecognition,
           pensionerTotalAmount: responses.documents?.pensionerTotalAmount,
           hasIs200Model: responses.documents?.hasIs200Model,
           hasIvaQuarterModel: responses.documents?.hasIvaQuarterModel,
           workLife: responses.documents?.workLife,
           hasWorkLife: responses.documents?.hasWorkLife,
           model130: responses.documents?.model130,
           hasModel130: responses.documents?.hasModel130,
           ssCertificate: responses.documents?.ssCertificate,
           hasSSCertificate: responses.documents?.hasSSCertificate,
           aeatCertificate: responses.documents?.aeatCertificate,
           hasAEATCertificate: responses.documents?.hasAEATCertificate,
           pygQuarter: responses.documents?.pygQuarter,
           hasPYGQuarter: responses.documents?.hasPYGQuarter,
 
           requestDocumentation: responses.requestDocumentation,
 
           user: {
             ...responses.user,
             freelance: responses.professionalData?.freelance,
           },
         };
       })
     );
   }
 */
}
