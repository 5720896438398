/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/typedef */
import { Component, Input, OnInit } from '@angular/core';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { CalculatedPremiumAragDto, Candidature } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { ModalController, ToastController } from '@ionic/angular';
import { AddAragInsuranceComponent } from '@shared/components/add-arag-insurance/add-arag-insurance.component';

@Component({
  selector: 'el-buen-inquilino-request-insurance-policy-modal',
  templateUrl: './request-insurance-policy-modal.component.html'
})
export class RequestInsurancePolicyModalComponent implements OnInit {
  @Input() candidature: Candidature;
  @Input() closedOperation: boolean;

  calculatedInsurance: CalculatedPremiumAragDto;
  hideAragOfferButton = true;

  constructor(
    private modalController: ModalController,
    private candidatureService: CandidaturesApiService,
    private toastController: ToastController,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.callCalculatedPremiun();
  }

  callCalculatedPremiun(): void {
    if (this.calculateConditionsToOfferPolicy()) {
      this.loadingService.presentSecondLoader(null, true);

      this.candidatureService
        .getCalculatedPremiumArag(this.candidature.id)
        .subscribe((data) => {
          if (data?.totalAnnualReceipt) {
            this.hideAragOfferButton = false;
            this.calculatedInsurance = {
              totalAnnualReceipt: data.totalAnnualReceipt
            };
          }
          this.loadingService.dismissSecondLoader();
        });
    }
  }

  calculateConditionsToOfferPolicy(): boolean {
    return (
      !this.candidature.policyNumber &&
      this.candidature.score &&
      this.candidature.score >= 80 &&
      !this.candidature.blockPolicy
    );
  }

  updateColor(progress): string {
    return this.candidatureService.updateColor(progress);
  }

  async presentToast(msg: string, toastcolor: string): Promise<void> {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top',
      color: toastcolor
    });
    toast.present();
  }

  dismiss(saving?: boolean): void {
    this.modalController.dismiss(
      {
        dismissed: true,
        saving
      },
      '',
      'request-insurance-policy'
    );
  }

  async presentAragInsuranceFormModal(
    data?,
    totalAnnualReceipt?: number
  ): Promise<void> {
    const isAutoHeight =
      window.innerWidth <= 575 ? 'auto-height' : 'no-auto-height';
    const modal = await this.modalController.create({
      component: AddAragInsuranceComponent,
      cssClass: `${isAutoHeight} modal-extend`,
      componentProps: {
        candidature: data,
        totalAnnualReceipt
      },
      id: 'addAragInsurance'
    });
    modal.onDidDismiss().then((close) => {
      if (close?.data?.saving) this.dismiss();
    });
    return await modal.present();
  }
}
