/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  Candidature,
  CandidatureDetailedDto,
  CandidatureIdDto,
  Certification,
  CreateUserForCertificationsRegistry,
  ListCertificationsDto,
  TenantCandidature,
  UpdateConditionsUserDto,
  User,
  UserCandidatureDto,
  UserDocuments
} from '../../models';
import { CandidaturesApiService } from '../candidatures-api/candidatures-api.service';

@Injectable()
export class CertificationsApiService {
  candidatureForCertification: Candidature = {} as Candidature;
  tenantListForIncidences: User[] = [];
  url: string;
  ebiCloudFunctions: string;
  isScorePresent = false;
  finalizedCertifications: CandidatureDetailedDto[];
  pendingCertifications: CandidatureDetailedDto[];
  selectedCertificationOnBackOffice: CandidatureDetailedDto;
  candidatureForCertification$ = new BehaviorSubject<Candidature>(
    this.candidatureForCertification
  );
  certificationsList: ListCertificationsDto = {} as ListCertificationsDto;
  certificationsList$ = new BehaviorSubject<ListCertificationsDto>(
    this.certificationsList
  );

  filterDates$ = new BehaviorSubject<{
    from: string;
    to: string;
    search: boolean;
  }>({ from: null, to: null, search: false });

  constructor(
    private http: HttpClient,
    private candidatureService: CandidaturesApiService
  ) {
    this.url = environment.services.certifications;
    this.ebiCloudFunctions = environment.services.ebiCloudFunctions;
  }

  setFilterDates(from: string, to: string, search: boolean): void {
    this.filterDates$.next({ from, to, search });
  }

  getFilterDates(): Observable<{
    from: string;
    to: string;
    search: boolean;
  }> {
    return this.filterDates$.asObservable();
  }

  getRequestWarrantyForCertifications(
    requestId: string
  ): Observable<CandidatureIdDto> {
    return this.http.get<CandidatureIdDto>(
      this.url + '/ebi-certifications/certifications/' + requestId
    );
  }

  getCandidatureForCertifications(
    candidatureId: string
  ): Observable<Candidature> {
    return this.http
      .get<Candidature>(
        this.url + '/ebi-certifications/candidatures/' + candidatureId
      )
      .pipe(
        tap((response: Candidature) => {
          this.candidatureForCertification$.next(response);
        })
      );
  }

  createRequestAnalyisisForCertifications(
    candidatureId: string
  ): Observable<string> {
    return this.http.get<string>(
      this.url +
        '/ebi-certifications/candidatures/' +
        candidatureId +
        '/new-certification-request'
    );
  }

  createNewCertification(newCertification: Certification): Observable<any> {
    return this.http.post(
      this.url + '/ebi-certifications/certifications/',
      newCertification
    );
  }

  updateCertification(newCertification: Certification): Observable<any> {
    return this.http.put(
      this.url + '/ebi-certifications/candidatures/' + newCertification.id,
      newCertification
    );
  }

  relaunchCertification(newCertification: Certification): Observable<any> {
    return this.http.post(
      this.url +
        '/ebi-certifications/candidatures/' +
        newCertification.id +
        '/relaunch',
      newCertification
    );
  }

  getCertificationReport(candidatureId: string): Observable<any> {
    return this.http.get(
      this.url +
        '/ebi-certifications/candidatures/' +
        candidatureId +
        '/report',
      { responseType: 'blob' }
    );
  }

  fillCandidatureDocuments(candidature: Candidature): Candidature {
    candidature.tenantCandidatureList.map((tenant: TenantCandidature) => {
      this.candidatureService
        .checkDocuments(tenant.user.id)
        .subscribe((doc: UserDocuments) => {
          tenant.hasDni = doc.hasDni;
          tenant.hasPayslip = doc.hasPayslip;
          tenant.lastPayslipAmount = doc.lastPayslipAmount;
          tenant.dni = doc.dni;
          tenant.payslips = doc.payslips;
          tenant.rent = doc.rent;
          tenant.hasRent = doc.hasRent;
          tenant.otherDocument = doc.otherDocument;
          tenant.hasOtherDocument = doc.hasOtherDocument;
          tenant.pensionerRecognition = doc.pensionerRecognition;
          tenant.hasPensionerRecognition = doc.hasPensionerRecognition;
          tenant.hasMinimumDocsByRol = this.checkMinimumDocForTenantRol(
            tenant,
            doc
          );
        });
    });
    return candidature;
  }

  fillCertificationDocumentsFinaer(
    certificationBackOffice: CandidatureDetailedDto
  ): CandidatureDetailedDto {
    certificationBackOffice?.tenantCandidatureList?.map(
      (tenant: UserCandidatureDto) => {
        this.candidatureService
          .checkDocuments(tenant.user.id)
          .subscribe((doc: UserDocuments) => {
            tenant.hasDni = doc.hasDni;
            tenant.hasPayslip = doc.hasPayslip;
            tenant.lastPayslipAmount = doc.lastPayslipAmount;
            tenant.dni = doc.dni;
            tenant.payslips = doc.payslips;
            tenant.rent = doc.rent;
            tenant.hasRent = doc.hasRent;
            tenant.otherDocument = doc.otherDocument;
            tenant.hasOtherDocument = doc.hasOtherDocument;
            tenant.pensionerRecognition = doc.pensionerRecognition;
            tenant.hasPensionerRecognition = doc.hasPensionerRecognition;
            tenant.hasIs200Model = doc.hasIs200Model;
            tenant.hasIvaQuarterModel = doc.hasIvaQuarterModel;
            tenant.workLife = doc.workLife;
            tenant.hasMinimumDocsByRol =
              this.checkMinimumDocForTenantRolFinaerBackoffice(tenant, doc);
          });
      }
    );
    return certificationBackOffice;
  }

  updateDocumentsOnCandidatureCertificationOnService(): void {
    // TODO actualizar con los documents de la Tab hija para ahorrar llamadas
    this.candidatureForCertification = this.fillCandidatureDocuments(
      this.candidatureForCertification
    );
  }

  updateDocumentsOnBackofficeCertificationOnService(): void {
    // TODO actualizar con los documents de la Tab hija para ahorrar llamadas
    this.selectedCertificationOnBackOffice =
      this.fillCertificationDocumentsFinaer(
        this.selectedCertificationOnBackOffice
      );
  }

  checkMinimumDocForTenantRol(
    tenant: TenantCandidature,
    docs: UserDocuments
  ): boolean {
    let minimunDocs = false;
    if (tenant.user.student) {
      minimunDocs = docs.hasDni;
    } else if (tenant.user.freelance) {
      minimunDocs = docs.hasDni && docs.hasRent;
    } else if (tenant.user.retired) {
      minimunDocs = docs.hasDni && docs.hasPensionerRecognition;
    } else {
      minimunDocs = docs.hasDni && docs.hasPayslip;
    }

    return minimunDocs;
  }

  checkMinimumDocForTenantRolFinaerBackoffice(
    tenant: UserCandidatureDto,
    docs: UserDocuments
  ): boolean {
    let minimunDocs = false;
    if (tenant.user.student) {
      minimunDocs = docs.hasDni;
    } else if (
      tenant.user.freelance ||
      tenant.user.professionalData?.freelance
    ) {
      minimunDocs = docs.hasDni && docs.hasRent;
    } else if (tenant.user.retired) {
      minimunDocs = docs.hasDni && docs.hasPensionerRecognition;
    } else if (tenant.user.business) {
      minimunDocs =
        docs.hasDni && docs.hasIs200Model && docs.hasIvaQuarterModel;
    } else {
      minimunDocs = docs.hasDni && docs.hasPayslip;
    }

    return minimunDocs;
  }

  getCertificationsList(
    dateFrom?: string,
    dateTo?: string,
    warrantyId?: string
  ): Observable<ListCertificationsDto> {
    let from: string = moment(new Date()).startOf('month').format('DD-MM-YYYY');
    let to: string = moment(new Date()).endOf('month').format('DD-MM-YYYY');
    if (dateFrom != null && dateFrom != '' && dateTo != null && dateTo != '') {
      from = dateFrom;
      to = dateTo;
    }

    let url = `${this.ebiCloudFunctions}/getCertificationsList/${from}/${to}`;

    if (warrantyId) {
      url = `${this.ebiCloudFunctions}/getCertification/${warrantyId}`;
    }

    return this.http.get<ListCertificationsDto>(url).pipe(
      map((request: ListCertificationsDto) => {
        request.finalizedCertifications.map((f) => f?.archived || false);
        request.pendingCertifications.map((p) => p?.archived || false);
        return request;
      }),
      tap((response: ListCertificationsDto) => {
        this.certificationsList$.next(response);
      })
    );
  }

  payCertification(candidatureId: string, paid: boolean): Observable<any> {
    return this.http.put(
      this.url + '/ebi-certifications/candidatures/' + candidatureId + '/pay',
      { paid }
    );
  }

  finalizeCandidature(candidatureId: string): Observable<any> {
    return this.http.patch(
      this.url +
        `/backend-operations/candidatures/${candidatureId}/finalize-candidature`,
      {}
    );
  }

  approveByDecreeCandidature(candidatureId: string): Observable<any> {
    return this.http.patch(
      this.url +
        `/backend-operations/candidatures/${candidatureId}/approve-by-decree`,
      {}
    );
  }

  encryptPassword(pass: string): string {
    const key = CryptoJS.enc.Utf8.parse(environment.encryptKey);
    const encrypted = CryptoJS.AES.encrypt(pass, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  registerUserForCertifications(
    createUserForCertificationsRegistry: CreateUserForCertificationsRegistry
  ): Observable<any> {
    const obj = createUserForCertificationsRegistry;
    obj.password = this.encryptPassword(obj.password);
    delete obj.confirmPassword;
    return this.http.post(this.url + '/notifications/register-new-user', obj);
  }

  updateConditionsUser(
    updateConditionsUser: UpdateConditionsUserDto
  ): Observable<any> {
    const obj: UpdateConditionsUserDto = updateConditionsUser;
    return this.http.post(
      this.url + '/notifications/update-conditions-user',
      obj
    );
  }

  createRequestAnalyisisKamino(
    candidatureId: string,
    homeownerId: string
  ): Observable<any> {
    return this.http.put<string>(
      this.url +
        '/kamino/candidatures/' +
        candidatureId +
        '/new-request-analysis-from-cc',
      { homeownerId }
    );
  }
}
