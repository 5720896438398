import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  CotenantsArag,
  DocumentDTO,
  GeneralModalResponse,
  PolicyIssue,
  SinisterDocumentType,
  User
} from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController, ToastController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, first } from 'rxjs';

import { PolicyDetailForm } from '../../models/policy-detail-modal.model';
import {
  base64ToBlob,
  getSinisterBlobName,
  getUsers
} from '../../utils/sinister.utils';
import { presentToast } from '../../utils/toast.utils';
import { AddSinisterDocumentComponent } from '../add-sinister-document/add-sinister-document.component';
import { SinisterDocumentsComponent } from '../sinister-documents/sinister-documents.component';
import { ViewSinisterDocumentModalComponent } from '../view-sinister-document-modal/view-sinister-document-modal.component';

@Component({
  selector: 'el-buen-inquilino-policy-detail-modal',
  templateUrl: './policy-detail-modal.component.html'
})
export class PolicyDetailModalComponent implements OnInit {
  @Input() insurance: PolicyIssue;
  @Input() isIncofisa = false;
  @Input() isCancelPolicy = false;
  @Input() tab = 1;
  @Input() user: User;
  @ViewChild('sinisterDocuments')
  sinisterDocumentsComponent: SinisterDocumentsComponent;

  form: FormGroup<PolicyDetailForm>;
  documents: DocumentDTO[] = [];
  userPolicyHolder: User;
  showUploadRentContractBtn = false;
  showPolicyCancelationConversation = false;
  users: User[] = [];
  contractTerminationFile: DocumentDTO = null;

  get isNotTenantRC(): boolean {
    return this.insurance?.type !== 'TENANT_RC';
  }

  get cotenants(): CotenantsArag[] {
    return this.insurance?.insuranceAsset?.cotenants || [];
  }

  get showSinisterDocuments(): boolean {
    return (
      this.isNotTenantRC && !!this.userPolicyHolder && !this.isCancelPolicy
    );
  }

  get showCancelPolicyButton(): boolean {
    return (
      this.isIncofisa &&
      !this.insurance.cancellationDate &&
      !this.insurance.endDate
    );
  }

  constructor(
    private modalController: ModalController,
    private loadingService: LoadingService,
    private candidatureService: CandidaturesApiService,
    private userService: UsersApiService,
    private utilsService: UtilsService,
    private toastController: ToastController,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.getHomeownerUser();
    this.getUsers();
    this.getContractTermination();
  }

  getHomeownerUser(): void {
    if (!!this.user) {
      this.userPolicyHolder = this.user;
    } else {
      this.userService
        .getUserObservable(this.insurance.userId)
        .pipe(first())
        .subscribe((user: User) => (this.userPolicyHolder = user));
    }
  }

  dismiss(cancel: boolean = false): void {
    this.modalController.dismiss(cancel);
  }

  setShowUploadBtn(show: boolean): void {
    this.showUploadRentContractBtn = show;
  }

  saveDocument(): void {
    this.sinisterDocumentsComponent.saveDocument(this.insurance.policyNumber);
  }

  async cancelPolicy(): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    this.candidatureService
      .cancelPolicy(this.insurance.policyNumber)
      .pipe(first())
      .subscribe({
        next: () => this.onSuccessCancelPolicy(),
        error: async () => await this.loadingService.dismissSecondLoader()
      });
  }

  async download(): Promise<void> {
    await this.loadingService.presentSecondLoader(null);

    let call: Observable<unknown> = null;

    if (this.tab === 1) {
      call = this.candidatureService.getPolicyReport(
        this.user.id,
        this.insurance.policyNumber
      );
    } else {
      call = this.candidatureService.downloadTenantPolicy(
        this.insurance.policyNumber
      );
    }

    call.pipe(first()).subscribe({
      next: async (blob: File) => {
        await this.loadingService.dismissSecondLoader();
        const url = window.URL.createObjectURL(blob);
        const pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
          alert(
            // eslint-disable-next-line max-len
            'Desactiva el bloqueador de ventanas emergentes y vuelve a intentarlo.'
          );
        }
      },
      error: async () => {
        await this.loadingService.dismissSecondLoader();
      }
    });
  }

  async deleteContractTermination(): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    this.candidatureService
      .deleteSinisterDocument(
        this.insurance.policyNumber,
        SinisterDocumentType.CONTRACT_TERMINATION
      )
      .pipe(first())
      .subscribe({
        next: () => this.onSuccessDeleteDoc(),
        error: () => this.onErrorDeleteDoc()
      });
  }

  getContractTermination(): void {
    this.candidatureService
      .getSinisterDocument(
        this.insurance.policyNumber,
        SinisterDocumentType.CONTRACT_TERMINATION
      )
      .pipe(first())
      .subscribe({
        next: (resp: DocumentDTO) => this.onSuccessGetContractTermination(resp)
      });
  }

  viewDocument(): void {
    this.loadViewDocumentModal(
      this.contractTerminationFile,
      getSinisterBlobName(
        this.insurance.id,
        SinisterDocumentType.CONTRACT_TERMINATION
      )
    );
  }

  async loadViewDocumentModal(doc: DocumentDTO, name: string): Promise<void> {
    const modal = await this.modalController.create({
      component: ViewSinisterDocumentModalComponent,
      cssClass: 'custom-modal-xl',
      componentProps: {
        url: doc.url,
        extension: doc.extension,
        name
      } as Partial<ViewSinisterDocumentModalComponent>
    });

    return await modal.present();
  }

  downloadContractTermination(): void {
    const { inputStream, extension } = this.contractTerminationFile;
    this.utilsService.downloadPDF(
      getSinisterBlobName(
        this.insurance.id,
        SinisterDocumentType.CONTRACT_TERMINATION
      ),
      base64ToBlob(inputStream, extension)
    );
  }

  async saveContractTermination(): Promise<void> {
    const modal = await this.modalController.create({
      component: AddSinisterDocumentComponent,
      componentProps: {
        type: SinisterDocumentType.CONTRACT_TERMINATION,
        user: this.userPolicyHolder,
        policyNumber: this.insurance.policyNumber,
        disabledTypeDocument: true,
        showContractTermination: true
      } as Partial<AddSinisterDocumentComponent>,
      showBackdrop: true,
      backdropDismiss: false
    });

    modal
      .onDidDismiss()
      .then((resp: OverlayEventDetail<GeneralModalResponse>) => {
        if (resp.data?.saving) {
          this.getContractTermination();
        }
      });
    return await modal.present();
  }

  private onSuccessGetContractTermination(resp: DocumentDTO): void {
    this.contractTerminationFile = resp;
  }

  private setForm(): void {
    this.form = new FormGroup<PolicyDetailForm>({
      address: new FormControl<string>({
        value: this.insurance.insuranceAsset.address,
        disabled: true
      }),
      city: new FormControl<string>({
        value: this.insurance.insuranceAsset.city,
        disabled: true
      }),
      policyHolder: new FormControl<string>({
        value: this.insurance.emailPolicyHolder,
        disabled: true
      })
    });
  }

  private async getUsers(): Promise<void> {
    if (this.isCancelPolicy) {
      await this.loadingService.presentSecondLoader(null);
      this.users = await getUsers(
        this.userService,
        this.user,
        null,
        this.insurance
      );
      this.showPolicyCancelationConversation = true;
      await this.loadingService.dismissSecondLoader();
    }
  }

  private async onSuccessCancelPolicy(): Promise<void> {
    await this.loadingService.dismissSecondLoader();
    presentToast(
      this.toastController,
      'Póliza cancelada correctamente.',
      'success'
    );
    this.dismiss(true);
  }

  private async onSuccessDeleteDoc(): Promise<void> {
    await this.loadingService.dismissSecondLoader();
    presentToast(
      this.toastController,
      this.translate.instant('pages.documents.create-page.deletedOK') as string,
      'success'
    );
    this.contractTerminationFile = null;
  }

  private async onErrorDeleteDoc(): Promise<void> {
    await this.loadingService.dismissSecondLoader();
    presentToast(
      this.toastController,
      this.translate.instant('pages.documents.create-page.deletedKO') as string,
      'danger'
    );
  }
}
