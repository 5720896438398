import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DocumentsApiService } from '@app/modules/core/api-services/documenst-api/documents-api.service';
import { DocumentDTO, TenantCandidature, User } from '@app/modules/core/models';
import { LoadingService } from '@app/modules/core/services/loading/loading.service';
import { copyObject, tableSize } from '@app/modules/shared/utils/global.utils';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';

import { presentToast } from '../../utils/toast.utils';
import { MultipleDocumentModalComponent } from '../multiple-document-modal/multiple.document.modal.component';

@Component({
  selector: 'el-buen-inquilino-tenant-list',
  templateUrl: './tenant-list.component.html'
})
export class TenantListComponent implements OnInit {
  @Input() tenantList: TenantCandidature[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  tableSize = tableSize;
  dataSource: MatTableDataSource<TenantCandidature> =
    new MatTableDataSource<TenantCandidature>([]);

  displayedColumns = ['name', 'role', 'documentation'];
  displayedColumnsEnum = {
    NAME: 'name',
    ROLE: 'role',
    //SCORE: 'score',
    DOCUMENTATION: 'documentation'
  };

  constructor(
    private loadingService: LoadingService,
    private modalController: ModalController,
    private toastController: ToastController,
    private translate: TranslateService,
    private docService: DocumentsApiService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.addDocumentsFromTenants();
  }

  checkUserDocuments(documents: DocumentDTO[]): boolean {
    let hasUserDocuments = true;

    if (documents) {
      return documents.every((document: DocumentDTO) => {
        hasUserDocuments = this.checkDocument(document);
      });
    }

    return hasUserDocuments;
  }

  launchViewDocuments(userDoc: User): void {
    if (userDoc.documents) {
      this.viewDocumentsFull(userDoc.documents);
    } else {
      this.presentModalDocumentationNotExists();
    }
  }

  goToTenantProfile(tenant: TenantCandidature): void {
    this.router.navigateByUrl(
      `tenant/null/home?idContact=${tenant.user.id}&fromHomeowner=true`
    );
  }

  getInitialsNameByTenant(tenant: TenantCandidature): string {
    const fullName = `${tenant.user.firstname} ${tenant.user.surname1}`;
    const names = fullName.split(' ');

    return `${names[0][0]}${names[1][0]}`.toUpperCase();
  }

  getColorRolByTenant(tenant: TenantCandidature): string {
    return tenant.user.guarantor ? 'warning' : 'primary';
  }

  private setDataSource(): void {
    this.dataSource = new MatTableDataSource<TenantCandidature>(
      copyObject(this.tenantList) as TenantCandidature[]
    );
    this.dataSource.paginator = null;
    this.dataSource.paginator = this.paginator;
  }

  private addDocumentsFromTenants(): void {
    this.tenantList.map((user: TenantCandidature, index: number) => {
      this.tenantDocuments(user.user.id, index);
    });
  }

  private tenantDocuments(idUser: string, index: number): void {
    this.docService
      .getDocuments('20', idUser)
      .pipe(first())
      .subscribe((documents: DocumentDTO[]) => {
        this.tenantList[index].user.documents = this.verifyDocuments(documents);
        this.setDataSource();
      });
  }

  private verifyDocuments(documents: DocumentDTO[]): DocumentDTO[] {
    const validatedDocument = documents.filter(
      (document: DocumentDTO) => document
    );

    return validatedDocument;
  }

  private checkDocument(document: DocumentDTO): boolean {
    return !!document && !!document.id;
  }

  private presentModalDocumentationNotExists(): void {
    presentToast(
      this.toastController,
      this.translate.instant(
        'components.selected-candidature-modal.documentation_not_exist'
      ) as string,
      'danger'
    );
  }

  private async viewDocumentsFull(documents: DocumentDTO[]): Promise<void> {
    this.loadingService.presentSecondLoader(null, true);

    const modal = await this.modalController.create({
      component: MultipleDocumentModalComponent,
      cssClass: 'custom-modal-xl modal-extend',
      componentProps: {
        documentsData: documents,
        source: 'selectedCandidature'
      }
    });

    return await modal.present();
  }
}
