import { AssetDto, BuildingDto, LocationDto } from './asset.model';
import { CandidatureStatusEnum } from './candidature.model';

export class InsuranceReceiveOffers {
  receiveOffers?: boolean;
}

export class InsuranceDTO extends InsuranceReceiveOffers {
  id?: string;
  typeInsurance: InsuranceTypeEnum;
  dateTakeOut: Date;
  provider: string;
  cost: number;
  documentId?: string;
}

export enum InsuranceTypeEnum {
  HEALTH = 'HEALTH',
  HOME = 'HOME',
  CAR = 'CAR'
}

export class AragInsuranceDto {
  score: number;
  assetRent: number;
  document: string;
  firstName: string;
  surname: string;
  surname2: string;
  phone: string;
  address: string;
  street: string;
  number: string;
  province: string;
  postalCode: string;
  city: string;
  ccc: string;
  birthdatePolicyHolder: string;
  mobilePhonePolicyHolder: string;
  emailPolicyHolder: string;
  documentType: string;
  insuranceAsset: InsuranceAsset;
  inceptionDate: string;
  vandalismCoverage: string;
  insuranceRetention: string;
  cleaningCoverage: string;
  candidatureStatus: CandidatureStatusEnum;
  assetLocation?: LocationDto;
  assetId?: string;
  apiId?: string;
  portfolioOwnerId?: string;
  portfolioId?: string;
  commissionFee?: number;
  userId?: string;
  type?: string;
  insuredTenants: string[];
  calculatedPrice?: number;
  initialPrice?: number;
  originalPolicyNumber?: string;
  clientId?: string;
  buildingId?: string;
  idPoblacion?: number;
  offerId?: number;
  ref?: number;
}

export class InsuranceAsset {
  address: string;
  postalCode: string;
  city: string;
  province: string;
  document: string;
  name: string;
  surname: string;
  surname2: string;
  insuranceTypeDocument: string;
  cotenants: CotenantsArag[];
  street: string;
  number: string;
  portal: string;
  floor: string;
  door: string;
  homeownerId?: string;
}

export class CalculatedPremiumAragDto {
  totalAnnualReceipt: number;
  initialPrice?: number;
}

export class PricingFiatcDto {
  price: number;
  ref: number;
  poliza?: string;
  idPoblacion?: number;
  offerId?: number;
}

export class CotenantsArag {
  type: string;
  document: string;
  name: string;
  surname: string;
  surname2: string;
  phone: string;
  freelance: boolean;
  guarantor: boolean;
  student?: boolean;
  postalCode?: string;
  birthDate?: Date;
  street?: string;
  number?: number;
  city?: string;
}

export enum TypeDocumentAragEnum {
  DNI = '0',
  CIF = '1'
}

export enum VandalismCoverageEnum {
  SIN = '-1',
  SEISCIENTOS = '600',
  MILDOSCIENTOS = '1200'
}

export enum InsuranceRetentionEnum {
  SIN = '0',
  CON = '1'
}

export enum CleaningCoverageEnum {
  SI = '1',
  NO = '-1'
}

export interface PolicyIssue {
  id: string;
  creationDate: Date;
  score: number;
  assetRent: number;
  documentType: string;
  document: string;
  firstName: string;
  surname: string;
  surname2: string;
  phone: string;
  address: string;
  postalCode: string;
  city: string;
  ccc: string;
  birthdatePolicyHolder: string;
  mobilePhonePolicyHolder: string;
  emailPolicyHolder: string;
  inceptionDate: string;
  vandalismCoverage: string;
  insuranceRetention: string;
  cleaningCoverage: string;
  candidatureStatus: CandidatureStatusEnum;
  policyNumber: string;
  tenantPolicyNumber?: string;
  candidatureId: string;
  userId: string;
  agentName: string;
  insuranceCompanyCode: string;
  assetLocation: AssetLocation;
  insuranceAsset: InsuranceAsset;
  netPremium: number;
  assetId?: string;
  apiId?: string;
  buildingId?: string;
  portfolioOwnerId?: string;
  portfolioId?: string;
  sinisters?: Sinister[];
  month?: number;
  commissionFee?: number;
  paid?: boolean;
  paidDate?: Date;
  cancelled?: boolean;
  cancellationDate?: Date;
  cancellationProcess?: boolean;
  cancelledPolicyNumber?: string;
  type?: string;
  insuredTenants: string[];
  originalPolicyNumber?: string;
  asset?: AssetDto;
  building?: BuildingDto;
  blocked?: boolean;
  totalAnnualReceipt: number;
  thread: SinisterThread[];
  endDate?: Date;
  chat: SinisterThread[];
}

export class Sinister {
  id: string;
  creationDate: Date;
  startDate: Date;
  endDate?: Date;
  comments: string;
  tenantCommunication: boolean;
  response?: string;
  emailsToNotify: string[];
  thread?: SinisterThread[];
  isCancellation?: boolean;
  insuranceAsset?: InsuranceAsset;
  portfolioId?: string;
  apiId?: string;
  userId?: string;
  candidatureId?: string;
  emailPolicyHolder?: string;
  policyNumber?: string;
  totalAnnualReceipt?: number;
  assetRent?: number;
  insuranceCode?: string;
  totalDebt?: number;
  lastUpdatedTotalDebt?: Date | string;
  assignedLawyer?: string;
  lawyerPhone?: string;
  lawyerEmail?: string;
  status?: SinisterStatusEnum;
  phases?: SinisterPhase[];
  historicalDebts?: HistoricalDebts[];
  type?: string;
  chat?: SinisterThread[];
}

export interface UpdateSinister extends Sinister {
  inputDebt: number;
}

export enum SinisterStatusEnum {
  NOTIFIED_TO_EBI = 'NOTIFIED_TO_EBI',
  INSURANCE_COMPANY_INITIAL_STATUS = 'INSURANCE_COMPANY_INITIAL_STATUS',
  INSURANCE_COMPANY_BUROFAX_SENT = 'INSURANCE_COMPANY_BUROFAX_SENT',
  INSURANCE_COMPANY_COMPLAINT_FILLED = 'INSURANCE_COMPANY_COMPLAINT_FILLED',
  PENDING_TRIAL = 'PENDING_TRIAL',
  SCHEDULED_EVICTION = 'SCHEDULED_EVICTION'
}

export interface SinisterPhase {
  date: Date | string;
  status: SinisterStatusEnum;
}

export interface HistoricalDebts {
  date: Date | string;
  currentDebt: number;
}

export class UpdatePartialPolicyRequestDataDto {
  policeNumber: string;
}

export class SinisterThread {
  date: Date;
  message: string;
  userId: string;
  internalNote?: boolean;
}

export enum SinisterDocumentType {
  // Contrato de arrendamiento (Se usa desde el detalle de póliza o al abrir un siniestro nuevo)
  RENTAL_CONTRACT = 'RENTAL_CONTRACT',
  // Justificante de envío de burofax (Fase >= INSURANCE_COMPANY_BUROFAX_SENT)
  BUROFAX_SENT = 'BUROFAX_SENT',
  // Justificante de recepción de burofax (Fase >= INSURANCE_COMPANY_BUROFAX_SENT)
  BUROFAX_RECEPTION = 'BUROFAX_RECEPTION',
  // Presentación de la demanda (fase >= INSURANCE_COMPANY_BUROFAX_SENT)
  COMPLAINT_DOC = 'COMPLAINT_DOC',
  // Solicitud de servicios sociales (fase >= INSURANCE_COMPANY_BUROFAX_SENT)
  SOCIAL_SERVICES_REQUEST = 'SOCIAL_SERVICES_REQUEST',
  // Resolución del procedimiento (fase >= INSURANCE_COMPANY_BUROFAX_SENT)
  PROCEDURE_RESOLUTION = 'PROCEDURE_RESOLUTION',
  // Demanda de ejecución (fase >= INSURANCE_COMPANY_BUROFAX_SENT)
  EXECUTION_CLAIM = 'EXECUTION_CLAIM',
  // (fase >= INSURANCE_COMPANY_BUROFAX_SENT)
  OTHER_DOCUMENT = 'OTHER_DOCUMENT',
  // Resolución de contrato - Se usa desde los modales de petición de cancelación de póliza
  CONTRACT_TERMINATION = 'CONTRACT_TERMINATION'
}

export interface AssetLocation {
  latitude: number;
  longitude: number;
}

export interface AuxiliaryFilter {
  name: string;
  surname1: string;
  surname2?: string;
  documentNumber: string;
  timesAppears: number;
  agentName?: string;
  id?: string;
}

export interface MessageClasses {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'right-comment': boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'left-comment': boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'sinister-ebi-comment': boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'sinister-client-comment': boolean;
}
