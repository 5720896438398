import { MenuItem } from './menu.model';

export const MENU_HOME_OWNER = [
  new MenuItem(
    'pages.profile.home-owner.menu.home',
    'home-outline',
    '/home-owner/{userId}/home'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.assets',
    'key-outline',
    '/home-owner/{userId}/assets'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.closed',
    'shield-checkmark-outline',
    '/home-owner/{userId}/closed'
  ),
  // new MenuItem(
  //   'pages.profile.home-owner.insurances.title',
  //   'receipt-outline',
  //   '/home-owner/{userId}/insurances'
  // ),
  new MenuItem(
    'pages.profile.home-owner.menu.tenants',
    'people-outline',
    '/home-owner/{userId}/tenants'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.profile',
    'person-outline',
    '/home-owner/{userId}/profile'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.information',
    'information-circle-outline',
    '/home-owner/{userId}/information'
  )
];

export const MENU_HOME_OWNER_EXTERNAL_LAWYER = [
  new MenuItem(
    'pages.profile.home-owner.sinisters.title',
    'warning-outline',
    `/home-owner/{userId}/sinisters`
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.information',
    'information-circle-outline',
    '/home-owner/{userId}/information'
  )
];

export const MENU_HOME_OWNER_UNPAID = [
  new MenuItem(
    'pages.profile.home-owner.menu.home',
    'home-outline',
    '/home-owner/{userId}/home-subscription'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.profile',
    'person-outline',
    '/home-owner/{userId}/profile'
  )
];

export const MENU_HOME_OWNER_BIG_TENANT = [
  new MenuItem(
    'pages.profile.home-owner.menu.home',
    'home-outline',
    '/home-owner/{userId}/home'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.assets',
    'key-outline',
    '/home-owner/{userId}/assets'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.closed',
    'shield-checkmark-outline',
    '/home-owner/{userId}/closed'
  ),
  // new MenuItem(
  //   'pages.profile.home-owner.insurances.title',
  //   'receipt-outline',
  //   '/home-owner/{userId}/insurances'
  // ),
  new MenuItem(
    'pages.profile.home-owner.menu.tenants',
    'people-outline',
    '/home-owner/{userId}/tenants'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.profile',
    'person-outline',
    '/home-owner/{userId}/profile'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.information',
    'information-circle-outline',
    '/home-owner/{userId}/information'
  )
];

export const MENU_HOME_OWNER_BIG_TENANT_ASSOCIATED_USER = [
  new MenuItem(
    'pages.profile.home-owner.menu.home',
    'home-outline',
    '/home-owner/{userId}/home'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.assets',
    'key-outline',
    '/home-owner/{userId}/assets'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.closed',
    'shield-checkmark-outline',
    '/home-owner/{userId}/closed'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.third-party-asset',
    'key-outline',
    '/home-owner/{userId}/third-party-asset'
  ),
  // new MenuItem(
  //   'pages.profile.home-owner.insurances.title',
  //   'receipt-outline',
  //   '/home-owner/{userId}/insurances'
  // ),
  new MenuItem(
    'pages.profile.home-owner.menu.tenants',
    'people-outline',
    '/home-owner/{userId}/tenants'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.profile',
    'person-outline',
    '/home-owner/{userId}/profile'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.information',
    'information-circle-outline',
    '/home-owner/{userId}/information'
  )
];

export const MENU_TRIAL_VERSION = [
  new MenuItem(
    'pages.profile.home-owner.menu.home',
    'home-outline',
    '/home-owner/{userId}/home'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.assets',
    'key-outline',
    '/home-owner/{userId}/assets'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.closed',
    'shield-checkmark-outline',
    '/home-owner/{userId}/closed'
  )
  // new MenuItem(
  //   'pages.profile.home-owner.insurances.title',
  //   'receipt-outline',
  //   '/home-owner/{userId}/insurances'
  // ),
  // new MenuItem(
  //   'pages.profile.home-owner.menu.information',
  //   'information-circle-outline',
  //   '/home-owner/{userId}/information'
  // ),
];

export const MENU_HOME_OWNER_PORTFOLIO_BIG_TENANT_ASSOCIATED_USER = [
  new MenuItem(
    'pages.profile.home-owner.menu.home',
    'home-outline',
    '/home-owner/{userId}/home'
  ),
  new MenuItem(
    'pages.profile.home-owner.portfolio.title',
    'briefcase-outline',
    '/home-owner/{userId}/portfolio'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.assets',
    'key-outline',
    '/home-owner/{userId}/assets'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.closed',
    'shield-checkmark-outline',
    '/home-owner/{userId}/closed'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.third-party-asset',
    'key-outline',
    '/home-owner/{userId}/third-party-asset'
  ),
  // new MenuItem(
  //   'pages.profile.home-owner.insurances.title',
  //   'receipt-outline',
  //   '/home-owner/{userId}/insurances'
  // ),
  // new MenuItem(
  //   'pages.profile.home-owner.analytics.title',
  //   'stats-chart-outline',
  //   '/home-owner/{userId}/analytics'
  // ),
  new MenuItem(
    'pages.profile.home-owner.menu.profile',
    'person-outline',
    '/home-owner/{userId}/profile'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.information',
    'information-circle-outline',
    '/home-owner/{userId}/information'
  )
];

export const MENU_HOME_OWNER_PORTFOLIO = [
  new MenuItem(
    'pages.profile.home-owner.menu.home',
    'home-outline',
    '/home-owner/{userId}/home'
  ),
  new MenuItem(
    'pages.profile.home-owner.portfolio.title',
    'briefcase-outline',
    '/home-owner/{userId}/portfolio'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.assets',
    'key-outline',
    '/home-owner/{userId}/assets'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.closed',
    'shield-checkmark-outline',
    '/home-owner/{userId}/closed'
  ),
  // new MenuItem(
  //   'pages.profile.home-owner.insurances.title',
  //   'receipt-outline',
  //   '/home-owner/{userId}/insurances'
  // ),
  // new MenuItem(
  //   'pages.profile.home-owner.analytics.title',
  //   'stats-chart-outline',
  //   '/home-owner/{userId}/analytics'
  // ),
  new MenuItem(
    'pages.profile.home-owner.menu.profile',
    'person-outline',
    '/home-owner/{userId}/profile'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.information',
    'information-circle-outline',
    '/home-owner/{userId}/information'
  )
];

export const MENU_HOME_OWNER_API_ADMIN = [
  new MenuItem(
    'pages.profile.home-owner.menu.home',
    'home-outline',
    '/home-owner/{userId}/home'
  ),
  new MenuItem(
    'pages.profile.home-owner.portfolio.title',
    'briefcase-outline',
    '/home-owner/{userId}/portfolio'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.assets',
    'key-outline',
    '/home-owner/{userId}/assets'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.closed',
    'shield-checkmark-outline',
    '/home-owner/{userId}/closed'
  ),
  // new MenuItem(
  //   'pages.profile.home-owner.insurances.title',
  //   'receipt-outline',
  //   '/home-owner/{userId}/insurances'
  // ),
  // new MenuItem(
  //   'pages.profile.home-owner.analytics.title',
  //   'stats-chart-outline',
  //   '/home-owner/{userId}/analytics'
  // ),
  new MenuItem(
    'pages.profile.home-owner.menu.profile',
    'person-outline',
    '/home-owner/{userId}/profile'
  ),
  new MenuItem(
    'pages.profile.home-owner.menu.information',
    'information-circle-outline',
    '/home-owner/{userId}/information'
  )
];

export const MENU_TABS_HOME_OWNER_SELECTED_CANDIDATURE = [
  new MenuItem(
    'pages.home.home_owner.selected_candidature.tabs_menu.candidature',
    '',
    'candidature'
  ),
  new MenuItem(
    'pages.home.home_owner.selected_candidature.tabs_menu.insurances',
    '',
    'insurances'
  ),
  new MenuItem(
    'pages.home.home_owner.selected_candidature.tabs_menu.accidents',
    '',
    'accidents'
  ),
  new MenuItem(
    'pages.home.home_owner.selected_candidature.tabs_menu.incidences',
    '',
    'incidences'
  ),
  new MenuItem(
    'pages.home.home_owner.selected_candidature.tabs_menu.profitability',
    '',
    'profitability'
  ),
  new MenuItem(
    'pages.home.home_owner.selected_candidature.tabs_menu.transactions',
    '',
    'transactions'
  )
];
