/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { DocumentDTO, PageDto } from '../../models';

@Injectable()
export class DocumentsApiService {
  url: string;
  urlCloudFunctions: string;

  constructor(private http: HttpClient) {
    this.url = environment.services.documents;
    this.urlCloudFunctions = environment.services.ebiCloudFunctions;
  }

  getDocuments(
    numElements: string = '20',
    requestUserId?: string,
    afterElementId?: string
  ): Observable<DocumentDTO[]> {
    let httpParams = new HttpParams().append('numElements', numElements);
    if (afterElementId) {
      httpParams = httpParams.append('afterElementId', afterElementId);
    }
    if (requestUserId) {
      httpParams = httpParams.append('requestUserId', requestUserId);
    }
    return this.http
      .get<PageDto<DocumentDTO>>(this.url + '/documents', {
        params: httpParams
      })
      .pipe(map((res: PageDto<DocumentDTO>) => res.elements));
  }

  getDocumentsByDocType(
    numElements: string = '20',
    requestUserId?: string,
    typeDocument?: string,
    afterElementId?: string
  ): Observable<DocumentDTO[]> {
    let httpParams = new HttpParams().append('numElements', numElements);
    if (afterElementId) {
      httpParams = httpParams.append('afterElementId', afterElementId);
    }
    if (requestUserId) {
      httpParams = httpParams.append('requestUserId', requestUserId);
    }
    if (typeDocument) {
      httpParams = httpParams.append('typeDocument', typeDocument);
    }
    return this.http
      .get<PageDto<DocumentDTO>>(this.url + '/documents', {
        params: httpParams
      })
      .pipe(map((res: PageDto<DocumentDTO>) => res.elements));
  }

  createDocument(
    documentData: DocumentDTO,
    file: File,
    sourceId?: string,
    urlLogin?: string
  ): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);
    Object.keys(documentData).forEach((data: string) => {
      if (data !== 'file') {
        formData.append(`${data}`, documentData[data]);
      }
    });
    if (sourceId) {
      formData.append('sourceId', sourceId);
    }
    if (urlLogin) {
      formData.append('urlLogin', urlLogin);
    }
    return this.http.post<void>(this.url + '/documents', formData);
  }

  getFileByDocument(documentId: string): Observable<Blob> {
    return this.http.get(this.url + '/documents/' + `${documentId}/document`, {
      responseType: 'blob'
    });
  }

  getDocumentsFiles(documents: DocumentDTO[]): Observable<DocumentDTO[]> {
    documents.map((doc: DocumentDTO) => {
      this.getFileByDocument(doc.id).subscribe((blob: Blob) => {
        doc.blob = blob;
        return doc;
      });
    });
    return of(documents);
  }

  getFileByDocumentAndCotenant(
    documentId: string,
    cotenantId: string
  ): Observable<Blob> {
    return this.http.get(
      this.url +
        '/cotenants-documents/' +
        `${cotenantId}` +
        '/document/' +
        `${documentId}`,
      {
        responseType: 'blob'
      }
    );
  }

  updateDocument(
    documentId: string,
    documentData: DocumentDTO
  ): Observable<unknown> {
    return this.http.put(
      this.url + '/documents/' + `${documentId}`,
      documentData
    );
  }

  deleteDocument(documentId: string): Observable<unknown> {
    return this.http.delete(this.url + '/documents/' + `${documentId}`);
  }

  deleteDocumentCotenant(
    cotenantId: string,
    documentId: string
  ): Observable<unknown> {
    return this.http.delete(
      this.url +
        '/cotenants-documents/' +
        `${cotenantId}` +
        '/document/' +
        `${documentId}`
    );
  }

  getDocument(documentId: string): Observable<DocumentDTO> {
    return this.http.get<DocumentDTO>(
      this.url + '/documents/' + `${documentId}`
    );
  }

  verifyDocument(documentId: string, checked: boolean): Observable<unknown> {
    return this.http.patch(
      this.url + '/documents/' + `${documentId}` + `/verify`,
      { checked }
    );
  }

  modifyNetAmountPayslip(
    documentId: string,
    netAmount: number
  ): Observable<unknown> {
    return this.http.patch(
      this.url + '/documents/' + `${documentId}` + `/modifyPayslipAmount`,
      { netAmount }
    );
  }

  moveDocument(
    userId: string,
    type: string,
    name: string,
    targetId: string
  ): Observable<unknown> {
    return this.http.get(
      this.urlCloudFunctions +
        `/moveDocument/${userId}/${type}/${name}/${targetId}`
    );
  }

  getUrlLogo(urlLogin: string): Observable<unknown> {
    return this.http.get(`${this.url}/documents/${urlLogin}/logo`, {
      responseType: 'blob'
    });
  }
}
