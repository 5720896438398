/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { PhoneMaskService } from '@core/services/utils/phone-mask.service';
import { CountryCode } from 'libphonenumber-js/types.d';
import * as moment from 'moment';

import { AppValidatorsErrorMessages } from '../models/app-validators.model';

export const cifValidator = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;
  return /^([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])$/.test(
    formControl.value
  )
    ? null
    : { cifValidatorError: true };
};

export const dniValidator = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;
  return /^\d{8}[a-zA-Z]{1}$/.test(formControl.value)
    ? null
    : { dniValidatorError: true };
};
/**More restrictive DNI regex /([X-Z]{1})([-]?)(\d{7})([-]?)([A-Z]{1}))|((\d{8})([-]?)([A-Z]{1})/ */

export const dniAndNieValidator = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;
  return /^\d{8}[a-zA-Z]{1}$/.test(formControl.value) ||
    /^[xyzXYZ][0-9]{7}[a-zA-Z]$/.test(formControl.value)
    ? null
    : { dniAndNieValidatorError: true };
};

export const dniAndNieAndPassportValidator = (
  formControl: UntypedFormControl
) => {
  if (!formControl.value) return null;

  return /^\d{8}[a-zA-Z]{1}$/.test(formControl.value) ||
    /^[xyzXYZ][0-9]{7}[a-zA-Z]$/.test(formControl.value) ||
    /^([a-zA-Z]|[0-9]){8,16}$/.test(formControl.value)
    ? null
    : { dniAndNieAndPassportValidatorError: true };
};

export const passportValidator = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;

  return /^([a-zA-Z]|[0-9]){8,16}$/.test(formControl.value)
    ? null
    : { passportValidatorError: true };
};

export const namesValidator = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;
  return /^[a-zA-ZñÑáéíóúÁÉÍÓÚöÖÜüïÏàÀèÈìÌòÒùÙ -]*$/.test(formControl.value)
    ? null
    : { namesValidatorError: true };
};

export const complexNameValidator = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;
  return /^[a-zA-ZñÑáéíóúÁÉÍÓÚöÖÜüïÏàÀèÈìÌòÒùÙ0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]*$/.test(
    formControl.value
  )
    ? null
    : { complexNameError: true };
};

export const postalCodeValidator = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;
  return /(?:0[1-9]|[1-4]\d|5[0-2])\d{3}/.test(formControl.value)
    ? null
    : { postalCodeError: true };
};

export const phoneValidator = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;
  return /[\d\(\)\+]+$/.test(formControl.value)
    ? null
    : { phoneValidatorError: true };
};

export const spanishPhoneValidator = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;
  return /^(\+34)?([0-9]){9}$/.test(formControl.value)
    ? null
    : { phoneValidatorError: true };
};

export const internationalPhoneValidator = (
  formControl: UntypedFormControl
) => {
  if (!formControl.value) return null;
  return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(formControl.value)
    ? null
    : { phoneValidatorError: true };
};

export const emailValidator = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;
  return /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}$/.test(formControl.value)
    ? null
    : { emailValidatorError: true };
};

export const emailForAragValidator = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;
  return /^[a-z0-9._%\-]+@[a-z0-9.-]+\.[a-z]{2,8}$/.test(formControl.value)
    ? null
    : { emailValidatorError: true };
};

export const emailDetector = (userId: string) => {
  if (!userId) return true; //!userId user not exist then inactive component like if id is a email
  return /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}$/.test(userId) ? true : false;
};

export const bankAccountValidator = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;
  return /^[a-zA-Z]{2}[0-9]{2}\s?[a-zA-Z0-9]{4}\s?[0-9]{4}\s?[0-9]{3}([a-zA-Z0-9]\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,3})?$/.test(
    formControl.value
  )
    ? null
    : { bankAccountValidatorError: true };
};

export const maxLengthValidator =
  (maxLength: number) => (formControl: UntypedFormControl) => {
    if (!formControl.value) return null;
    return formControl.value.length <= maxLength
      ? null
      : { maxLengthValidatorError: true };
  };

export const minLengthValidator =
  (minLength: number) => (formControl: UntypedFormControl) => {
    if (!formControl.value) return null;
    return formControl.value.length >= minLength
      ? null
      : { minLengthValidatorError: true };
  };

export const addressValidator = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;
  return /^\s*\S+(?:\s+\S+){2}/.test(formControl.value)
    ? null
    : { addressValidatorError: true };
};

export const passwordMatchingValidator: ValidatorFn = (
  form: FormGroup
): ValidationErrors | null => {
  const password = form.get('password');
  const confirmPassword = form.get('confirmPassword');

  return password?.value === confirmPassword?.value
    ? null
    : { notmatched: true };
};

export const cadastralReferenceValidator = (
  formControl: UntypedFormControl
) => {
  if (!formControl.value) return null;
  return /^[a-zA-Z0-9]{10,20}$/.test(formControl.value)
    ? null
    : { cadastralReferenceValidatorError: true };
};

export const onlyNumericValidator = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;
  return /^[0-9]+$/.test(formControl.value)
    ? null
    : { onlyNumericValidatorError: true };
};

export const onlylettersAndNumericValidator = (
  formControl: UntypedFormControl
) => {
  if (!formControl.value) return null;
  return /^[a-zA-Z0-9]+$/.test(formControl.value)
    ? null
    : { onlylettersAndNumericValidatorError: true };
};

export const negativeNumericValidator = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;
  return /^-?[0-9]\d*(\.\d+)?$/.test(formControl.value)
    ? null
    : { onlyNumericValidatorError: true };
};

//^-?[0-9]\d*(\.\d+)?$

export const priceValidator = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;
  return /^\d+(,\d{1,2})?$/.test(formControl.value)
    ? null
    : { priceValidatorError: true };
};

/**
 * Se hace trim al valor del input
 * @param form Formulario
 * @param input Form control del fomulario
 */
export function deleteSpacesFormControl(form: UntypedFormGroup, input: string) {
  // Si es string, se hace el trim
  if (typeof form.get(input).value === 'string') {
    form.get(input).setValue(form.get(input).value.trim());
  }
}

/**
 * Valida si la fecha introducida es menor a hoy
 * @param formControl Input
 * @returns
 */
export const dateIsBeforeToday = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;

  return moment(formControl.value, 'DD/MM/YYYY').isBefore(new Date(), 'day')
    ? { dateIsBeforeTodayError: true }
    : null;
};

/**
 * Valida si la fecha introducida es menor a hoy (Formato fecha)
 * @param formControl Input
 * @returns
 */
export const dateIsBeforeToday2 = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;

  let momentDate = moment(new Date(formControl.value), 'DD-MM-YYYY', true);

  if (!momentDate.isValid()) {
    momentDate = moment(formControl.value, 'DD-MM-YYYY', true);
  }

  return momentDate.isBefore(new Date(), 'day')
    ? { dateIsBeforeTodayError: true }
    : null;
};

/**
 * Valida si la fecha introducida es menor a hoy
 * @param date
 * @returns
 */
export function dateIsBeforeTodayFunction(date: Date) {
  if (!date) return false;

  return moment(date).isBefore(new Date(), 'day');
}

/**
 * Valida si la fecha es de este año
 * @param date
 * @returns
 */
export function dateIsThisYearFunction(date: Date) {
  if (!date) return false;

  return moment(date).isSame(new Date(), 'year');
}

/**
 * Valida si la url es correcta
 * @param formControl
 * @returns
 */
export const urlValidator = (formControl: UntypedFormControl) => {
  if (!formControl.value) return null;

  return /[a-zA-Z0-9\-]+$/.test(formControl.value)
    ? null
    : { urlValidatorError: true };
};

export function phonenumberValidator(
  service: PhoneMaskService,
  country: CountryCode
): ValidatorFn {
  return (control: FormControl<string>): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    return service.isValidPhoneNumberWithIsoCode(value, country)
      ? null
      : { phone: true };
  };
}

// message pasa a ser el key de archivos de i18n

//TODO generic validation generic message of all forms
export const validationErrorMessages: AppValidatorsErrorMessages = {
  email: [
    {
      type: 'emailValidatorError',
      message: 'validation_errors.email.emailValidatorError'
    },
    { type: 'required', message: 'validation_errors.email.required' }
  ],
  firstname: [
    { type: 'required', message: 'validation_errors.firstname.required' },
    {
      type: 'namesValidatorError',
      message: 'validation_errors.firstname.namesValidatorError'
    },
    { type: 'minlength', message: 'validation_errors.firstname.minlength' },
    { type: 'maxlength', message: 'validation_errors.firstname.maxlength' }
  ],
  surname1: [
    { type: 'required', message: 'validation_errors.surname1.required' },
    {
      type: 'namesValidatorError',
      message: 'validation_errors.surname1.namesValidatorError'
    },
    { type: 'minlength', message: 'validation_errors.surname1.minlength' },
    {
      type: 'maxlength',
      message: 'validation_errors.surname1.maxlength'
    }
  ],
  surname2: [
    { type: 'required', message: 'validation_errors.surname2.required' },
    {
      type: 'namesValidatorError',
      message: 'validation_errors.surname2.namesValidatorError'
    },
    {
      type: 'maxlength',
      message: 'validation_errors.surname2.maxlength'
    }
  ],
  dni: [
    { type: 'required', message: 'validation_errors.dni.required' },
    {
      type: 'dniAndNieValidatorError',
      message: 'validation_errors.dni.dniAndNieValidatorError'
    },
    {
      type: 'dniAndNieAndPassportValidatorError',
      message: 'validation_errors.dni.dniAndNieAndPassportValidatorError'
    },
    { type: 'minlength', message: 'validation_errors.dni.minlength' },
    { type: 'maxlength', message: 'validation_errors.dni.maxlength' },
    {
      type: 'dniValidatorError',
      message: 'validation_errors.dni.dniValidatorError'
    }
  ],
  passport: [
    { type: 'required', message: 'validation_errors.passport.required' },
    {
      type: 'passportValidatorError',
      message: 'validation_errors.passport.passportValidatorError'
    }
  ],
  ocupation: [
    { type: 'required', message: 'Se debe seleccionar una ocupación.' }
  ],
  occupation: [
    { type: 'required', message: 'validation_errors.occupation.required' }
  ],
  phone: [
    {
      type: 'required',
      message: 'validation_errors.phone.required'
    },
    {
      type: 'phoneValidatorError',
      message: 'validation_errors.phone.phoneValidatorError'
    },
    { type: 'minlength', message: 'validation_errors.phone.minlength' },
    { type: 'maxlength', message: 'validation_errors.phone.maxlength' },
    { type: 'phone', message: 'validation_errors.phone.phoneValidatorError' }
  ],
  password: [
    { type: 'required', message: 'validation_errors.password.required' },
    { type: 'minlength', message: 'validation_errors.password.minlength' },
    {
      type: 'maxlength',
      message: 'validation_errors.password.maxlength'
    }
  ],
  confirmPassword: [
    {
      type: 'minlength',
      message: 'validation_errors.confirmPassword.minlength'
    },
    {
      type: 'maxlength',
      message: 'validation_errors.confirmPassword.maxlength'
    },
    {
      type: 'required',
      message: 'validation_errors.confirmPassword.required'
    }
  ],
  cif: [
    {
      type: 'cifValidatorError',
      message: 'validation_errors.cif.cifValidatorError'
    },
    { type: 'required', message: 'validation_errors.cif.required' }
  ],
  businessName: [
    { type: 'required', message: 'validation_errors.businessName.required' },
    {
      type: 'complexNameError',
      message: 'validation_errors.businessName.complexNameError'
    },
    { type: 'minlength', message: 'validation_errors.businessName.minlength' },
    {
      type: 'maxlength',
      message: 'validation_errors.businessName.maxlength'
    }
  ],
  taxResidence: [
    {
      type: 'required',
      message: 'validation_errors.taxResidence.required'
    },
    {
      type: 'addressValidatorError',
      message: 'validation_errors.taxResidence.addressValidatorError'
    },
    {
      type: 'minlength',
      message: 'validation_errors.taxResidence.minlength'
    },
    {
      type: 'maxlength',
      message: 'validation_errors.taxResidence.maxlength'
    }
  ],
  acceptTerms: [
    {
      type: 'required',
      message: 'Debe aceptar las condiciones para poder continuar.'
    },
    {
      type: 'requiredTrue',
      message: 'Debe aceptar las condiciones para poder continuar.'
    }
  ],
  cadastralReference: [
    { type: 'required', message: 'La referencia catastral es obligatoria.' },
    {
      type: 'cadastralReferenceValidatorError',
      message: 'La referencia catastral no es válida.'
    }
  ],
  rentalArea: [
    { type: 'required', message: 'El alquiler en la zona es obligatorio.' },
    {
      type: 'priceValidatorError',
      message: 'El alquiler en la zona es inválido.'
    },
    {
      type: 'onlyNumericValidatorError',
      message: 'El campo sólo puede contener caracteres numéricos.'
    }
  ],
  position: [{ type: 'required', message: 'El cargo es obligatorio.' }],
  income: [
    { type: 'required', message: 'Los ingresos netos son obligatorios.' },
    {
      type: 'onlyNumericValidatorError',
      message: 'El campo sólo puede contener caracteres numéricos.'
    },
    {
      type: 'priceValidatorError',
      message: 'El alquiler en la zona es inválido.'
    }
  ],
  sector: [
    { type: 'required', message: 'El sector de actividad es obligatorio.' }
  ],
  contractType: [
    { type: 'required', message: 'El tipo contrato es obligatorio.' }
  ],
  antiquity: [{ type: 'required', message: 'La antigüedad es obligatoria.' }],
  judicialIncidents: [
    {
      type: 'required',
      message: 'Las incidencias judiciales son obligatorias.'
    }
  ],
  traceability: [
    { type: 'required', message: 'La trazabilidad es obligatoria.' }
  ],
  nonPaymentsConfirmed: [
    { type: 'required', message: 'Los impagos confirmados son obligatorios.' }
  ],
  stayLastAddress: [
    {
      type: 'required',
      message: 'La permanencia en el último domicilio es obligatoria.'
    }
  ],
  businessActivitySector: [
    {
      type: 'required',
      message: 'El sector de actividad es obligatorio.'
    }
  ],
  previousYearExploit: [
    {
      type: 'required',
      message: 'El valor casilla 500 Modelo IS (200/202) es obligatorio.'
    },
    {
      type: 'onlyNumericValidatorError',
      message: 'El campo sólo puede contener caracteres numéricos.'
    }
  ],
  previousPeriodResults: [
    {
      type: 'required',
      message: 'El valor casilla 385 Modelo IS (200/202) es obligatorio.'
    },
    {
      type: 'onlyNumericValidatorError',
      message: 'El campo sólo puede contener caracteres numéricos.'
    }
  ],
  lastQuarterSales: [
    {
      type: 'required',
      message: 'El valor casilla 4 modelo 303 es obligatorio.'
    },
    {
      type: 'onlyNumericValidatorError',
      message: 'El campo sólo puede contener caracteres numéricos.'
    }
  ],
  previousYearSales: [
    {
      type: 'required',
      message: 'El valor casilla 255 Modelo IS (200/202) es obligatorio.'
    },
    {
      type: 'onlyNumericValidatorError',
      message: 'El campo sólo puede contener caracteres numéricos.'
    }
  ],
  businessAntiquity: [
    {
      type: 'required',
      message: 'La antigüedad es obligatoria.'
    }
  ],
  businessJudicialIncidents: [
    {
      type: 'required',
      message: 'Las incidencias judiciales son obligatorias.'
    }
  ],
  businessNoPaymentsConfirmed: [
    {
      type: 'required',
      message: 'Los impagos confirmados son obligatorios.'
    }
  ],
  typeDocument: [
    { type: 'required', message: 'validation_errors.typeDocument.required' }
  ],
  file: [{ type: 'required', message: 'validation_errors.file.required' }],
  expirationDate: [
    { type: 'required', message: 'validation_errors.expirationDate.required' },
    {
      type: 'dateIsBeforeTodayError',
      message: 'validation_errors.expirationDate.dateIsBeforeTodayError'
    }
  ],
  netAmount: [
    { type: 'required', message: 'validation_errors.netAmount.required' }
  ],
  date: [{ type: 'required', message: 'validation_errors.date.required' }],
  taxBase: [
    {
      type: 'required',
      message: 'validation_errors.taxBase.required'
    }
  ],
  dateYear: [
    { type: 'required', message: 'validation_errors.dateYear.required' }
  ],
  name: [{ type: 'required', message: 'validation_errors.name.required' }],
  totalAmount: [
    { type: 'required', message: 'validation_errors.totalAmount.required' }
  ],
  sourceId: [
    { type: 'required', message: 'validation_errors.sourceId.required' }
  ],
  addressInsured: [
    {
      type: 'required',
      message: 'validation_errors.addressInsured.required'
    },
    {
      type: 'maxLengthValidatorError',
      message: 'validation_errors.addressInsured.maxLengthValidatorError'
    }
  ],
  cityInsured: [
    {
      type: 'required',
      message: 'validation_errors.cityInsured.required'
    },
    {
      type: 'maxLengthValidatorError',
      message: 'validation_errors.cityInsured.maxLengthValidatorError'
    },
    {
      type: 'namesValidatorError',
      message: 'validation_errors.cityInsured.namesValidatorError'
    }
  ],
  postalCodeInsured: [
    {
      type: 'required',
      message: 'validation_errors.postalCodeInsured.required'
    },
    {
      type: 'postalCodeError',
      message: 'validation_errors.postalCodeInsured.postalCodeError'
    }
  ],
  assetRent: [
    {
      type: 'required',
      message: 'validation_errors.assetRent.required'
    },
    {
      type: 'max',
      message: 'validation_errors.assetRent.max'
    }
  ],
  document: [
    {
      type: 'required',
      message: 'validation_errors.document.required'
    },
    {
      type: 'dniValidatorError',
      message: 'validation_errors.document.dniValidatorError'
    },
    {
      type: 'cifValidatorError',
      message: 'validation_errors.document.cifValidatorError'
    }
  ],
  birthdatePolicyHolder: [
    {
      type: 'required',
      message: 'validation_errors.birthdatePolicyHolder.required'
    }
  ],
  birthPlace: [
    {
      type: 'required',
      message: 'validation_errors.birthPlace.required'
    }
  ],
  address: [
    {
      type: 'required',
      message: 'validation_errors.address.required'
    },
    {
      type: 'maxLengthValidatorError',
      message: 'validation_errors.address.maxLengthValidatorError'
    }
  ],
  street: [
    {
      type: 'required',
      message: 'validation_errors.street.required'
    },
    {
      type: 'maxLengthValidatorError',
      message: 'validation_errors.street.maxLengthValidatorError'
    }
  ],
  number: [
    {
      type: 'required',
      message: 'validation_errors.number.required'
    },
    {
      type: 'maxLengthValidatorError',
      message: 'validation_errors.number.maxLengthValidatorError'
    },
    {
      type: 'onlyNumericValidatorError',
      message: 'validation_errors.number.onlyNumericValidatorError'
    }
  ],
  province: [
    {
      type: 'required',
      message: 'validation_errors.province.required'
    },
    {
      type: 'maxLengthValidatorError',
      message: 'validation_errors.province.maxLengthValidatorError'
    }
  ],
  postalCode: [
    {
      type: 'required',
      message: 'validation_errors.postalCode.required'
    },
    {
      type: 'postalCodeError',
      message: 'validation_errors.postalCode.postalCodeError'
    }
  ],
  city: [
    {
      type: 'required',
      message: 'validation_errors.city.required'
    },
    {
      type: 'namesValidatorError',
      message: 'validation_errors.city.namesValidatorError'
    }
  ],
  mobilePhonePolicyHolder: [
    {
      type: 'required',
      message: 'validation_errors.mobilePhonePolicyHolder.required'
    },
    {
      type: 'phoneValidatorError',
      message: 'validation_errors.mobilePhonePolicyHolder.phoneValidatorError'
    }
  ],
  emailPolicyHolder: [
    {
      type: 'required',
      message: 'validation_errors.emailPolicyHolder.required'
    },
    {
      type: 'emailValidatorError',
      message: 'validation_errors.emailPolicyHolder.emailValidatorError'
    }
  ],
  ccc: [
    { type: 'required', message: 'validation_errors.ccc.required' },
    {
      type: 'bankAccountValidatorError',
      message: 'validation_errors.ccc.bankAccountValidatorError'
    }
  ],
  documentInsured: [
    { type: 'required', message: 'validation_errors.documentInsured.required' },
    {
      type: 'dniValidatorError',
      message: 'validation_errors.documentInsured.dniValidatorError'
    }
  ],
  businessNameArag: [
    {
      type: 'required',
      message: 'validation_errors.businessNameArag.required'
    },
    {
      type: 'complexNameError',
      message: 'validation_errors.businessNameArag.complexNameError'
    }
  ],
  reason: [
    {
      type: 'required',
      message: 'validation_errors.reason.required'
    }
  ],
  language: [
    {
      type: 'required',
      message: 'validation_errors.language.required'
    }
  ],
  maxReportsByAssetMonth: [
    {
      type: 'required',
      message: 'validation_errors.maxReportsByAssetMonth.required'
    },
    {
      type: 'onlyNumericValidatorError',
      message:
        'validation_errors.maxReportsByAssetMonth.onlyNumericValidatorError'
    }
  ],
  maxCandidaturesByAssetMonth: [
    {
      type: 'required',
      message: 'validation_errors.maxCandidaturesByAssetMonth.required'
    },
    {
      type: 'onlyNumericValidatorError',
      message:
        'validation_errors.maxCandidaturesByAssetMonth.onlyNumericValidatorError'
    }
  ],
  maxReportsMonth: [
    {
      type: 'required',
      message: 'validation_errors.maxReportsMonth.required'
    },
    {
      type: 'onlyNumericValidatorError',
      message: 'validation_errors.maxReportsMonth.onlyNumericValidatorError'
    }
  ],
  maxCandidaturesMonth: [
    {
      type: 'required',
      message: 'validation_errors.maxCandidaturesMonth.required'
    },
    {
      type: 'onlyNumericValidatorError',
      message:
        'validation_errors.maxCandidaturesMonth.onlyNumericValidatorError'
    }
  ],
  surface: [
    {
      type: 'onlyNumericValidatorError',
      message:
        'validation_errors.maxCandidaturesMonth.onlyNumericValidatorError'
    }
  ],
  adnexalSurface: [
    {
      type: 'onlyNumericValidatorError',
      message:
        'validation_errors.maxCandidaturesMonth.onlyNumericValidatorError'
    }
  ],
  constructionDate: [
    {
      type: 'onlyNumericValidatorError',
      message:
        'validation_errors.maxCandidaturesMonth.onlyNumericValidatorError'
    }
  ],
  reformYear: [
    {
      type: 'onlyNumericValidatorError',
      message:
        'validation_errors.maxCandidaturesMonth.onlyNumericValidatorError'
    }
  ],
  portal: [
    {
      type: 'onlylettersAndNumericValidatorError',
      message: 'validation_errors.portal.onlylettersAndNumericValidatorError'
    }
  ],
  floor: [
    {
      type: 'onlylettersAndNumericValidatorError',
      message: 'validation_errors.floor.onlylettersAndNumericValidatorError'
    }
  ],
  door: [
    {
      type: 'onlylettersAndNumericValidatorError',
      message: 'validation_errors.door.onlylettersAndNumericValidatorError'
    }
  ]
};
//TODO crear Model para typeScript para los errores -> validationErrorMessages, y cada error : type message :
