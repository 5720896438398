/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/typedef */
import { Component, OnInit, Input } from '@angular/core';
import { DocumentsApiService } from '@core/api-services/documenst-api/documents-api.service';
import { DocumentDTO } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'el-buen-inquilino-document-modal',
  templateUrl: './document.modal.html'
})
export class DocumentModalComponent implements OnInit {
  @Input() pdfSrc: Blob;
  @Input() documentData: DocumentDTO;
  @Input() downloadable = false;
  @Input() extension: string;
  @Input() name: string;
  isDisabledDownload = false;
  fileBlobUrl: string;
  isPdf = true;

  get modalTitle(): string {
    if (!!this.documentData) {
      return this.documentData.name;
    } else {
      return this.name;
    }
  }

  constructor(
    private modalController: ModalController,
    private loadingService: LoadingService,
    private toastController: ToastController,
    private docService: DocumentsApiService,
    private utils: UtilsService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.previewFile(this.pdfSrc, this.documentData);
  }

  previewFile(file, document): void {
    if (
      (!!document && document.extension !== 'pdf') ||
      (!!this.extension && this.extension !== 'application/pdf')
    ) {
      this.isPdf = false;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener(
        'load',
        () => {
          this.fileBlobUrl = reader.result as string;
        },
        false
      );
    } else {
      const url = window.URL.createObjectURL(file);
      this.fileBlobUrl = url;
    }
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  onClickVerifiedDoc(document: DocumentDTO): void {
    if (document) {
      this.isDisabledDownload = true;
      this.loadingService.presentSecondLoader(
        this.translateService.instant('components.document_modal.downloading'),
        true
      );

      this.downloadVerifiedDocument(document);
    } else {
      this.presentToast(
        this.translateService.instant('components.document_modal.not_exist'),
        'danger'
      );
    }
  }

  async presentToast(msg: string, toastcolor: string): Promise<void> {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top',
      color: toastcolor
    });
    toast.present();
  }

  downloadVerifiedDocument(documentDownload: DocumentDTO): void {
    this.docService.getFileByDocument(documentDownload.id).subscribe(
      (res) => {
        this.loadingService.dismissSecondLoader();

        this.utils.downloadPDF(
          documentDownload.name + '.' + documentDownload.extension,
          res
        );
      },
      () => {
        this.loadingService.dismissSecondLoader();
        this.isDisabledDownload = false;
        this.presentToast(
          this.translateService.instant(
            'components.document_modal.download_fail'
          ),
          'danger'
        );
      }
    );
  }
}
