/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CandidaturesApiService } from '@app/modules/core/api-services/candidatures-api/candidatures-api.service';
import { prepareFile } from '@app/modules/core/services/utils/utils.service';
import { Candidature, TypeDocumentToCandidatureEnum } from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Component({
  selector: 'el-buen-inquilino-add-document-to-candidature',
  templateUrl: './add-document-to-candidature.component.html'
})
export class AddDocumentToCandidatureComponent implements OnInit {
  @Input() candidature: Candidature;
  @Input() typeDocumentEnum = TypeDocumentToCandidatureEnum;

  form: FormGroup;

  #file: File;

  constructor(
    private modalController: ModalController,
    private candidaturesService: CandidaturesApiService,
    private toastController: ToastController,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  dismiss(forceReload: boolean = false): void {
    this.modalController.dismiss({
      dismissed: forceReload
    });
  }

  uploadDocument(): void {
    const params = {
      type: this.form.get('type').value,
      internalReference: this.form.get('internalReference').value,
      description: this.form.get('description').value,
      file: this.#file
    };

    this.candidaturesService
      .createDocumentOnCandidature(params, this.candidature.id)
      .pipe(
        catchError((err: any) => {
          if (err.status === 409) {
            const messageKey = 'pages.documents.create-page.duplicatedDoc';
            this.presentToast(messageKey, 'danger');
          }

          return throwError(err);
        }),
        finalize(() => this.loadingService.dismissSecondLoader())
      )
      .subscribe(
        () => {
          const messageKey = 'pages.documents.create-page.savedOK';

          this.presentToast(messageKey, 'success');
          this.dismiss(true);
        },
        (error: any) => {
          if (error.status === 400) {
            this.presentToast(
              'pages.documents.create-page.sizeDocumentError',
              'danger'
            );
          } else {
            this.presentToast(
              'pages.documents.create-page.error_upload_document',
              'danger'
            );
          }
        }
      );
  }

  changeListenerFile(event: any): void {
    this.#file = prepareFile((event.target as HTMLInputElement).files[0]);
  }

  private async presentToast(messageKey: string, color: string): Promise<void> {
    const toast = await this.toastController.create({
      message: this.translate.instant(messageKey),
      position: 'top',
      color,
      duration: 4000
    });

    toast.present();
  }

  private initForm(): void {
    this.form = this.fb.group({
      type: [null, [Validators.required]],
      internalReference: [null],
      description: [null, [Validators.maxLength(200)]],
      file: ['', [Validators.required]]
    });
  }
}
