/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Observable } from 'rxjs';

import {
  AssetCreateCandidatureTenantDto,
  AssetDto,
  CreateAssetDto
} from './asset.model';
import { DocumentDTO } from './document.model';
import {
  Conditioning,
  Incidence,
  OccupationEnum,
  ProfDataDto,
  TenantCertification,
  User,
  UserDto
} from './users.model';

export class ShareProfileDto {
  asset: CreateAssetDto;
  email: string;
}

export class CreateCandidatureDto {
  user?: UserCreateCandidatureDto[];
  asset?: AssetCreateCandidatureTenantDto;
}

export class UserRequestCreateCandidatureDto {
  user?: UserCreateCandidatureDto;
}

export class UserRequestCreateMultipleCandidatureDto {
  users?: UserCreateCandidatureDto[];
}

export interface UserCreateCandidatureDto {
  id?: string;
  birthDate: Date;
  dni: string;
  email: string;
  firstname: string;
  phone: string;
  surname1: string;
  surname2: string;
  postalCode: string;
  score?: number;
  guarantor?: boolean;
  student?: boolean;
  freelance?: boolean;
  professionalData?: ProfDataDto;
  retired?: boolean;
  business?: boolean;
  businessName?: string;
  cif?: string;
  taxResidence?: string;
  coTenants?: string[];
  unremovable?: boolean;
}

export class Candidature {
  id?: string;
  asset?: AssetDto;
  asset$?: Observable<AssetDto>;
  candidatureStatusEnum: CandidatureStatusEnum;
  expirationDate: Date;
  homeowner: HomeOwner;
  tenantCandidatureList: TenantCandidature[];
  score?: number;
  draftScore?: number;
  originalScore?: number;
  policyNumber?: string;
  tenantPolicyNumber?: string;
  tenantPolicyContractedDate?: Date;
  latestUpdate?: Date;
  blockPolicy?: boolean;
  warrantyId?: string;
  closingForced?: boolean;
  certificationPaid?: boolean;
  paidDate?: Date;
  certifier?: string;
  selectDate?: Date;
  rejectDate?: Date;
  creationDate?: Date;
  thirdParty: boolean;
  archived?: boolean;
  isCandidatureSelectAndExpired?: boolean;
  comercialNotes?: ComercialNote[];
}

export interface HomeOwner {
  user: UserCreateCandidatureDto;
  userCandidatureStatusDtoEnum: any;
  insuranceCompanyCode?: string;
}
export class TenantCandidature {
  analysisId?: string;
  user?: User;
  percentage?: number;
  status?: UserAnalysisStatusEnum;
  userCandidatureStatusDtoEnum?: any;
  hasDni? = false;
  hasPayslip? = false;
  hasRent? = false;
  hasPensionerRecognition? = false;
  hasOtherDocument? = false;
  hasIs200Model? = false;
  hasIvaQuarterModel? = false;
  hasWorkLife? = false;
  hasModel130? = false;
  hasSSCertificate? = false;
  hasAEATCertificate? = false;
  hasPYGQuarter? = false;
  hasMinimumDocsByRol?: boolean = false;
  requestAnalysisId?: string;
  requestAnalysisDate?: Date;
  incidences?: Incidence[];
  requestDocumentation?: RequestDocumentation;
  lastPayslipAmount?: string;
  pensionerTotalAmount?: string;
  dni?: DocumentDTO[];
  payslips?: DocumentDTO[];
  rent?: DocumentDTO[];
  otherDocument?: DocumentDTO[];
  workLife?: DocumentDTO[];
  pensionerRecognition?: DocumentDTO[];
  is200Model?: DocumentDTO[];
  ivaQuarterModel?: DocumentDTO[];
  model130?: DocumentDTO[];
  ssCertificate?: DocumentDTO[];
  aeatCertificate?: DocumentDTO[];
  pygQuarter?: DocumentDTO[];
  Conditioning?: Conditioning;
  pendingToDownload?: boolean;
  duplicated?: boolean;
  pygCertificate?: DocumentDTO[];
}

export class UserDocuments {
  hasDni: boolean;
  hasPayslip: boolean;
  hasOneMonthPayslips: boolean;
  hasTwoMonthPayslips: boolean;
  hasRent: boolean;
  hasMinimumDocsByRol: boolean;
  hasOtherDocument: boolean;
  hasPensionerRecognition: boolean;
  hasIs200Model: boolean;
  hasIvaQuarterModel: boolean;
  hasOneQuarterModel: boolean;
  hasTwoQuarterModel: boolean;
  hasWorkLife: boolean;
  hasModel130: boolean;
  hasSSCertificate: boolean;
  hasAEATCertificate: boolean;
  hasPYGQuarter: boolean;
  lastPayslipAmount?: string;
  dni: DocumentDTO[];
  payslips: DocumentDTO[];
  rent: DocumentDTO[];
  otherDocument: DocumentDTO[];
  pensionerRecognition: DocumentDTO[];
  pensionerTotalAmount?: string;
  is200Model: DocumentDTO[];
  ivaQuarterModel: DocumentDTO[];
  workLife: DocumentDTO[];
  model130: DocumentDTO[];
  ssCertificate: DocumentDTO[];
  aeatCertificate: DocumentDTO[];
  pygQuarter: DocumentDTO[];
}

export interface CandidatureDto {
  id: string;
  asset: AssetDto;
  asset$?: Observable<AssetDto>;
  tenantCandidatureList: any[];
  homeowner: any;
  candidatureStatusEnum: string;
  expirationDate: Date;
  itm: any;
}

export interface CandidatureIdDto {
  candidatureId: string;
}

export interface RequestDocumentation {
  id: string;
  tenantId: string;
  creationDate: Date;
}

export enum CandidatureStatusEnum {
  WITHOUT_CHECKING = 'WITHOUT_CHECKING',
  PENDING = 'PENDING',
  PRE_SELECT = 'PRE_SELECT',
  REJECT = 'REJECT',
  SELECT = 'SELECT',
  EXPIRED = 'EXPIRED'
}

export enum CandidatureStatusModalEnum {
  PENDING = 'pending',
  DOCUMENTS = 'documents',
  REVISION = 'revision',
  REVISION_EBI = 'revision-ebi',
  SELECTED = 'selected'
}

export enum UserAnalysisStatusEnum {
  WITHOUT_ANALYSIS = 0,
  IN_PROCESS_ANALYSIS = 1,
  REPORTED_ANALYSIS = 2
}

export interface ScoreResponseDto {
  date: Date;
  value: number;
  error: string;
}

export interface ValidateUserDto {
  dni: string;
  firstname: string;
  surname1: string;
  surname2: string;
  score?: ScoreResponseDto;
  guarantor?: boolean;
  occupation?: OccupationEnum;
  email?: string;
  student?: boolean;
  retired?: boolean;
  freelance?: boolean;
  business?: boolean;
  businessName?: string;
  cif?: string;
  taxResidence?: string;
  acceptTerms?: boolean;
  noValidated?: boolean;
}

export class TrialCandidatureRequestDto {
  asset: AssetDto;
  warrantyId: string;
  portfolioId?: string;
  users: ValidateUserDto[];
}

export interface ChangeUserRoleDto {
  candidatureId: string;
  tenantId: string;
  newRol: UserRole;
  businessName?: string;
  cif?: string;
  taxResidence?: string;
}

export enum UserRole {
  WORKER = 'WORKER',
  STUDENT = 'STUDENT',
  FREELANCE = 'FREELANCE',
  RETIRED = 'RETIRED',
  BUSINESS = 'BUSINESS'
}

export enum UserGuarantorRole {
  WORKER = 'WORKER',
  FREELANCE = 'FREELANCE',
  RETIRED = 'RETIRED'
}

export interface TenantInfoCandidature {
  tenant: User;
  assetId: string;
}

export interface ReSendEmailDto {
  asset: AssetDto;
  userTenant: User;
}

export class ChangeTenantEmailObj {
  email: string;
  newTenantEmail: string;
  candidatureId: string;
}

export class TenantCertificationDTO {
  user: TenantCertification;
}

export class Certification {
  id?: string;
  asset?: CreateAssetDto;
  users: TenantCertification[];
  warrantyId: string;
  certifier: string;
  apiUser: UserDto;
  tenantCandidatureList?: TenantCertificationDTO[];
  originalWarrantyId?: string;
}

export interface MoveCandidatureRequest {
  originCandidatureId: string;
  tenantList: TenantCandidature[];
  destinationAssetId: string;
}

export interface BrokerDto {
  insuranceCode: string;
  canContractTenantInsurances: boolean;
  insuranceBrokerPaid: boolean;
  tenantInsuranceCode?: string;
}

export interface UpdateUserStatusResponseDto {
  convertToGuarantor: boolean;
}

export interface ComercialNote {
  date: Date;
  message: string;
  userId: string;
}
