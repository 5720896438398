import { Pipe, PipeTransform } from '@angular/core';
import { AssetDto } from '@core/models';
import { TranslateService } from '@ngx-translate/core';

import {
  MultiplePriceAssign,
  MultiplePriceAssignFunction
} from '../models/multiple-price-assign.model';

@Pipe({
  name: 'multiplePriceAssign'
})
export class MultiplePriceAssignPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(
    items: AssetDto[],
    { pipeFunction, isBuildingPage }: MultiplePriceAssign
  ): boolean | string {
    switch (pipeFunction) {
      case MultiplePriceAssignFunction.SHOW_BUTTON:
        return isBuildingPage;
      case MultiplePriceAssignFunction.DISABLE_BUTTON:
        return this.getDisabledButton(items);
      case MultiplePriceAssignFunction.TOOLTIP:
        return this.getTooltip(items);
    }
  }

  private getDisabledButton(items: AssetDto[]): boolean {
    if (!items || items?.length < 2) {
      return true;
    } else {
      return false;
    }
  }

  private getTooltip(items: AssetDto[]): string {
    if (this.getDisabledButton(items)) {
      return this.translate.instant(
        'components.asset_filters.multiple_disable_tooltip'
      ) as string;
    } else {
      return null;
    }
  }
}
