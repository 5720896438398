export enum DisplayedColumnsEnum {
  ID = 'id',
  START_DATE = 'startDate',
  ADDRESS = 'address',
  EMAIL_POLICY_HOLDER = 'emailPolicyHolder',
  TENANTS = 'tenants',
  DEBT = 'debt',
  DEBT_DATE = 'debtDate',
  INSURANCE_CODE = 'insuranceCode',
  STATUS = 'status',
  ACTIONS = 'actions'
}

export enum SinistersChildRoutesEnum {
  ACTIVE = 'active',
  FINALIZED = 'finalized'
}
