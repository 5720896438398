import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  ActivatedRoute,
  Event,
  NavigationStart,
  Params,
  Router
} from '@angular/router';
import { AssetsApiService } from '@core/api-services/assets-api/assets-api.service';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { DocumentsApiService } from '@core/api-services/documenst-api/documents-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  AnalysisCatalogue,
  AssetDto,
  BrokerDto,
  CalculatedPremiumAragDto,
  Candidature,
  CandidatureStatusEnum,
  CreateAnalysisDto,
  CustomModalButtonRole,
  DocumentDTO,
  InsuranceTenantRequestDto,
  TenantCandidature,
  User
} from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { IncofisaReportPdfService } from '@core/services/incofisa-report-pdf/incofisa-report-pdf.service';
import { LoadingService } from '@core/services/loading/loading.service';
import {
  AlertController,
  ModalController,
  ToastController
} from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { first, forkJoin, lastValueFrom, Subject, takeUntil } from 'rxjs';

import { AddAragInsuranceComponent } from '../../components/add-arag-insurance/add-arag-insurance.component';
import { AddTenantInsuranceComponent } from '../../components/add-tenant-insurance/add-tenant-insurance.component';
import { CancelPolicyRequestModalComponent } from '../../components/cancel-policy-request-modal/cancel-policy-request-modal.component';
import { MultipleDocumentModalComponent } from '../../components/multiple-document-modal/multiple.document.modal.component';
import { PreviewReportComponent } from '../../components/preview-report/preview-report.component';
import { ButtonsRole } from '../../models/button.model';
import { CancelPolicyRequestModalResp } from '../../models/cancel-policy-request-modal.model';
import { SelectedCandidatureHistoryState } from '../../models/selected-candidature.model';
import { presentToast } from '../../utils/toast.utils';

@Component({
  selector: 'el-buen-inquilino-selected-candidature-page',
  templateUrl: './selected-candidature-page.component.html'
})
export class SelectedCandidaturePageComponent implements OnInit, OnDestroy {
  asset: AssetDto;
  candidature: Candidature;
  isBackoffice = false;
  originalPolicyNumber: string;
  user: User;
  canContractTenantInsurances = false;
  insuranceCompanyCode = 'ARAG';
  client: User;
  totalAnnualReceipt: number;
  initialPrice: number;
  insuranceBrokerPaid = false;
  textTenantInsuranceButton: string;

  @ViewChild('scoringBusiness', { static: true })
  canvasElement: ElementRef<HTMLCanvasElement>;

  private $destroy = new Subject<void>();

  get assetTitle(): string {
    if (!this.asset) {
      return '';
    }
    return `${this.asset.street} ${this.asset.number}`;
  }

  get assetAddress(): string {
    if (!this.asset) {
      return '';
    }
    let address = '';

    if (!!this.asset.portal) {
      address = `${address} - Portal ${this.asset.portal}`;
    }

    if (!!this.asset.floor) {
      address = `${address} - Planta ${this.asset.floor}`;
    }

    if (!!this.asset.door) {
      address = `${address} - Puerta ${this.asset.door}`;
    }

    return address;
  }

  get street(): string {
    if (!this.candidature) {
      return '';
    }
    return this.candidature.asset.street;
  }

  get number(): string {
    if (!this.candidature) {
      return '';
    }
    return this.candidature.asset.number;
  }

  get portal(): string {
    if (!this.candidature) {
      return '';
    }
    return this.candidature.asset?.portal;
  }

  get floor(): string {
    if (!this.candidature) {
      return '';
    }
    return this.candidature.asset?.floor;
  }

  get door(): string {
    if (!this.candidature) {
      return '';
    }
    return this.candidature.asset?.door;
  }

  get rentalPrice(): number {
    if (!this.candidature) {
      return 0;
    }
    return this.candidature.asset?.rentalPrice;
  }

  get tenantList(): TenantCandidature[] {
    if (!this.candidature) {
      return [];
    }
    return this.candidature.tenantCandidatureList;
  }

  get isDisabledTenantPolicyReport(): boolean {
    return this.candidature.tenantPolicyNumber === 'DRAFT';
  }

  get score(): number {
    if (!this.candidature) {
      return null;
    }
    return this.candidature?.score;
  }

  get showOffersWrapper(): boolean {
    return (
      (this.canContractTenantInsurances || this.score >= 80) &&
      !this.user?.blockCreatePolicy &&
      !this.isBackoffice
    );
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private candidatureService: CandidaturesApiService,
    private assetService: AssetsApiService,
    private authService: AuthService,
    private modalController: ModalController,
    private loadingService: LoadingService,
    private toastController: ToastController,
    private usersService: UsersApiService,
    private incofisaReportPdfService: IncofisaReportPdfService,
    private translate: TranslateService,
    private docService: DocumentsApiService,
    private alertController: AlertController
  ) {}

  ngOnInit(): void {
    this.setAsset();
    this.setCandidature();
    this.setClient();
    this.subscribeToRouterNavigation();
    this.setUser();
    this.getInsuranceCompanyCode();
  }

  subscribeToRouterNavigation(): void {
    this.router.events
      .pipe(takeUntil(this.$destroy))
      .subscribe((val: Event) => {
        if (val instanceof NavigationStart) {
          delete (history.state as SelectedCandidatureHistoryState).candidature;
          delete (history.state as SelectedCandidatureHistoryState).asset;
          history.pushState(history.state, '');
        }
      });
  }

  return(): void {
    this.router.navigate(['../../..'], {
      relativeTo: this.route
    });
  }

  revertSelectedCandidature(): void {
    if (this.candidature.policyNumber) {
      this.presentReOpenAssetAlert();
    } else {
      this.reopenCandidature();
    }
  }

  async presentReOpenAssetAlert(): Promise<void> {
    const modal = await this.modalController.create({
      component: CancelPolicyRequestModalComponent,
      showBackdrop: true,
      backdropDismiss: false,
      componentProps: {
        reopen: true
      },
      cssClass: 'reopen-cancel-policy-modal'
    });

    modal
      .onWillDismiss()
      .then((resp: OverlayEventDetail<CancelPolicyRequestModalResp>) => {
        if (resp.role === (CustomModalButtonRole.ACCEPT as string)) {
          this.callCancelPolicy(resp.data);
        }
      });
    await modal.present();
  }

  async previewReport(): Promise<void> {
    const candidature = this.candidature;
    await this.loadingService.presentSecondLoader(null, true);

    // Preparamos la llamada para obtener los informes
    const calls: unknown[] = [];

    candidature.tenantCandidatureList.forEach((tc: TenantCandidature) => {
      calls.push(
        this.usersService.getCompletedAnalysis(candidature.id, tc.user.id)
      );
    });

    // Añadimos la llamada para obtener el catálogo del análisis
    calls.push(this.usersService.getCatalogue());

    forkJoin(calls)
      .pipe(first())
      .subscribe({
        next: async (resp: unknown[]) => {
          await this.loadingService.dismissSecondLoader();
          // Recuperamos la información del catálogo y la eliminamos del array
          const catalogue: AnalysisCatalogue = resp.pop() as AnalysisCatalogue;

          const modal = await this.modalController.create({
            component: PreviewReportComponent,
            cssClass: 'preview-report',
            componentProps: {
              reports: resp,
              candidature,
              catalogue
            }
          });
          await modal.present();

          const dismissEvent = await modal.onWillDismiss();
          if (dismissEvent.role === 'download') {
            this.incofisaReportPdfService.getReport(
              candidature,
              resp as CreateAnalysisDto[],
              catalogue,
              this.canvasElement
            );
          }
        },
        error: async () => await this.loadingService.dismissSecondLoader()
      });
  }

  checkUserDocuments(documents: DocumentDTO[]): boolean {
    let hasUserDocuments = true;
    if (documents) {
      return documents.every((document: DocumentDTO) => {
        hasUserDocuments = this.checkDocument(document);
      });
    }
    return hasUserDocuments;
  }

  launchViewDocuments(userDoc: User): void {
    if (userDoc.documents) {
      this.viewDocumentsFull(userDoc.documents);
    } else {
      presentToast(
        this.toastController,
        this.translate.instant(
          'components.selected-candidature-modal.documentation_not_exist'
        ) as string,
        'danger'
      );
    }
  }

  async presentAragInsuranceFormModal(
    data?: Candidature,
    totalAnnualReceipt?: number
  ): Promise<void> {
    const isAutoHeight =
      window.innerWidth <= 575 ? 'auto-height' : 'no-auto-height';
    const modal = await this.modalController.create({
      component: AddAragInsuranceComponent,
      cssClass: `${isAutoHeight} custom-over-modal modal-extend`,
      componentProps: {
        candidature: data,
        totalAnnualReceipt,
        initialPrice: this.initialPrice,
        insuranceCompanyCode: this.insuranceCompanyCode,
        originalPolicyNumber: this.originalPolicyNumber,
        client: this.client,
        insuranceBrokerPaid: this.insuranceBrokerPaid
      },
      id: 'addAragInsurance'
    });
    modal
      .onDidDismiss()
      .then((close: OverlayEventDetail<{ saving?: boolean }>) => {
        if (close?.data?.saving) {
          this.return();
        }
      });
    return await modal.present();
  }

  downloadPolicyReport(candidature: Candidature): void {
    if (candidature.policyNumber) {
      this.loadingService.presentSecondLoader(null);
      this.candidatureService
        .getPolicyReport(
          candidature.homeowner?.user?.id,
          candidature.policyNumber
        )
        .pipe(first())
        .subscribe({
          next: (blob: Blob) => {
            this.loadingService.dismissSecondLoader();
            const url = window.URL.createObjectURL(blob);
            const pwa = window.open(url);
            if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
              alert(
                this.translate.instant(
                  'pages.profile.home-owner.assets.disable_popup_blocker'
                )
              );
            }
          },
          error: () => {
            this.loadingService.dismissSecondLoader();
            presentToast(
              this.toastController,
              this.translate.instant(
                'components.selected-candidature-modal.can_not_download'
              ) as string,
              'danger'
            );
          }
        });
    }
  }

  async presentTenantInsuranceFormModal(
    data?: Candidature,
    totalAnnualReceipt?: number
  ): Promise<void> {
    const isAutoHeight =
      window.innerWidth <= 575 ? 'auto-height' : 'no-auto-height';
    const modal = await this.modalController.create({
      component: AddTenantInsuranceComponent,
      cssClass: `${isAutoHeight} modal-extend modal-backdrop`,
      componentProps: {
        candidature: data,
        totalAnnualReceipt
      },
      showBackdrop: true
    });
    modal
      .onDidDismiss()
      .then((close: OverlayEventDetail<{ saving?: boolean }>) => {
        if (close?.data?.saving) {
          this.candidature.tenantPolicyNumber = 'DRAFT';
          this.textTenantInsuranceButton = 'Pendiente de firma';
          presentToast(
            this.toastController,
            // eslint-disable-next-line max-len
            'Se ha enviado una solicitud de contratación de póliza a los miembros de la candidatura',
            'success'
          );
        }
      });
    return await modal.present();
  }

  downloadTenantPolicyReport(candidature: Candidature): void {
    if (candidature.tenantPolicyNumber !== 'DRAFT') {
      this.loadingService.presentSecondLoader(null);
      this.candidatureService
        .downloadTenantPolicy(candidature.tenantPolicyNumber)
        .pipe(first())
        .subscribe({
          next: (blob: Blob) => {
            this.loadingService.dismissSecondLoader();
            const url = window.URL.createObjectURL(blob);
            const pwa = window.open(url);
            if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
              alert(
                this.translate.instant(
                  'pages.profile.home-owner.assets.disable_popup_blocker'
                )
              );
            }
          },
          error: () => {
            this.loadingService.dismissSecondLoader();
            presentToast(
              this.toastController,
              this.translate.instant(
                'components.selected-candidature-modal.can_not_download'
              ) as string,
              'danger'
            );
          }
        });
    }
  }

  resendTenantPolicyRequest(): void {
    const notification = new InsuranceTenantRequestDto();
    // eslint-disable-next-line max-len
    notification.address = `${this.candidature.asset.street} ${this.candidature.asset.number}, ${this.candidature.asset.town} (${this.candidature.asset.province})`;
    this.candidature.tenantCandidatureList?.forEach(
      (userCandidature: TenantCandidature) => {
        notification.email = userCandidature.user.email;
        notification.firstname = userCandidature.user.firstname;
        this.usersService.resendTenantPolicyRequest(notification).subscribe();
      }
    );
    presentToast(
      this.toastController,
      'Invitaciones reenviadas con éxito',
      'success'
    );
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  private async setAsset(): Promise<void> {
    if (!!(history.state as SelectedCandidatureHistoryState).asset) {
      this.asset = (history.state as SelectedCandidatureHistoryState).asset;
    } else {
      const params: Params = await lastValueFrom(
        this.route.queryParams.pipe(first())
      );
      this.assetService
        .getAsset(params['id'] as string)
        .pipe(first())
        .subscribe((asset: AssetDto) => (this.asset = asset));
    }
  }

  private async setCandidature(): Promise<void> {
    if (!!(history.state as SelectedCandidatureHistoryState).candidature) {
      this.candidature = (
        history.state as SelectedCandidatureHistoryState
      ).candidature;
      this.addDocumentsToTenant();
      this.checkPolicyNumber();
    } else {
      const params: Params = await lastValueFrom(
        this.route.queryParams.pipe(first())
      );
      this.candidatureService
        .getCandidature(params['candidatureId'] as string)
        .pipe(first())
        .subscribe((candidature: Candidature) => {
          this.candidature = candidature;
          this.addDocumentsToTenant();
          this.checkPolicyNumber();
        });
    }
  }

  private checkPolicyNumber(): void {
    if (!this.candidature?.policyNumber) {
      this.callCalculatedPremiun();
    }
  }

  private callCalculatedPremiun(): void {
    if (this.calculateConditionsToOfferPolicy()) {
      this.candidatureService
        .getCalculatedPremiumArag(this.candidature.id, this.client?.id)
        .pipe(first())
        .subscribe((resp: CalculatedPremiumAragDto) => {
          if (resp && resp?.totalAnnualReceipt) {
            this.totalAnnualReceipt = resp.totalAnnualReceipt;
            if (resp?.initialPrice) {
              this.initialPrice = resp.initialPrice;

              if (resp.initialPrice < resp.totalAnnualReceipt) {
                this.totalAnnualReceipt = resp.initialPrice;
              }
            }
          }
        });
    }
  }

  private calculateConditionsToOfferPolicy(): boolean {
    return (
      !this.candidature.policyNumber &&
      this.candidature.score &&
      this.candidature.score >= 80 &&
      !this.candidature.blockPolicy
    );
  }

  private addDocumentsToTenant(): void {
    this.candidature.tenantCandidatureList.map(
      (user: TenantCandidature, index: number) => {
        this.tenantDocuments(user.user.id, index);
      }
    );
  }

  private tenantDocuments(idUser: string, index: number): void {
    this.docService
      .getDocuments('20', idUser)
      .pipe(first())
      .subscribe((documents: DocumentDTO[]) => {
        this.candidature.tenantCandidatureList[index].user.documents =
          this.verifyDocuments(documents);
      });
  }

  private verifyDocuments(documents: DocumentDTO[]): DocumentDTO[] {
    const validatedDocument = documents.filter(
      (document: DocumentDTO) => document
    );
    return validatedDocument;
  }

  private setUser(): void {
    this.user = this.authService.user;
  }

  private async callCancelPolicy(
    data: CancelPolicyRequestModalResp
  ): Promise<void> {
    await this.loadingService.presentLoading(null);
    this.candidatureService
      .cancelPolicyRequest(
        this.candidature.policyNumber,
        data.file,
        data.comments
      )
      .pipe(first())
      .subscribe({
        next: () => this.onSuccessCancelPolicy(),
        error: async () => await this.loadingService.dismissLoading()
      });
  }

  private async onSuccessCancelPolicy(): Promise<void> {
    await this.loadingService.dismissLoading();
    presentToast(
      this.toastController,
      'Vamos a tramitar tu solicitud de cancelación de póliza',
      'success',
      5000
    );
    this.updateCandidatureStatus();
  }

  private async reopenCandidature(): Promise<void> {
    const alert = await this.alertController.create({
      // eslint-disable-next-line max-len
      message: `¿Estás seguro de que quieres reabrir el inmueble de ${this.assetTitle} ${this.assetAddress}?`,
      buttons: [
        {
          text: 'Cancelar',
          role: ButtonsRole.CANCEL
        },
        {
          text: 'Reabrir',
          role: ButtonsRole.ACCEPT
        }
      ]
    });
    await alert.present();
    const { role } = await alert.onWillDismiss();

    if (role === (ButtonsRole.ACCEPT as string)) {
      this.updateCandidatureStatus();
    }
  }

  private updateCandidatureStatus(): void {
    this.candidatureService
      .updateCandidatureStatus(
        this.candidature,
        CandidatureStatusEnum.PENDING,
        false
      )
      .subscribe({
        next: () => {
          presentToast(
            this.toastController,
            'Éxito en la reapertura del activo',
            'success'
          );
          this.return();
        },
        error: () =>
          presentToast(
            this.toastController,
            'Error al deseleccionar la candidatura',
            'danger'
          )
      });
  }

  private checkDocument(document: DocumentDTO): boolean {
    return !!document && !!document.id;
  }

  private async viewDocumentsFull(documents: DocumentDTO[]): Promise<void> {
    this.loadingService.presentSecondLoader(null, true);
    const modal = await this.modalController.create({
      component: MultipleDocumentModalComponent,
      cssClass: 'custom-modal-xl modal-extend',
      componentProps: {
        documentsData: documents,
        source: 'selectedCandidature'
      }
    });
    return await modal.present();
  }

  private getInsuranceCompanyCode(): void {
    let userId = this.user?.id;
    if (!!this.client) {
      userId = this.client.id;
    }
    this.candidatureService
      .getBrokerCodeByUserId(userId)
      .subscribe((broker: BrokerDto) => {
        this.insuranceCompanyCode = broker?.insuranceCode;
        this.canContractTenantInsurances = broker?.canContractTenantInsurances;
        // TODO - Retomar cuando Caser conteste
        //this.insuranceBrokerPaid = broker?.insuranceBrokerPaid;
      });
  }

  private setClient(): void {
    if (!!(history.state as SelectedCandidatureHistoryState).client) {
      this.client = (history.state as SelectedCandidatureHistoryState).client;
    }
  }
}
