import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { InsurancesApiService } from '@core/api-services/insurances-api/insurances-api.service';
import { UsersApiService } from '@core/api-services/users-api/users-api.service';
import {
  Candidature,
  CotenantsArag,
  CustomModalButtonRole,
  PolicyIssue,
  Sinister,
  SinisterThread,
  User,
  UserRol
} from '@core/models';
import { CandidatureUtilsService } from '@core/services/candidature-utils/candidature-utils.service';
import { LoadingService } from '@core/services/loading/loading.service';
import {
  ModalController,
  PopoverController,
  ToastController
} from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { add, endOfMonth, parse } from 'date-fns';
import { Observable, first } from 'rxjs';

import { CancelPolicyRequestModalResp } from '../../models/cancel-policy-request-modal.model';
import { CreateSinesterModalResp } from '../../models/create-sinester-modal.model';
import {
  InsuranceOptionPopoverData,
  InsuranceOptionPopoverEnum,
  InsuranceOptionPopoverResp
} from '../../models/insurance-options-popover.model';
import { ChildRoutesEnum } from '../../models/insurance-page.model';
import { PoliciesDisplayedColumnsEnum } from '../../models/shared-policies.model';
import { copyObject, tableSize } from '../../utils/global.utils';
import { getUsers } from '../../utils/sinister.utils';
import { presentToast } from '../../utils/toast.utils';
import { CancelPolicyRequestModalComponent } from '../cancel-policy-request-modal/cancel-policy-request-modal.component';
import { ConfirmationSinisterModalComponent } from '../confirmation-sinister-modal/confirmation-sinister-modal.component';
import { CreateSinesterModalComponent } from '../create-sinester-modal/create-sinester-modal.component';
import { InsuranceOptionsPopoverComponent } from '../insurance-options-popover/insurance-options-popover.component';
import { PolicyConversationComponent } from '../policy-conversation/policy-conversation.component';
import { PolicyDetailModalComponent } from '../policy-detail-modal/policy-detail-modal.component';
import { TenantListPopoverComponent } from '../tenant-list-popover/tenant-list-popover.component';

@Component({
  selector: 'el-buen-inquilino-shared-policies',
  templateUrl: './shared-policies.component.html'
})
export class SharedPoliciesComponent implements OnInit, OnChanges {
  @Input({ required: true }) originalInsurances: PolicyIssue[] | Sinister[] =
    [];
  @Input({ required: true }) items: PolicyIssue[] | Sinister[] = [];
  @Input({ required: true }) type: ChildRoutesEnum;
  @Input({ required: true }) tab = 1;
  @Input() user: User;
  @Input() searchbarFilter: string;
  @Input() dateFilter: string;
  @Input() renovationFilter: boolean;
  @Input() isIncofisa = false;
  @ViewChild('table') table: MatTable<PolicyIssue | Sinister>;
  childRoutesEnum = ChildRoutesEnum;
  insurances: PolicyIssue[] | Sinister[] = [];
  unpaidInsurances: PolicyIssue[] | Sinister[] = [];
  tenantInsurances: PolicyIssue[] | Sinister[] = [];
  userRolEnum = UserRol;
  // TABLA
  displayedColumns = [
    PoliciesDisplayedColumnsEnum.POLICY_NUMBER,
    PoliciesDisplayedColumnsEnum.ADDRESS,
    PoliciesDisplayedColumnsEnum.ASSET_RENT,
    PoliciesDisplayedColumnsEnum.PREMIUM,
    PoliciesDisplayedColumnsEnum.INIT_DATE,
    PoliciesDisplayedColumnsEnum.END_DATE,
    PoliciesDisplayedColumnsEnum.TENANTS,
    PoliciesDisplayedColumnsEnum.ACTIONS
  ];
  dataSource: MatTableDataSource<PolicyIssue | Sinister> =
    new MatTableDataSource<PolicyIssue | Sinister>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  tableSize = tableSize;
  displayedColumnsEnum = PoliciesDisplayedColumnsEnum;
  // END TABLA

  @Output() getItemsEmitter = new EventEmitter();
  @Output() ownersListEmitter = new EventEmitter<string[]>();

  get noResultMsg(): string {
    if (!!this.searchbarFilter || !!this.dateFilter) {
      return 'common.msg.filters_no_results';
    } else if (this.renovationFilter) {
      return 'pages.homeowner_insurances_list.renovation_filter_no_results';
    } else {
      return 'pages.profile.home-owner.assets.no_results';
    }
  }

  constructor(
    private loadingService: LoadingService,
    private candidaturesService: CandidaturesApiService,
    private popoverController: PopoverController,
    private modalController: ModalController,
    private toastController: ToastController,
    private candidatureUtilsService: CandidatureUtilsService,
    private insuranceService: InsurancesApiService,
    private userService: UsersApiService
  ) {}

  ngOnInit(): void {
    this.setTableColumns();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['originalInsurances']) {
      this.insurances = changes['originalInsurances']
        .currentValue as PolicyIssue[];
      this.setLists();
      this.setDataSource();
    }

    if (changes['items']) {
      this.setLists();
      this.setDataSource();
    }

    if (changes['type']) {
      this.setTableColumns();
    }

    if (
      changes['searchbarFilter'] ||
      changes['dateFilter'] ||
      changes['renovationFilter']
    ) {
      this.filterList();
    }
  }

  trackByPolicyIssue(
    _index: number,
    item: PolicyIssue | Sinister
  ): PolicyIssue | Sinister {
    return item;
  }

  filterList(): void {
    if (this.type === ChildRoutesEnum.ACTIVE) {
      this.filterByActive();
    } else {
      this.filterByCancelled();
    }
  }

  filterByActive(): void {
    let items = copyObject(this.items) as PolicyIssue[];
    if (!!this.searchbarFilter && this.searchbarFilter.trim() !== '') {
      items = items.filter(
        (p: PolicyIssue) =>
          p.insuranceAsset?.address
            .toLowerCase()
            .includes(this.searchbarFilter) ||
          p.policyNumber?.toLowerCase().includes(this.searchbarFilter) ||
          p.emailPolicyHolder.toLowerCase().includes(this.searchbarFilter) ||
          p.insuranceAsset.cotenants.some(
            (tenant: CotenantsArag) =>
              tenant.name?.toLowerCase().includes(this.searchbarFilter) ||
              tenant.surname?.toLowerCase().includes(this.searchbarFilter) ||
              tenant.surname2?.toLowerCase().includes(this.searchbarFilter) ||
              this.getTenantCompleteName(tenant).includes(
                this.searchbarFilter
              ) ||
              tenant.document?.toLowerCase().includes(this.searchbarFilter)
          )
      );
    }

    if (!!this.dateFilter) {
      const date1 = parse(this.dateFilter, 'dd/MM/yyyy', new Date());
      const date2 = endOfMonth(date1);

      items = items.filter((p: PolicyIssue) =>
        this.insuranceService.isBetween(
          date1,
          date2,
          parse(p.inceptionDate, 'dd/MM/yyyy', new Date())
        )
      );
    }

    if (this.renovationFilter) {
      items = items.filter((p: PolicyIssue) => {
        const today = new Date();
        const threeMonths = add(new Date(), { days: 60 });
        const renovationDate = add(
          parse(p.inceptionDate, 'dd/MM/yyyy', new Date()),
          { years: 1 }
        );
        return this.insuranceService.isBetween(
          today,
          threeMonths,
          renovationDate
        );
      });
    }

    this.setDataSourceData(items);
  }

  getTenantCompleteName(tenant: CotenantsArag): string {
    // eslint-disable-next-line max-len
    return `${tenant.name?.toLowerCase()} ${tenant.surname?.toLowerCase()} ${tenant.surname2?.toLowerCase()}`;
  }

  filterByCancelled(): void {
    let items = copyObject(this.items) as Sinister[];
    if (!!this.searchbarFilter && this.searchbarFilter.trim() !== '') {
      items = items.filter(
        (s: Sinister) =>
          s.insuranceAsset?.address
            .toLowerCase()
            .includes(this.searchbarFilter) ||
          s.policyNumber?.toLowerCase().includes(this.searchbarFilter) ||
          s.emailPolicyHolder.toLowerCase().includes(this.searchbarFilter)
      );
    }

    if (!!this.dateFilter) {
      const date1 = parse(this.dateFilter, 'dd/MM/yyyy', new Date());
      const date2 = endOfMonth(date1);

      items = items.filter(
        (s: Sinister) =>
          !!s.endDate &&
          this.insuranceService.isBetween(date1, date2, new Date(s.endDate))
      );
    }

    this.setDataSourceData(items);
  }

  tableSort(event: Sort): void {
    if (!event.active || event.direction === '') {
      this.dataSource.data = this.items;
      return;
    }

    this.filterList();

    const isAsc = event.direction === 'asc';
    const data = copyObject(this.dataSource.data) as PolicyIssue[];

    const sortedData = data.sort((a: PolicyIssue, b: PolicyIssue) => {
      switch (event.active as PoliciesDisplayedColumnsEnum) {
        case PoliciesDisplayedColumnsEnum.POLICY_NUMBER: {
          if (this.type === this.childRoutesEnum.ACTIVE) {
            return this.compare(a.policyNumber, b.policyNumber, isAsc);
          } else {
            return this.compare(
              a.cancelledPolicyNumber,
              b.cancelledPolicyNumber,
              isAsc
            );
          }
        }
        case PoliciesDisplayedColumnsEnum.ADDRESS: {
          return this.compare(a.address, b.address, isAsc);
        }
        case PoliciesDisplayedColumnsEnum.ASSET_RENT: {
          return this.compare(a.assetRent, b.assetRent, isAsc);
        }
        case PoliciesDisplayedColumnsEnum.PREMIUM: {
          return this.compare(
            a.totalAnnualReceipt,
            b.totalAnnualReceipt,
            isAsc
          );
        }
        case PoliciesDisplayedColumnsEnum.INIT_DATE: {
          const aDate: Date = parse(a.inceptionDate, 'dd/MM/yyyy', new Date());
          const bDate: Date = parse(b.inceptionDate, 'dd/MM/yyyy', new Date());
          return this.compare(aDate.getTime(), bDate.getTime(), isAsc);
        }
        case PoliciesDisplayedColumnsEnum.END_DATE: {
          let aDate: Date, bDate: Date;
          if (this.type === this.childRoutesEnum.ACTIVE) {
            aDate = add(parse(a.inceptionDate, 'dd/MM/yyyy', new Date()), {
              years: 1
            });
            bDate = add(parse(b.inceptionDate, 'dd/MM/yyyy', new Date()), {
              years: 1
            });
          } else {
            aDate = new Date(a.cancellationDate);
            bDate = new Date(b.cancellationDate);
          }
          return this.compare(aDate.getTime(), bDate.getTime(), isAsc);
        }
        case PoliciesDisplayedColumnsEnum.TENANTS: {
          return this.compare(
            a.insuranceAsset.cotenants.length,
            b.insuranceAsset.cotenants.length,
            isAsc
          );
        }
      }
    });

    this.dataSource.data = sortedData;
  }

  async downloadPolicy(policy: PolicyIssue | Sinister): Promise<void> {
    if (this.type === ChildRoutesEnum.CANCELLED) {
      presentToast(
        this.toastController,
        'Lás pólizas canceladas no se pueden descargar',
        'danger'
      );
      return;
    }

    await this.loadingService.presentSecondLoader(null);

    let call: Observable<unknown> = null;

    if (this.tab === 1) {
      call = this.candidaturesService.getPolicyReport(
        this.user.id,
        policy.policyNumber
      );
    } else {
      call = this.candidaturesService.downloadTenantPolicy(policy.policyNumber);
    }

    call.pipe(first()).subscribe({
      next: async (blob: File) => {
        await this.loadingService.dismissSecondLoader();
        const url = window.URL.createObjectURL(blob);
        const pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
          alert(
            // eslint-disable-next-line max-len
            'Desactiva el bloqueador de ventanas emergentes y vuelve a intentarlo.'
          );
        }
      },
      error: async () => {
        await this.loadingService.dismissSecondLoader();
      }
    });
  }

  async showTenantListPopover(
    event: Event,
    insurance: PolicyIssue
  ): Promise<void> {
    const popover = await this.popoverController.create({
      component: TenantListPopoverComponent,
      componentProps: {
        list: insurance.insuranceAsset.cotenants
      },
      event
    });
    popover.present();
  }

  async presentUtilsOptionPopover(
    ev: Event,
    insurance: PolicyIssue
  ): Promise<void> {
    const popover = await this.popoverController.create({
      component: InsuranceOptionsPopoverComponent,
      event: ev,
      translucent: true,
      componentProps: {
        unpaid: this.type === ChildRoutesEnum.ACTIVE && this.tab === 1,
        showCancel: this.showCancelOption(insurance),
        isClient: !this.isIncofisa,
        seeCandidature: true
      } as InsuranceOptionPopoverData
    });
    await popover.present();

    const { data }: OverlayEventDetail<InsuranceOptionPopoverResp> =
      await popover.onWillDismiss();

    if (data) {
      switch (data.option) {
        case InsuranceOptionPopoverEnum.SEE_DETAILS:
          this.seeInsuranceDetails(insurance);
          break;
        case InsuranceOptionPopoverEnum.CREATE_SINISTER:
          this.createSinister(insurance);
          break;
        case InsuranceOptionPopoverEnum.POLICY_CANCELATION:
          this.cancelPolicy(insurance);
          break;
        case InsuranceOptionPopoverEnum.SEE_CANDIDATURE:
          this.seeCandidature(insurance);
          break;
        case InsuranceOptionPopoverEnum.SEE_MODIFICATIONS:
          this.seeModifications(insurance);
          break;
        case InsuranceOptionPopoverEnum.SEE_INTERNAL_NOTES:
          this.seeInternalNotes(insurance);
          break;
      }
    }
  }
  showCancelOption(insurance: PolicyIssue): boolean {
    if (this.type === ChildRoutesEnum.ACTIVE) {
      return true;
    }

    if (
      this.type === ChildRoutesEnum.CANCELLED &&
      this.isIncofisa &&
      !insurance.endDate
    ) {
      return true;
    }
    return false;
  }

  private async seeInsuranceDetails(insurance: PolicyIssue): Promise<void> {
    const modal = await this.modalController.create({
      component: PolicyDetailModalComponent,
      backdropDismiss: false,
      showBackdrop: true,
      componentProps: {
        insurance,
        user: this.user,
        isCancelPolicy: this.type === ChildRoutesEnum.CANCELLED,
        tab: this.tab,
        isIncofisa: this.isIncofisa
      } as Partial<PolicyDetailModalComponent>
    });
    modal.onWillDismiss().then((resp: OverlayEventDetail<boolean>) => {
      if (resp.data) {
        this.getItemsEmitter.emit();
      }
    });
    await modal.present();
  }

  private async createSinister(insurance: PolicyIssue): Promise<void> {
    const modal = await this.modalController.create({
      component: CreateSinesterModalComponent,
      backdropDismiss: false,
      componentProps: {
        insurance,
        user: this.user
      }
    });
    await modal.present();

    const { data }: OverlayEventDetail<CreateSinesterModalResp> =
      await modal.onWillDismiss();

    if (data) {
      await this.loadingService.presentSecondLoader(null);
      this.candidaturesService
        .createSinister(insurance.id, data.sinister)
        .pipe(first())
        .subscribe({
          next: () => this.onSuccessCreateSinister(),
          error: async () => await this.loadingService.dismissSecondLoader()
        });
    }
  }

  private async onSuccessCreateSinister(): Promise<void> {
    await this.loadingService.dismissSecondLoader();
    this.getItemsEmitter.emit();
    this.showConfirmationSinisterModal();
  }

  private async showConfirmationSinisterModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: ConfirmationSinisterModalComponent
    });
    await modal.present();
  }

  private cancelPolicy(insurance: PolicyIssue): void {
    if (this.isIncofisa && this.type === ChildRoutesEnum.CANCELLED) {
      this.seeInsuranceDetails(insurance);
    } else {
      this.showCancelPolicyRequestModal(insurance);
    }
  }

  private async showCancelPolicyRequestModal(
    insurance: PolicyIssue
  ): Promise<void> {
    const modal = await this.modalController.create({
      component: CancelPolicyRequestModalComponent,
      showBackdrop: true,
      backdropDismiss: false
    });

    modal
      .onWillDismiss()
      .then((resp: OverlayEventDetail<CancelPolicyRequestModalResp>) => {
        if (resp.role === (CustomModalButtonRole.ACCEPT as string)) {
          this.callCancelPolicy(resp.data, insurance);
        }
      });
    await modal.present();
  }

  private async callCancelPolicy(
    data: CancelPolicyRequestModalResp,
    insurance: PolicyIssue
  ): Promise<void> {
    await this.loadingService.presentLoading(null);
    this.candidaturesService
      .cancelPolicyRequest(insurance.policyNumber, data.file, data.comments)
      .pipe(first())
      .subscribe({
        next: () => this.onSuccessCancelPolicy(),
        error: async () => await this.loadingService.dismissLoading()
      });
  }

  private async onSuccessCancelPolicy(): Promise<void> {
    await this.loadingService.dismissLoading();
    presentToast(
      this.toastController,
      'Vamos a tramitar tu solicitud de cancelación de póliza',
      'success',
      5000
    );
    this.getItemsEmitter.emit();
  }

  private async seeCandidature(policy: PolicyIssue): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    this.candidaturesService
      .getCandidaturesById(policy.candidatureId)
      .pipe(first())
      .subscribe((candidature: Candidature) =>
        this.onSuccessGetCandidature(candidature)
      );
  }

  private async seeModifications(item: PolicyIssue | Sinister): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    let sinister: Sinister = null;
    let policy: PolicyIssue = null;
    if (this.type === ChildRoutesEnum.ACTIVE) {
      policy = item as PolicyIssue;
    } else {
      sinister = item as Sinister;
    }
    const users = await getUsers(
      this.userService,
      this.user,
      sinister,
      policy,
      true
    );
    await this.loadingService.dismissSecondLoader();

    const modal = await this.modalController.create({
      component: PolicyConversationComponent,
      backdropDismiss: false,
      showBackdrop: true,
      componentProps: {
        messages: item.chat?.filter(
          (message: SinisterThread) => !message.internalNote
        ),
        users,
        policy,
        sinister,
        user: this.user,
        isIncofisa: this.isIncofisa
      } as Partial<PolicyConversationComponent>
    });
    modal.onWillDismiss().then((resp: OverlayEventDetail<boolean>) => {
      if (resp.data) {
        this.getItemsEmitter.emit();
      }
    });
    await modal.present();
  }

  private async seeInternalNotes(item: PolicyIssue | Sinister): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    let sinister: Sinister = null;
    let policy: PolicyIssue = null;
    if (this.type === ChildRoutesEnum.ACTIVE) {
      policy = item as PolicyIssue;
    } else {
      sinister = item as Sinister;
    }
    const users = await getUsers(
      this.userService,
      this.user,
      sinister,
      policy,
      true
    );
    await this.loadingService.dismissSecondLoader();
    const modal = await this.modalController.create({
      component: PolicyConversationComponent,
      backdropDismiss: false,
      showBackdrop: true,
      componentProps: {
        messages: item.chat?.filter(
          (message: SinisterThread) => message.internalNote
        ),
        users,
        policy,
        sinister,
        user: this.user,
        isIncofisa: this.isIncofisa,
        isInternalNotes: true
      } as Partial<PolicyConversationComponent>
    });
    modal.onWillDismiss().then((resp: OverlayEventDetail<boolean>) => {
      if (resp.data) {
        this.getItemsEmitter.emit();
      }
    });
    await modal.present();
  }

  private async onSuccessGetCandidature(
    candidature: Candidature
  ): Promise<void> {
    await this.loadingService.dismissSecondLoader();
    this.candidatureUtilsService.presentSelectedCandidatureModal(candidature);
  }

  private setTableColumns(): void {
    if (this.isIncofisa) {
      if (this.type === ChildRoutesEnum.ACTIVE) {
        this.displayedColumns = [
          PoliciesDisplayedColumnsEnum.POLICY_NUMBER,
          PoliciesDisplayedColumnsEnum.ADDRESS,
          PoliciesDisplayedColumnsEnum.CLIENT,
          PoliciesDisplayedColumnsEnum.ASSET_RENT,
          PoliciesDisplayedColumnsEnum.PREMIUM,
          PoliciesDisplayedColumnsEnum.INIT_DATE,
          PoliciesDisplayedColumnsEnum.END_DATE,
          PoliciesDisplayedColumnsEnum.TENANTS,
          PoliciesDisplayedColumnsEnum.ACTIONS
        ];
      } else {
        this.displayedColumns = [
          PoliciesDisplayedColumnsEnum.ID,
          PoliciesDisplayedColumnsEnum.POLICY_NUMBER,
          PoliciesDisplayedColumnsEnum.ADDRESS,
          PoliciesDisplayedColumnsEnum.CLIENT,
          PoliciesDisplayedColumnsEnum.ASSET_RENT,
          PoliciesDisplayedColumnsEnum.PREMIUM,
          PoliciesDisplayedColumnsEnum.INIT_DATE,
          PoliciesDisplayedColumnsEnum.END_DATE,
          PoliciesDisplayedColumnsEnum.TENANTS,
          PoliciesDisplayedColumnsEnum.ACTIONS
        ];
      }
    } else {
      if (this.type === ChildRoutesEnum.ACTIVE) {
        this.displayedColumns = [
          PoliciesDisplayedColumnsEnum.POLICY_NUMBER,
          PoliciesDisplayedColumnsEnum.ADDRESS,
          PoliciesDisplayedColumnsEnum.ASSET_RENT,
          PoliciesDisplayedColumnsEnum.PREMIUM,
          PoliciesDisplayedColumnsEnum.INIT_DATE,
          PoliciesDisplayedColumnsEnum.END_DATE,
          PoliciesDisplayedColumnsEnum.TENANTS,
          PoliciesDisplayedColumnsEnum.ACTIONS
        ];
      } else {
        this.displayedColumns = [
          PoliciesDisplayedColumnsEnum.ID,
          PoliciesDisplayedColumnsEnum.POLICY_NUMBER,
          PoliciesDisplayedColumnsEnum.ADDRESS,
          PoliciesDisplayedColumnsEnum.ASSET_RENT,
          PoliciesDisplayedColumnsEnum.PREMIUM,
          PoliciesDisplayedColumnsEnum.INIT_DATE,
          PoliciesDisplayedColumnsEnum.END_DATE,
          PoliciesDisplayedColumnsEnum.TENANTS,
          PoliciesDisplayedColumnsEnum.ACTIONS
        ];
      }
    }
  }

  private setLists(): void {
    if (this.type === ChildRoutesEnum.ACTIVE) {
      this.unpaidInsurances = (this.insurances as PolicyIssue[]).filter(
        (i: PolicyIssue) => !i.type || i.type === 'DEFAULT'
      );
      this.tenantInsurances = (this.insurances as PolicyIssue[]).filter(
        (i: PolicyIssue) => i.type === 'TENANT_RC'
      );

      if (this.tab === 1) {
        this.items = copyObject(this.unpaidInsurances) as PolicyIssue[];
      } else {
        this.items = copyObject(this.tenantInsurances) as PolicyIssue[];
      }
    } else {
      this.unpaidInsurances = (this.insurances as Sinister[]).filter(
        (i: Sinister) => !i.type || i.type === 'DEFAULT'
      );
      this.tenantInsurances = (this.insurances as Sinister[]).filter(
        (i: Sinister) => i.type === 'TENANT_RC'
      );

      if (this.tab === 1) {
        this.items = copyObject(this.unpaidInsurances) as Sinister[];
      } else {
        this.items = copyObject(this.tenantInsurances) as Sinister[];
      }
    }

    this.setDataSource();
  }

  private setDataSource(): void {
    this.dataSource = new MatTableDataSource<PolicyIssue>(
      copyObject(this.items) as PolicyIssue[]
    );
    this.dataSource.paginator = null;
    this.dataSource.paginator = this.paginator;
  }

  private setDataSourceData(list: PolicyIssue[] | Sinister[]): void {
    if (this.type === ChildRoutesEnum.ACTIVE) {
      this.dataSource.data = copyObject(list) as PolicyIssue[];
    } else {
      this.dataSource.data = copyObject(list) as Sinister[];
    }
  }

  private compare(
    a: number | Date | string,
    b: number | Date | string,
    isAsc: boolean
  ): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
