import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CatalogsApiService } from '@core/api-services/catalogs-api/catalogs-api.service';
import { IncidencesApiService } from '@core/api-services/incidences-api/incidences-api.service';
import { InsurancesApiService } from '@core/api-services/insurances-api/insurances-api.service';
import {
  Catalog,
  CatalogEnum,
  Incidence,
  IncidenceStatusEnum
} from '@core/models';
import {
  IonSelectCustomEvent,
  IonToggleCustomEvent,
  SelectChangeEventDetail,
  ToggleChangeEventDetail
} from '@ionic/core';
import { endOfDay, startOfDay } from 'date-fns';
import { first } from 'rxjs';

import { IncidencesListDisplayedColumnsEnum } from '../../models/incidences-list.model';
import { copyObject, tableSize } from '../../utils/global.utils';

@Component({
  selector: 'el-buen-inquilino-incidences-list',
  templateUrl: './incidences-list.component.html'
})
export class IncidencesListComponent implements OnInit {
  @Input({ required: true }) assetId: string;

  originalItems: Incidence[] = [];
  // TABLA
  displayedColumns = [
    IncidencesListDisplayedColumnsEnum.INCIDENCE_TYPE,
    IncidencesListDisplayedColumnsEnum.TENANT,
    IncidencesListDisplayedColumnsEnum.REQUEST_DATE,
    IncidencesListDisplayedColumnsEnum.STATUS
  ];
  incidencesDataSource: MatTableDataSource<Incidence> =
    new MatTableDataSource<Incidence>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  tableSize = tableSize;
  displayedColumnsEnum = IncidencesListDisplayedColumnsEnum;
  // END TABLA

  incidenceTypes: Catalog[];
  incidenceTypesFilter: Catalog | null = null;
  dateFilter: Date | null = null;
  max = new Date();
  showCancelDateFilter = false;
  newStatusFilter = false;
  ejecucionStatusFilter = false;
  closedStatusFilter = false;

  constructor(
    private catalogService: CatalogsApiService,
    private incidenceService: IncidencesApiService,
    private insuranceService: InsurancesApiService
  ) {}

  ngOnInit(): void {
    // this.getIncidences();
    this.getIncidenceTypes();
  }

  trackByIncidence(_index: number, incidence: Incidence): Incidence {
    return incidence;
  }

  incidenceTypesFilterChange(
    event: IonSelectCustomEvent<SelectChangeEventDetail<Catalog>>
  ): void {
    this.incidenceTypesFilter = event.detail.value;
    this.filter();
  }

  dateFilterChange(event: MatDatepickerInputEvent<Date, Date>): void {
    this.dateFilter = event.value;
    if (!!this.dateFilter) {
      this.showCancelDateFilter = true;
    }
    this.filter();
  }

  resetDateFilter(): void {
    this.dateFilter = null;
    this.showCancelDateFilter = false;
    this.filter();
  }

  newStatusFilterChange(
    event: IonToggleCustomEvent<ToggleChangeEventDetail<boolean>>
  ): void {
    this.newStatusFilter = event.detail.checked;
    if (this.newStatusFilter) {
      this.ejecucionStatusFilter = false;
      this.closedStatusFilter = false;
      this.filter();
    } else {
      this.clearFilters();
    }
  }

  ejecucionStatusFilterChange(
    event: IonToggleCustomEvent<ToggleChangeEventDetail<boolean>>
  ): void {
    this.ejecucionStatusFilter = event.detail.checked;
    if (this.ejecucionStatusFilter) {
      this.newStatusFilter = false;
      this.closedStatusFilter = false;
      this.filter();
    } else {
      this.clearFilters();
    }
  }

  closedStatusFilterChange(
    event: IonToggleCustomEvent<ToggleChangeEventDetail<boolean>>
  ): void {
    this.closedStatusFilter = event.detail.checked;
    if (this.closedStatusFilter) {
      this.newStatusFilter = false;
      this.ejecucionStatusFilter = false;
      this.filter();
    } else {
      this.clearFilters();
    }
  }

  clearFilters(): void {
    this.incidenceTypesFilter = null;
    this.dateFilter = null;
    this.showCancelDateFilter = false;
    this.newStatusFilter = false;
    this.ejecucionStatusFilter = false;
    this.closedStatusFilter = false;
    this.filter();
  }

  private getIncidenceTypes(): void {
    this.catalogService
      .getCatalogs(CatalogEnum.INCIDENCE_TYPES)
      .pipe(first())
      .subscribe((resp: Catalog[]) => this.onSuccessGetIncidenceTypes(resp));
  }

  private onSuccessGetIncidenceTypes(resp: Catalog[]): void {
    this.incidenceTypes = resp;
    this.getIncidences();
  }

  private getIncidences(): void {
    this.incidenceService
      .getIncidencesByCandidature(this.assetId)
      .pipe(first())
      .subscribe({
        next: (resp: Incidence[]) => this.onSuccessGetIncidences(resp),
        error: (err: unknown) => this.onErrorGetIncidences(err)
      });
  }

  private onSuccessGetIncidences(resp: Incidence[]): void {
    this.originalItems = copyObject(resp) as Incidence[];
    this.incidencesDataSource = new MatTableDataSource<Incidence>(
      copyObject(resp) as Incidence[]
    );
    this.incidencesDataSource.paginator = null;
    this.incidencesDataSource.paginator = this.paginator;
  }

  private onErrorGetIncidences(_err: unknown): void {}

  private filter(): void {
    let incidences = copyObject(this.originalItems) as Incidence[];

    if (!!this.incidenceTypesFilter) {
      incidences = incidences.filter(
        (incidence: Incidence) =>
          incidence.type === this.incidenceTypesFilter.id
      );
    }

    if (!!this.dateFilter) {
      const initDate = startOfDay(this.dateFilter);
      const endDate = endOfDay(this.dateFilter);

      incidences = incidences.filter((incidence: Incidence) =>
        this.insuranceService.isBetween(
          initDate,
          endDate,
          incidence.creationDate
        )
      );
    }

    if (this.newStatusFilter) {
      incidences = incidences.filter(
        (incidence: Incidence) => incidence.status === IncidenceStatusEnum.NEW
      );
    }

    if (this.ejecucionStatusFilter) {
      incidences = incidences.filter(
        (incidence: Incidence) =>
          incidence.status === IncidenceStatusEnum.EJECUCION
      );
    }

    if (this.closedStatusFilter) {
      incidences = incidences.filter(
        (incidence: Incidence) =>
          incidence.status === IncidenceStatusEnum.CLOSED
      );
    }

    this.incidencesDataSource.data = incidences;
  }
}
