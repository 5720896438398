/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import { ChangeUserRoleDto, TenantCandidature, UserRole } from '@core/models';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { BusinessRolDataFormModalComponent } from '../business-rol-data-form-modal/business-rol-data-form-modal.component';

@Component({
  selector: 'el-buen-inquilino-change-tenant-role',
  templateUrl: './change.tenant.role.html'
})
export class ChangeTenantRoleComponent {
  @Input() tenant: TenantCandidature;
  @Input() candidatureId: string;
  userRoleStatusEnum = UserRole;
  userRole: UserRole;
  @Output() refresh = new EventEmitter();
  @Input() multipleTenants: boolean;

  constructor(
    private candidatureService: CandidaturesApiService,
    private modalController: ModalController,
    private translateService: TranslateService
  ) {}

  changeTenantRole(): void {
    const obj: ChangeUserRoleDto = {
      candidatureId: this.candidatureId,
      newRol: this.userRole,
      tenantId: this.tenant.user.id
    };
    if (this.userRole === this.userRoleStatusEnum.BUSINESS) {
      this.presentAddBusinessRolDataForm(obj);
    } else {
      this.changeTenantRoleToService(obj);
    }
  }

  changeTenantRoleToService(changeUserRoleDto: ChangeUserRoleDto): void {
    this.candidatureService
      .changeUserRole(changeUserRoleDto)
      .subscribe((data) => {
        this.tenant = data;
        this.refresh.emit();
      });
  }

  getTenantStatus(): string {
    if (this.tenant && this.tenant.user) {
      if (this.tenant.user.retired) {
        return this.translate(this.userRoleStatusEnum.RETIRED);
      }
      if (this.tenant.user.student) {
        return this.translate(this.userRoleStatusEnum.STUDENT);
      }
      if (
        this.tenant.user.professionalData &&
        this.tenant.user.professionalData.freelance
      ) {
        return this.translate(this.userRoleStatusEnum.FREELANCE);
      }
      if (this.tenant.user.business) {
        return this.translate(this.userRoleStatusEnum.BUSINESS);
      }
      return this.translate(this.userRoleStatusEnum.WORKER);
    }
  }

  translate(userRole: UserRole): string {
    switch (userRole) {
      case this.userRoleStatusEnum.RETIRED:
        return this.translateService.instant(
          'components.change_tenant_role.retired'
        );
      case this.userRoleStatusEnum.STUDENT:
        return this.translateService.instant(
          'components.change_tenant_role.student'
        );
      case this.userRoleStatusEnum.FREELANCE:
        return this.translateService.instant(
          'components.change_tenant_role.freelance'
        );
      case this.userRoleStatusEnum.BUSINESS:
        return this.translateService.instant(
          'components.change_tenant_role.business'
        );
      default:
        return this.translateService.instant(
          'components.change_tenant_role.worker'
        );
    }
  }

  async presentAddBusinessRolDataForm(
    changeUserRoleDto: ChangeUserRoleDto
  ): Promise<void> {
    const modal = await this.modalController.create({
      component: BusinessRolDataFormModalComponent,
      cssClass: 'auto-height modal-extend',
      componentProps: {
        tenant: this.tenant,
        changeUserRoleDto
      },
      id: 'AddBusinessRolDataForm'
    });
    modal.onDidDismiss().then((close) => {
      if (close?.data?.saving) {
        this.changeTenantRoleToService(close.data.data);
      }
    });
    return await modal.present();
  }
}
