import { FormControl } from '@angular/forms';
import { SinisterStatusEnum } from '@core/models';

export interface UpdateSinisterModalForm {
  status: FormControl<SinisterStatusEnum>;
  insuranceCode: FormControl<string>;
  totalDebt: FormControl<number>;
  inputDebt?: FormControl<string>;
  lastUpdatedTotalDebt: FormControl<Date>;
  assignedLawyer?: FormControl<string>;
  lawyerPhone?: FormControl<string>;
  lawyerEmail?: FormControl<string>;
}
export interface UpdateSinisterModalFormValue {
  status: SinisterStatusEnum;
  insuranceCode: string;
  totalDebt: number;
  inputDebt?: string;
  lastUpdatedTotalDebt: Date;
  assignedLawyer?: string;
  lawyerPhone?: string;
  lawyerEmail?: string;
}

export enum DisplayedColumns {
  DATE = 'date',
  TOTAL_DEBT = 'totalDebt',
  STATUS = 'status'
}
