import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { CandidaturesApiService } from '@core/api-services/candidatures-api/candidatures-api.service';
import {
  DocumentDTO,
  ErrorResponse,
  GeneralModalResponse,
  PolicyIssue,
  Sinister,
  SinisterDocumentType,
  SinisterStatusEnum,
  User
} from '@core/models';
import { LoadingService } from '@core/services/loading/loading.service';
import { UtilsService } from '@core/services/utils/utils.service';
import {
  AlertController,
  ModalController,
  ToastController
} from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, first } from 'rxjs';

import {
  base64ToBlob,
  getSinisterBlobName,
  getSinisterDocLabel
} from '../../utils/sinister.utils';
import { presentToast } from '../../utils/toast.utils';
import { AddSinisterDocumentComponent } from '../add-sinister-document/add-sinister-document.component';
import { ViewSinisterDocumentModalComponent } from '../view-sinister-document-modal/view-sinister-document-modal.component';

@Component({
  selector: 'el-buen-inquilino-sinister-documents',
  templateUrl: './sinister-documents.component.html'
})
export class SinisterDocumentsComponent implements OnChanges {
  @Input() insurance: PolicyIssue;
  @Input({ required: true }) policyNumber: string;
  @Input() sinister: Sinister;
  @Input() isIncofisa = false;
  @Input() seeDetails = false;
  @Input({ required: true }) private userPolicyHolder: User;
  @Input() isCreateModal = false;

  documents: DocumentDTO[] = [];
  rentalContractFile: DocumentDTO = null;
  burofaxSentFile: DocumentDTO = null;
  burofaxReceptionFile: DocumentDTO = null;
  complaintDocFile: DocumentDTO = null;
  socialServicesRequestFile: DocumentDTO = null;
  procedureResolutionFile: DocumentDTO = null;
  executionClaimFile: DocumentDTO = null;
  otherDocumentFile: DocumentDTO = null;
  sinisterDocumentType = SinisterDocumentType;
  uploadDocumentsStatusList = [
    SinisterStatusEnum.INSURANCE_COMPANY_BUROFAX_SENT,
    SinisterStatusEnum.INSURANCE_COMPANY_COMPLAINT_FILLED,
    SinisterStatusEnum.PENDING_TRIAL,
    SinisterStatusEnum.SCHEDULED_EVICTION
  ];

  @Output() showUploadBtn = new EventEmitter<boolean>();
  @Output() newDocEvent = new EventEmitter<SinisterDocumentType>();
  @Output() deleteDocEvent = new EventEmitter<SinisterDocumentType>();

  get thereIsDocuments(): boolean {
    return this.rentalContractFile !== null;
  }

  get noDocsMsg(): string {
    // eslint-disable-next-line max-len
    return 'Recuerda que si ocurre un siniestro con tu póliza, necesitamos que adjuntes tu contrato de alquiler.';
  }

  get showBurofaxSentFile(): boolean {
    return (
      !this.burofaxSentFile &&
      this.uploadDocumentsStatusList.includes(this.sinister?.status)
    );
  }

  get showBurofaxReceptionFile(): boolean {
    return (
      !this.burofaxReceptionFile &&
      this.uploadDocumentsStatusList.includes(this.sinister?.status)
    );
  }

  get showComplaintDocFile(): boolean {
    return (
      !this.complaintDocFile &&
      this.uploadDocumentsStatusList.includes(this.sinister?.status)
    );
  }

  get showSocialServicesRequestFile(): boolean {
    return (
      !this.socialServicesRequestFile &&
      this.uploadDocumentsStatusList.includes(this.sinister?.status)
    );
  }

  get showProcedureResolutionFile(): boolean {
    return (
      !this.procedureResolutionFile &&
      this.uploadDocumentsStatusList.includes(this.sinister?.status)
    );
  }

  get showExecutionClaimFile(): boolean {
    return (
      !this.executionClaimFile &&
      this.uploadDocumentsStatusList.includes(this.sinister?.status)
    );
  }

  get showOtherDocumentFile(): boolean {
    return (
      !this.otherDocumentFile &&
      this.uploadDocumentsStatusList.includes(this.sinister?.status)
    );
  }

  constructor(
    private alertController: AlertController,
    private modalController: ModalController,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private utilsService: UtilsService,
    private toastController: ToastController,
    private candidatureService: CandidaturesApiService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sinister']) {
      this.getDocuments();
    }
  }

  getDocLabel(type: SinisterDocumentType): string {
    return getSinisterDocLabel(type);
  }

  getDocUploadDate(type: SinisterDocumentType): Date {
    return new Date(this.getDocumentByType(type).creationDate);
  }

  viewDocument(type: SinisterDocumentType): void {
    this.loadViewDocumentModal(
      this.getDocumentByType(type),
      getSinisterBlobName(this.policyNumber, type)
    );
  }

  async loadViewDocumentModal(doc: DocumentDTO, name: string): Promise<void> {
    const modal = await this.modalController.create({
      component: ViewSinisterDocumentModalComponent,
      cssClass: 'custom-modal-xl',
      componentProps: {
        url: doc.url,
        extension: doc.extension,
        name
      } as Partial<ViewSinisterDocumentModalComponent>
    });

    return await modal.present();
  }

  async saveDocument(
    policyNumber: string,
    type: SinisterDocumentType = SinisterDocumentType.RENTAL_CONTRACT
  ): Promise<void> {
    this.policyNumber = policyNumber;
    if (this.documents?.length >= 20) {
      this.presentMaximumNumberDocumentsReachedModal();
      return;
    }

    const modal = await this.modalController.create({
      component: AddSinisterDocumentComponent,
      componentProps: {
        type,
        user: this.userPolicyHolder,
        policyNumber: this.policyNumber
      } as Partial<AddSinisterDocumentComponent>,
      showBackdrop: true,
      backdropDismiss: false
    });

    modal
      .onDidDismiss()
      .then((resp: OverlayEventDetail<GeneralModalResponse>) => {
        if (resp.data?.saving) {
          this.getDocuments();
          this.newDocEvent.emit(type);
        }
      });
    return await modal.present();
  }

  download(type: SinisterDocumentType): void {
    this.utilsService.downloadPDF(
      getSinisterBlobName(this.policyNumber, type),
      this.getFileByType(type)
    );
  }

  async presentAlertDelete(type: SinisterDocumentType): Promise<void> {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert',
      message: `¿Estás seguro que quieres eliminar ${this.getDocLabel(type)}?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: `Eliminar`,
          cssClass: 'danger',
          handler: (): void => {
            this.deleteDoc(type);
          }
        }
      ]
    });
    await alert.present();
  }

  async deleteDoc(type: SinisterDocumentType): Promise<void> {
    await this.loadingService.presentSecondLoader(null);
    this.candidatureService
      .deleteSinisterDocument(this.policyNumber, type)
      .pipe(first())
      .subscribe({
        next: () => this.onSuccessDeleteDoc(type),
        error: () => this.onErrorDeleteDoc()
      });
  }

  saveBurofaxSentFile(): void {
    this.saveDocument(this.policyNumber, SinisterDocumentType.BUROFAX_SENT);
  }

  saveReceptionFile(): void {
    this.saveDocument(
      this.policyNumber,
      SinisterDocumentType.BUROFAX_RECEPTION
    );
  }

  saveComplaintDocFile(): void {
    this.saveDocument(this.policyNumber, SinisterDocumentType.COMPLAINT_DOC);
  }

  saveSocialServicesRequestFile(): void {
    this.saveDocument(
      this.policyNumber,
      SinisterDocumentType.SOCIAL_SERVICES_REQUEST
    );
  }

  saveProcedureResolutionFile(): void {
    this.saveDocument(
      this.policyNumber,
      SinisterDocumentType.PROCEDURE_RESOLUTION
    );
  }

  saveExecutionClaimFile(): void {
    this.saveDocument(this.policyNumber, SinisterDocumentType.EXECUTION_CLAIM);
  }

  saveOtherDocumentFile(): void {
    this.saveDocument(this.policyNumber, SinisterDocumentType.OTHER_DOCUMENT);
  }

  private async presentMaximumNumberDocumentsReachedModal(): Promise<void> {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert',
      header: this.translate.instant(
        'pages.documents.create-page.maximum_documents_reached_modal_header'
      ) as string,
      message: this.translate.instant(
        'pages.documents.create-page.maximum_documents_reached_modal_message'
      ) as string,
      buttons: [
        {
          text: this.translate.instant(
            'pages.documents.create-page.ok'
          ) as string,
          cssClass: 'secondary'
        }
      ]
    });
    await alert.present();
  }

  private getDocuments(): void {
    if (this.seeDetails) {
      this.getSinisterDocuments();
    } else if (this.insurance?.type !== 'TENANT_RC') {
      this.getRentContract();
    }
  }

  private getSinisterDocuments(): void {
    this.getRentContract();
    this.getBurofaxSent();
    this.getBurofaxReception();
    this.getComplaint();
    this.getSocialServicesRequestFile();
    this.getProcedureResolutionFile();
    this.getExecutionClaimFile();
    this.getOtherDocumentFile();
  }

  private getRentContract(): void {
    this.getDocumentByTypeCall(SinisterDocumentType.RENTAL_CONTRACT)
      .pipe(first())
      .subscribe({
        next: (resp: DocumentDTO) => {
          this.rentalContractFile = resp;
        },
        error: (error: HttpErrorResponse) =>
          this.onErrorGetSinisterDocument(error.error as ErrorResponse)
      });
  }

  private onErrorGetSinisterDocument(error: ErrorResponse): void {
    if (error?.code === '404') {
      this.showUploadBtn.emit(true);
    }
  }

  private getBurofaxSent(): void {
    this.getDocumentByTypeCall(SinisterDocumentType.BUROFAX_SENT)
      .pipe(first())
      .subscribe((resp: DocumentDTO) => (this.burofaxSentFile = resp));
  }

  private getBurofaxReception(): void {
    this.getDocumentByTypeCall(SinisterDocumentType.BUROFAX_RECEPTION)
      .pipe(first())
      .subscribe((resp: DocumentDTO) => (this.burofaxReceptionFile = resp));
  }

  private getComplaint(): void {
    this.getDocumentByTypeCall(SinisterDocumentType.COMPLAINT_DOC)
      .pipe(first())
      .subscribe((resp: DocumentDTO) => (this.complaintDocFile = resp));
  }

  private getSocialServicesRequestFile(): void {
    this.getDocumentByTypeCall(SinisterDocumentType.SOCIAL_SERVICES_REQUEST)
      .pipe(first())
      .subscribe(
        (resp: DocumentDTO) => (this.socialServicesRequestFile = resp)
      );
  }

  private getProcedureResolutionFile(): void {
    this.getDocumentByTypeCall(SinisterDocumentType.PROCEDURE_RESOLUTION)
      .pipe(first())
      .subscribe((resp: DocumentDTO) => (this.procedureResolutionFile = resp));
  }

  private getExecutionClaimFile(): void {
    this.getDocumentByTypeCall(SinisterDocumentType.EXECUTION_CLAIM)
      .pipe(first())
      .subscribe((resp: DocumentDTO) => (this.executionClaimFile = resp));
  }

  private getOtherDocumentFile(): void {
    this.getDocumentByTypeCall(SinisterDocumentType.OTHER_DOCUMENT)
      .pipe(first())
      .subscribe((resp: DocumentDTO) => (this.otherDocumentFile = resp));
  }

  private getDocumentByTypeCall(
    type: SinisterDocumentType
  ): Observable<DocumentDTO> {
    return this.candidatureService.getSinisterDocument(this.policyNumber, type);
  }

  private getDocumentByType(type: SinisterDocumentType): DocumentDTO {
    switch (type) {
      case SinisterDocumentType.RENTAL_CONTRACT:
        return this.rentalContractFile;
      case SinisterDocumentType.BUROFAX_SENT:
        return this.burofaxSentFile;
      case SinisterDocumentType.BUROFAX_RECEPTION:
        return this.burofaxReceptionFile;
      case SinisterDocumentType.COMPLAINT_DOC:
        return this.complaintDocFile;
      case SinisterDocumentType.SOCIAL_SERVICES_REQUEST:
        return this.socialServicesRequestFile;
      case SinisterDocumentType.PROCEDURE_RESOLUTION:
        return this.procedureResolutionFile;
      case SinisterDocumentType.EXECUTION_CLAIM:
        return this.executionClaimFile;
      case SinisterDocumentType.OTHER_DOCUMENT:
        return this.otherDocumentFile;
    }
  }

  private async onSuccessDeleteDoc(type: SinisterDocumentType): Promise<void> {
    await this.loadingService.dismissSecondLoader();
    presentToast(
      this.toastController,
      this.translate.instant('pages.documents.create-page.deletedOK') as string,
      'success'
    );

    this.deleteDocEvent.emit(type);

    switch (type) {
      case SinisterDocumentType.RENTAL_CONTRACT:
        this.rentalContractFile = null;
      case SinisterDocumentType.BUROFAX_SENT:
        this.burofaxSentFile = null;
      case SinisterDocumentType.BUROFAX_RECEPTION:
        this.burofaxReceptionFile = null;
      case SinisterDocumentType.COMPLAINT_DOC:
        this.complaintDocFile = null;
      case SinisterDocumentType.SOCIAL_SERVICES_REQUEST:
        this.socialServicesRequestFile = null;
      case SinisterDocumentType.PROCEDURE_RESOLUTION:
        this.procedureResolutionFile = null;
      case SinisterDocumentType.EXECUTION_CLAIM:
        this.executionClaimFile = null;
      case SinisterDocumentType.OTHER_DOCUMENT:
        this.otherDocumentFile = null;
    }
  }

  private async onErrorDeleteDoc(): Promise<void> {
    await this.loadingService.dismissSecondLoader();
    presentToast(
      this.toastController,
      this.translate.instant('pages.documents.create-page.deletedKO') as string,
      'danger'
    );
  }

  private getFileByType(type: SinisterDocumentType): Blob {
    let b64: string, docType: string;
    switch (type) {
      case SinisterDocumentType.RENTAL_CONTRACT:
        b64 = this.rentalContractFile.inputStream;
        docType = this.rentalContractFile.extension;
        break;
      case SinisterDocumentType.BUROFAX_SENT:
        b64 = this.burofaxSentFile.inputStream;
        docType = this.burofaxSentFile.extension;
        break;
      case SinisterDocumentType.BUROFAX_RECEPTION:
        b64 = this.burofaxReceptionFile.inputStream;
        docType = this.burofaxReceptionFile.extension;
        break;
      case SinisterDocumentType.COMPLAINT_DOC:
        b64 = this.complaintDocFile.inputStream;
        docType = this.complaintDocFile.extension;
        break;
      case SinisterDocumentType.SOCIAL_SERVICES_REQUEST:
        b64 = this.socialServicesRequestFile.inputStream;
        docType = this.socialServicesRequestFile.extension;
        break;
      case SinisterDocumentType.PROCEDURE_RESOLUTION:
        b64 = this.procedureResolutionFile.inputStream;
        docType = this.procedureResolutionFile.extension;
        break;
      case SinisterDocumentType.EXECUTION_CLAIM:
        b64 = this.executionClaimFile.inputStream;
        docType = this.executionClaimFile.extension;
        break;
      case SinisterDocumentType.OTHER_DOCUMENT:
        b64 = this.otherDocumentFile.inputStream;
        docType = this.otherDocumentFile.extension;
        break;
    }
    return base64ToBlob(b64, docType);
  }
}
